export default {
    namespaced: true,

    state: {
        enabled: false,
        host: null,
        port: null,
    },

    getters: {
        isEnabled(state) {
            return state.enabled;
        },
        scaleSettings(state){
            if(!state.host && !state.port)
                return {
                    host:'127.0.0.1',
                    port:3086,
                };
            return {
                host:state.host,
                port:state.port,
            }
        },
        host(state) {
            return state.host;
        },
        port(state) {
            return state.port;
        },
    },

    mutations: {
        toggleScale(state, payload) {
            state.enabled = payload;
        },
        setScaleSettings(state, payload) {
            if (!payload) {
                state.host = null;
                state.port = null;
            } else {
                state.host = payload.host;
                state.port = payload.port;
            }
        },

    },

    actions: {

        toggleScale({commit},payload) {
            commit('toggleScale',payload);
        },
        setScaleSettings({commit},payload) {
            commit('setScaleSettings',payload);
        },

    },
}