export default {
    namespaced: true,

    state: {
        activeUser: {
            userID: null,
            name: "",
            last_name: "",
            tokenColor: "",
            avatarURI: ""
        },
        users: [],
    },

    mutations: {
        addUser(state, data) {
            if(state.users.find(el => el.userID === parseInt(data.userID)))
                return;
            state.users.unshift(data);
            //Vue.set(state.users, state.users.length,data)
        },
        setActiveUser(state, data) {
            state.activeUser.userID = parseInt(data.userID);
            state.activeUser.name = data.name;
            state.activeUser.last_name = data.last_name;
            state.activeUser.avatarURI = data.avatarURI;
            state.activeUser.tokenColor = data.tokenColor;
        },
        setUserGastroState(state, data) {
            const index = state.users.findIndex(el => el.userID === state.activeUser.userID);
            if (index !== -1) {
                state.users[index].gastro = data;
            }
        },
        removeUser(state, userID) {
            for (let i = 0, l = state.users.length; i < l; i++) {
                if (state.users[i].userID === parseInt(userID)) {
                    state.users.splice(i, 1);
                    break;
                }
            }
        },
        clearActiveUser(state) {
            state.activeUser.userID = null;
            state.activeUser.name = "";
            state.activeUser.last_name = "";
            state.activeUser.avatarURI = "";
            state.activeUser.tokenColor = "";
        },
        reset(state) {
            state.activeUser.userID = null;
            state.activeUser.name = "";
            state.activeUser.last_name = "";
            state.activeUser.avatarURI = "";
            state.activeUser.tokenColor = "";
            state.users = [];
        }
    },

    getters: {
        activeUser(state) {
            return state.activeUser;
        },
        allUsers(state) {
            return _.orderBy(state.users, 'priority','asc');
        }
    },

    actions: {}
};
