import {erpRoutes} from "./erpRoutes";
import {accountingRoutes} from "./accountingRoutes";
import {analyticRoutes} from "./analyticRoutes";
import {customerRoutes} from "./customerRoutes";
import {factureRoutes} from "./factureRoutes";
import {timeTrackRoutes} from "./timeTrackRoutes";
import {settingRoutes} from "./settingRoutes";
import {bookingRoutes} from "./bookingRoutes";
import {deliveryRoutes} from "./deliveryRoutes";
import {canteenRoutes} from "./canteenRoutes";
import {tableBeeRoutes} from "./tablebeeRoutes";
import {kioskRoutes} from "./kioskRoutes";
import {eventbeeRoutes} from "./eventbeeRoutes";
import {pmsRoutes} from "@/config/pmsRoutes";
import {onlineShopRoutes} from "./onlineShopRoutes";
import {replicationRoutes} from "./replicationRoutes";

/*
* we can use an array of required modules on one route
* for example requiredModule: [moduleID1, moduleID2,...],
* we still have the possibility to put one value only since there are some module ids that can give the permission to the same route
* */

export const ROUTER = {
    ENTRIES: [
        // --- LOGIN ---

        {
            path: '/index.html',
            groups: [],
            childs: [],
            meta: {
                hideInNavigation: true,
                siteTitle: 'Willkommen bei 3POS',
                menuTitle: '',
                layout: 'userpages',
                icon: '',
                permission: {}
            },
            component: () => import('../views/auth/Index'),
        },
        {
            path: '/',
            name: 'init',
            groups: [],
            childs: [],
            meta: {
                hideInNavigation: true,
                siteTitle: 'Willkommen bei 3POS',
                menuTitle: '',
                layout: 'userpages',
                icon: '',
                permission: {}
            },
            component: () => import('../views/auth/Index'),
        },
        // DASHBOARD
        {
            path: '/dashboards/analytics',
            name: 'dashboard',
            groups: [],
            childs: [],
            meta: {
                siteTitle: 'Dashboard',
                menuTitle: 'Dashboard',
                layout: 'default',
                icon: 'pe-7s-graph2',
                permission: {}
            },
            component: () => import('../views/dashboard/Analytics.vue'),
        },
        //Wholesale retail POS
        {
            path: '/pos/wholesaleRetail',
            name: 'pos.wholesaleRetail',
            groups: [],
            childs: [],
            meta: {
                siteTitle: ('generic.lang_wholeSaleRetailPOS'),
                menuTitle: ('generic.lang_wholeSaleRetailPOS'),
                layout: 'default',
                icon: 'pe-7s-box1',
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 78,
                    requiredPermission: "show_wholesaleUi",
                    hybridDisplay: "",
                    hideInNavigation: "",
                }
            },
            component: () => import('../views/pos/WholesaleRetail.vue'),
        },
        //RETAIL POS
        {
            path: '/pos/retail',
            name: 'pos.retail',
            groups: [],
            childs: [],
            meta: {
                siteTitle: ('generic.lang_nav_einzelhandelkasse'),
                menuTitle: ('generic.lang_nav_einzelhandelkasse'),
                layout: 'default',
                icon: 'pe-7s-shopbag',
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 1,
                    requiredPermission: "show_cashierui",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/pos/Retail.vue'),
        },
        //SELFSERIVCE POS
        {
            path: '/pos/retail/selfcheckout',
            name: 'pos.retail.selfcheckout',
            groups: [],
            childs: [],
            meta: {
                siteTitle: ('generic.lang_nav_retail_selfcheckout'),
                menuTitle: ('generic.lang_nav_retail_selfcheckout'),
                layout: 'default',
                icon: 'pe-7s-phone',
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 85,
                    requiredPermission: "access_self_service_pos",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/pos/Retail.vue'),
        },
        {
            path: '/pos/selfcheckout/display',
            name: 'pos.selfcheckout.display',
            groups: [],
            childs: [],
            meta: {
                siteTitle: ('generic.lang_nav_retail_selfcheckout_display'),
                menuTitle: ('generic.lang_nav_retail_selfcheckout_display'),
                layout: 'default',
                icon: 'pe-7s-monitor',
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 85,
                    requiredPermission: "access_self_service_pos",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/pos/SelfServiceDisplay.vue'),
        },
        //Gastro POS
        {
            path: '/pos/gastro',
            name: 'pos.gastro',
            groups: [],
            childs: [],
            meta: {
                hideInNavigation: true,
                siteTitle: ('generic.lang_nav_gastrkasse'),
                menuTitle: ('generic.lang_nav_gastrkasse'),
                layout: 'default',
                icon: 'lnr-dinner',
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 2,
                    requiredPermission: "show_cashierui",
                    hybridDisplay: "",
                    hideInNavigation: false,
                }
            },
            component: () => import('../views/pos/Gastro.vue'),
        },
        {
            path: '/pos/gastro/tresenverkauf',
            name: 'pos.gastro.tresenverkauf',
            groups: [],
            childs: [],
            meta: {
                hideInNavigation: false,
                siteTitle: ('generic.lang_nav_gastrkasse'),
                menuTitle: ('generic.lang_nav_gastrkasse'),
                layout: 'default',
                icon: 'lnr-dinner',
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 2,
                    requiredPermission: "show_cashierui",
                    hybridDisplay: "",
                }
            },
            component: () => import('../views/pos/GastroTresenverkauf.vue'),
        },
        /* {
             path: '/pos/gastro/mobile-direct-sale',
             name: 'pos.gastro.mobile.direct.sale',
             groups: [],
             childs: [],
             meta: {
                 hideInNavigation: false,
                 siteTitle: ('generic.lang_table_Tresenverkau'),
                 menuTitle: ('generic.lang_table_Tresenverkau'),
                 layout: 'default',
                 icon: 'lnr-dinner',
                 permission: {
                     requiredModule: 2,
                     requiredPermission: "show_cashierui",
                     hybridDisplay: ""
                 }
             },
             component: () => import('../views/pos/GastroTresenverkauf.vue'),
         },*/
        {
            path: '/pos/gastro/tables/:isChange?',
            name: 'pos.gastro.tables',
            groups: [],
            childs: [],
            meta: {
                siteTitle: ('generic.lang_nav_tableOverview'),
                menuTitle: ('generic.lang_nav_tableOverview'),
                layout: 'default',
                icon: 'lnr-dinner',
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 2,
                    requiredPermission: "show_cashierui",
                    hybridDisplay: "",
                }
            },
            component: () => import('../views/pos/GastroTables.vue'),
        },
        {
            path: '/pos/externalAppGroup/',
            name: 'pos.externalAppGroup',
            meta: {
                hideInNavigation: false,
                layout: 'default',
                permission: {
                    requiredModule: 65,
                    requiredPermission: "display_external_apps",
                    hybridDisplay: ""
                }
            },
            groups: [],
            childs: [],
        },
        {
            path: '/pos/rfid',
            name: 'pos.rfid',
            groups: [],
            childs: [],
            meta: {
                hideInNavigation: false,
                siteTitle: ('generic.lang_nav_rfidCashier'),
                menuTitle: ('generic.lang_nav_rfidCashier'),
                layout: 'default',
                icon: 'lnr-dinner',
                permission: {
                    requiredModule: 50,
                    requiredPermission: "show_cashierui",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/pos/RFIDScan.vue'),
        },
        // --- Tablebee ---
        {},
        // --- Kiosk ---
        {},
        // --- Online Shop ---
        {},
        // --- Eventbee ---
        {},
        // --- delivery ---
        {},
        // --- Kantine ---
        {},
        // --- RESERVATION ---
        {},
        // --- WARENWIRTSCHAFT ---

        {},
        // --- BUCHHALTUNG ---
        {},
        // --- ANALYSEN / AUSWERTUNG ---
        {},

        // --- KUNDENVERWALTUNG ---
        {},
        // --- FAKTURA ---
        {},
        // -------------- AIDA ----------------
        /*{
            header: true,
            meta: {
                menuTitle: "AIDA",
                permission: {
                    requiredModule: 5,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            }
        },
        {
            header: false,
            path: '/aida/cruiseDatesImport',
            name: 'aida.cruiseDatesImport',
            meta: {
                siteTitle: "Reisedaten Import",
                menuTitle: "Reisedaten Import",
                layout: 'default',
                icon: 'pe-7s-help2',
                permission: {
                    requiredModule: 5,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/help/Help.vue'),
        },
        {
            header: false,
            path: '/aida/stockImport',
            name: 'aida.stockImport',
            meta: {
                siteTitle: "Bestandsdaten Import",
                menuTitle: "Bestandsdaten Import",
                layout: 'default',
                icon: 'pe-7s-help2',
                permission: {
                    requiredModule: 5,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/help/Help.vue'),
        },
        {
            header: false,
            path: '/aida/auctionList',
            name: 'aida.auctionList',
            meta: {
                siteTitle: "Auktionsliste",
                menuTitle: "Auktionsliste",
                layout: 'default',
                icon: 'pe-7s-help2',
                permission: {
                    requiredModule: 5,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/help/Help.vue'),
        },
        {
            header: false,
            path: '/aida/aidaArtReports',
            name: 'aida.aidaArtReports',
            meta: {
                siteTitle: ('accounting.lang_nav_accounting_reports'),
                menuTitle: ('accounting.lang_nav_accounting_reports'),
                layout: 'default',
                icon: 'pe-7s-help2',
                permission: {
                    requiredModule: 5,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/help/Help.vue'),
        },*/

        // --- ZEITERFASSUNG ---
        {},
        // --- SYSTEMEINSTELLUNGEN ---
        {},
        // --- SYSTEMEINSTELLUNGEN ---
        {},
        // --- SYSTEMEINSTELLUNGEN ---
        {},
        // HILFE
        {
            groups: [],
            childs: [],
            path: '/help',
            name: 'help',
            meta: {
                siteTitle: ('generic.lang_nav_help'),
                menuTitle: ('generic.lang_nav_help'),
                layout: 'default',
                icon: 'pe-7s-help2',
                permission: {}
            },
            component: () => import('../views/help/Help.vue'),
        },
        {
            path: '/pos/externalApp/',
            name: 'pos.externalApp',
            meta: {
                hideInNavigation: true,
                layout: 'default',
                permission: {
                    requiredModule: 65,
                    requiredPermission: "show_cashierui",
                    hybridDisplay: ""
                }
            },
            groups: [],
            childs: [],
            component: () => import('../views/pos/ExternalAppView.vue'),
        },

    ]
};
ROUTER.ENTRIES[12] = deliveryRoutes;
ROUTER.ENTRIES[13] = tableBeeRoutes;
ROUTER.ENTRIES[14] = kioskRoutes;
ROUTER.ENTRIES[15] = onlineShopRoutes;
ROUTER.ENTRIES[16] = pmsRoutes;
ROUTER.ENTRIES[17] = eventbeeRoutes;
ROUTER.ENTRIES[18] = canteenRoutes;
ROUTER.ENTRIES[19] = bookingRoutes;
ROUTER.ENTRIES[20] = replicationRoutes;
ROUTER.ENTRIES[21] = erpRoutes;
ROUTER.ENTRIES[22] = accountingRoutes;
ROUTER.ENTRIES[23] = analyticRoutes;
ROUTER.ENTRIES[24] = customerRoutes;
ROUTER.ENTRIES[25] = factureRoutes;
ROUTER.ENTRIES[26] = timeTrackRoutes;
ROUTER.ENTRIES[27] = settingRoutes;