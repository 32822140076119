var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-dots"},[_c('b-dropdown',{attrs:{"menu-class":[this.$vuetify.theme.dark ? 'dark-bg' : ''],"toggle-class":"p-0 mr-2","variant":"link","no-caret":"","right":""}},[_c('span',{attrs:{"slot":"button-content"},slot:"button-content"},[_c('div',{staticClass:"icon-wrapper icon-wrapper-alt rounded-circle"},[_c('div',{staticClass:"icon-wrapper-bg bg-focus"}),_c('div',{staticClass:"language-icon"},[(_vm.$root.$i18n.locale === 'de')?_c('country-flag',{staticClass:"opacity-8",staticStyle:{"margin-top":"0"},attrs:{"country":"DE"}}):_vm._e(),(_vm.$root.$i18n.locale === 'en')?_c('country-flag',{staticClass:"opacity-8",staticStyle:{"margin-top":"0"},attrs:{"country":"GB"}}):_vm._e(),(_vm.$root.$i18n.locale === 'fr')?_c('country-flag',{staticClass:"opacity-8",staticStyle:{"margin-top":"0"},attrs:{"country":"FR"}}):_vm._e()],1)])]),_c('div',{staticClass:"dropdown-menu-header"},[_c('div',{staticClass:"dropdown-menu-header-inner pt-4 pb-4 bg-focus"},[_c('div',{staticClass:"menu-header-image opacity-05 dd-header-bg-4"}),_c('div',{staticClass:"menu-header-content text-center text-white"},[_c('h6',{staticClass:"menu-header-subtitle mt-0"},[_vm._v(" "+_vm._s(_vm.$t('generic.lang_chooseLanguage'))+" ")])])])]),_c('button',{class:[
        this.$vuetify.theme.dark ? 'white--text' : '',
        'dropdown-item',
        _vm.$root.$i18n.locale === 'en' ? 'active' : '',
      ],attrs:{"type":"button","tabindex":"0"},on:{"click":function($event){return _vm.changeLanguage('en')}}},[_c('country-flag',{staticClass:"mr-3 opacity-8",staticStyle:{"margin-top":"-15px"},attrs:{"country":"GB"}}),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.$t('generic.lang_english')))])],1),_c('button',{class:[
        this.$vuetify.theme.dark ? 'white--text' : '',
        'dropdown-item',
        _vm.$root.$i18n.locale === 'de' ? 'active' : '',
      ],attrs:{"type":"button","tabindex":"0"},on:{"click":function($event){return _vm.changeLanguage('de')}}},[_c('country-flag',{staticClass:"mr-3 opacity-8",staticStyle:{"margin-top":"-15px"},attrs:{"country":"DE"}}),_c('span',[_vm._v(_vm._s(_vm.$t('generic.lang_germany')))])],1),_c('button',{class:[
        this.$vuetify.theme.dark ? 'white--text' : '',
        'dropdown-item',
        _vm.$root.$i18n.locale === 'fr' ? 'active' : '',
      ],attrs:{"type":"button","tabindex":"0"},on:{"click":function($event){return _vm.changeLanguage('fr')}}},[_c('country-flag',{staticClass:"mr-3 opacity-8",staticStyle:{"margin-top":"-15px"},attrs:{"country":"FR"}}),_c('span',[_vm._v(_vm._s(_vm.$t('generic.lang_french')))])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }