export let pmsRoutes = {
    meta: {
        siteTitle: ('generic.lang_pms_nav'),
        menuTitle: ('generic.lang_pms_nav'),
        layout: 'default',
        icon: 'pe-7s-culture',
        permission: {
            requiredModule: 60,
            requiredPermission: "",
            hybridDisplay: ""
        }
    },
    childs: [
        //start: dashboard
        {
            path: '/pms/dashboard',
            name: 'pms.dashboard',
            meta: {
                siteTitle: ('generic.lang_dashboard'),
                menuTitle: ('generic.lang_dashboard'),
                layout: 'default',
                icon: 'pe-7s-graph',
                permission: {
                    requiredModule: 60,
                    requiredPermission: "pms_dashboard",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/pms/PmsDashboardView'),
        },
        //end: dashboard

        //start: breakfast list
        {
            path: '/pms/breakfast-list',
            name: 'pms.breakfast-list',
            meta: {
                siteTitle: ('pms.lang_breakfast_list'),
                menuTitle: ('pms.lang_breakfast_list'),
                layout: 'default',
                icon: 'pe-7s-menu',
                permission: {
                    requiredModule: 60,
                    requiredPermission: "pms_breakfastlist",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/pms/PmsBreakfastListView'),
        },
        //end: breakfast list

        //start: settings
        {
            path: '/pms/settings',
            name: 'pms.settings',
            meta: {
                siteTitle: ('settings.lang_settings'),
                menuTitle: ('settings.lang_settings'),
                layout: 'default',
                icon: 'pe-7s-settings',
                permission: {
                    requiredModule: 60,
                    requiredPermission: "pms_settings",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/pms/PmsSettingsView'),
        },
        //end: settings
    ],
    groups: [],
};

