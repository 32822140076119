<template>
  <div class="header-dots">
    <b-dropdown
      :menu-class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
      toggle-class="p-0 mr-2"
      variant="link"
      no-caret
      right
    >
      <span slot="button-content">
        <div class="icon-wrapper icon-wrapper-alt rounded-circle">
          <div class="icon-wrapper-bg bg-focus"></div>
          <div class="language-icon">
            <country-flag
              v-if="$root.$i18n.locale === 'de'"
              style="margin-top: 0"
              country="DE"
              class="opacity-8"
            />
            <country-flag
              v-if="$root.$i18n.locale === 'en'"
              style="margin-top: 0"
              country="GB"
              class="opacity-8"
            />
            <country-flag
              v-if="$root.$i18n.locale === 'fr'"
              style="margin-top: 0"
              country="FR"
              class="opacity-8"
            />
          </div>
        </div>
      </span>

      <div class="dropdown-menu-header">
        <div class="dropdown-menu-header-inner pt-4 pb-4 bg-focus">
          <div class="menu-header-image opacity-05 dd-header-bg-4"></div>
          <div class="menu-header-content text-center text-white">
            <h6 class="menu-header-subtitle mt-0">
              {{ $t('generic.lang_chooseLanguage') }}
            </h6>
          </div>
        </div>
      </div>

      <button
        @click="changeLanguage('en')"
        type="button"
        tabindex="0"
        :class="[
          this.$vuetify.theme.dark ? 'white--text' : '',
          'dropdown-item',
          $root.$i18n.locale === 'en' ? 'active' : '',
        ]"
      >
        <country-flag
          style="margin-top: -15px"
          country="GB"
          class="mr-3 opacity-8"
        />
        <span class="text-capitalize">{{ $t('generic.lang_english') }}</span>
      </button>

      <button
        @click="changeLanguage('de')"
        type="button"
        tabindex="0"
        :class="[
          this.$vuetify.theme.dark ? 'white--text' : '',
          'dropdown-item',
          $root.$i18n.locale === 'de' ? 'active' : '',
        ]"
      >
        <country-flag
          style="margin-top: -15px"
          country="DE"
          class="mr-3 opacity-8"
        />
        <span>{{ $t('generic.lang_germany') }}</span>
      </button>
      <button
        @click="changeLanguage('fr')"
        type="button"
        tabindex="0"
        :class="[
          this.$vuetify.theme.dark ? 'white--text' : '',
          'dropdown-item',
          $root.$i18n.locale === 'fr' ? 'active' : '',
        ]"
      >
        <country-flag
          style="margin-top: -15px"
          country="FR"
          class="mr-3 opacity-8"
        />
        <span>{{ $t('generic.lang_french') }}</span>
      </button>
    </b-dropdown>
  </div>
</template>

<script>
import CountryFlag from 'vue-country-flag';
import { mapState } from 'vuex';

export default {
  name: 'HeaderLanguage',

  components: {
    CountryFlag,
  },

  methods: {
    changeLanguage(locale) {
      this.$store.dispatch('app/changeLanguage', locale).then((res) => {
        this.$root.$i18n.locale = locale;
        this.$vuetify.lang.current = locale;
        this.$moment.locale(locale);
      });
    },
  },

  computed: mapState(['app']),
};
</script>

<style scoped>
/* @media (min-width: 0px) and (max-width: 768px) {
  .dropdown-menu-header {
    padding-top:80px;
  }
} */
</style>
