import jsSHA from "jssha";
import {Events} from "./events";
import io from 'socket.io-client';
import Vue from 'vue';
import store from "@/store";
import {roundNumber} from "@/plugins/round";
import { format } from 'mathjs';

export default class ePosTSEPrinterClass {
  constructor(ePosDev, printerSettings, shouldSetup, loginAsAdmin, useProxy) {
    this.settings = printerSettings;
    this.shouldSetup = shouldSetup;
    this.loginAsAdmin = loginAsAdmin;
    this.useProxy = useProxy;

    this.germanyFiscalElement = null;
    this.ePosDev = ePosDev;
    this.proxyConnection = null;

    this.challenge = "";
    this.transactionNumber = 0;
    this.storageInfo = {};
    this.signature = "";
    this.logTime = "";
    this.signatureCounter = 0;
    this.connected = false;
    this.connectedFrom = "";
    this.tseReady = false;
    this.transactionPayload = {};
    this.tryReconnect = false;
    this.tryReconnectTempPayload = null;
    this.lastOperatePayload = null;
    this.lastOperationRunning = false;
    this.skipGFEReceiveCallback = false;
    this.runAsAdmin = true;

    this.selfTestTimer = null;

    this.events = new Vue();

    Events.$emit("eposDevice", {
      connectedFrom: this.connectedFrom,
      action: "init",
      deviceSettings: this.settings,
      result: null
    });

    if (!this.useProxy) {
      // --------- SINGLE TSE MODE -----------
      this.ePosDev.onerror = () => {
        this.connected = false;
        this.tseReady = false;
        this.runAsAdmin = true;

        Events.$emit("eposDevice", {
          connectedFrom: this.connectedFrom,
          action: "error",
          deviceSettings: this.settings,
          result: null
        });
      };

      this.ePosDev.ondisconnect = () => {
        this.connected = false;
        this.tseReady = false;
        this.runAsAdmin = true;

        Events.$emit("eposDevice", {
          connectedFrom: this.connectedFrom,
          action: "disconnect",
          deviceSettings: this.settings,
          result: null
        });
      };

      this.ePosDev.onreconnect = (data) => {
        Events.$emit("eposDevice", {
          connectedFrom: this.connectedFrom,
          action: "reconnect",
          deviceSettings: this.settings,
          result: null
        });

        this.connectCallback(data);
      };

      this.ePosDev.onreconnecting = () => {
        this.connected = false;
        this.tseReady = false;
        this.runAsAdmin = true;

        Events.$emit("eposDevice", {
          connectedFrom: this.connectedFrom,
          action: "reconnecting",
          deviceSettings: this.settings,
          result: null
        });
      };

      this.ePosDev.connect(this.settings.ip, this.settings.port, (data) => {
        this.connectCallback(data);
      });
    } else {
      this.connectProxy();
    }
  }

  setTryReconnect(val) {
    this.tryReconnect = val;
  }

  connect(connectedFrom = null) {
    //CHECK IF WE USE PROXY
    if (this.useProxy)
      return;

    this.ePosDev.connect(this.settings.ip, this.settings.port, (data) => {
      this.connectCallback(data);
    });
  }

  connectCallback(data) {
    if (data === 'OK' || data === 'SSL_CONNECT_OK') {
      this.connected = true;

      Events.$emit("eposDevice", {
        action: "connectCallback.success",
        result: null
      });

      this.ePosDev.createDevice(this.settings.deviceID, this.ePosDev.DEVICE_TYPE_GFE, {
        'crypto': false,
        'buffer': false
      }, (devobj, retcode) => {
        this.createDeviceCallback(devobj, retcode);
      });
    } else {
      this.connected = false;

      Events.$emit("eposDevice", {
        action: "connectCallback.error",
        result: null
      });

      if (this.tryReconnectTempPayload !== null) {
        //WE TRIED TO RECONNECT BUT ERROR OCCURS
        //EMIT RESULT TO ORIGIN FUNCTION
        Events.$emit("GFE_OnReceive", {
          result: {
            function: this.tryReconnectTempPayload.function,
            result: "connectCallback.error"
          }
        });

        //REMOVE TEMP PAYLOAD
        this.tryReconnectTempPayload = null;
      }
    }
  }

  createDeviceCallback(data, code) {
    if (data != null) {
      this.germanyFiscalElement = data;

      Events.$emit("eposDevice", {
        action: "createDeviceCallback.success",
        result: null
      });

      // FIRST GET CHALLENGE FOR ADMIN USER
      this.GFE_GetChallenge(this.settings.adminID);

      this.germanyFiscalElement.ongfereceive = (res) => {
        this.GFE_OnReceive(res);
      };

      this.germanyFiscalElement.onerror = (res) => {
        this.GFE_OnError(res);
      };
    } else {
      Events.$emit("eposDevice", {
        action: "createDeviceCallback.error",
        result: null,
        code: code
      });

      if (this.tryReconnectTempPayload !== null) {
        //WE TRIED TO RECONNECT BUT ERROR OCCURS
        //EMIT RESULT TO ORIGIN FUNCTION
        Events.$emit("GFE_OnReceive", {
          result: {
            function: this.tryReconnectTempPayload.function,
            result: "createDeviceCallback.error"
          }
        });

        //REMOVE TEMP PAYLOAD
        this.tryReconnectTempPayload = null;
      }
    }
  }

  disconnect(fullDisconnect = false) {
    if (!this.useProxy)
      this.ePosDev.disconnect();
    else {
      // CHECK IF ALSO WANT TO DISCONNECT TSE FROM TSE-SERVER
      // OR ONLY CLOSE THIS CLIENT CONNECTION
      if(fullDisconnect)
        this.proxyConnection.emit("closeDevice");

      this.proxyConnection.close();
    }
  }

  // ------------------- PROXY CONNECTION ------------------
  connectProxy() {

    console.log("connect proxy");

    this.proxyConnection = io('http://'+this.settings.TSEProxyIPAdress+':8040', {
      autoConnect: true,
      query: {
        tseDeviceID: this.settings.deviceID,
        tsePort: this.settings.port,
        tseIp: this.settings.ip,
        fiscalDeviceID: this.settings.id
      }
    });



    this.proxyConnection.on("connect", () => {
      console.log("CONNECT");
      Events.$emit("eposDevice", {
        connectedFrom: this.connectedFrom,
        action: "createDeviceCallback.success",
        deviceSettings: this.settings,
        result: null
      });

      //GET CHALLENGE FOR ADMIN USER
      this.GFE_GetChallenge(this.settings.adminID);
    });

    //PROXY ON RECONNECT
   /* this.proxyConnection.on("reconnect", () => {
      console.log("RECONNECT");
      Events.$emit("eposDevice", {
        connectedFrom: this.connectedFrom,
        action: "createDeviceCallback.success",
        deviceSettings: this.settings,
        result: null
      });

      //GET CHALLENGE FOR ADMIN USER
      this.GFE_GetChallenge(this.settings.adminID);
    });*/

    this.proxyConnection.on("disconnect", () => {
      console.log("DISCONNECT");
      this.tseReady = false;
      this.runAsAdmin = true;
    });

    this.proxyConnection.on("jsonCommand", (data) => {
      //FORWARD jsonCommand to internal event listener (function)
      this.GFE_OnReceive({
        success: true,
        resultdata: JSON.stringify(data)
      });
    });

    this.proxyConnection.on("eposDevice", (data) => {
      Events.$emit("eposDevice", data);
    });
  }

  // -----------------------------------------------------------

  // ------------------- GFE ------------------
  GFE_OnReceive(res) {
    if (res.success || !res.success) {
      let result = JSON.parse(res.resultdata);

      console.log(result);

      // TESTING ONLY
      // result.result = "TSE1_ERROR_WRONG_STATE_NEEDS_SELF_TEST";

      if (this.tryReconnectTempPayload === null) {
        Events.$emit("GFE_OnReceive", {
          result: result
        });

        Events.$emit("GFE_OnReceive." + result.function, {
          result: result
        });

        this.events.$emit("GFE_OnReceive", {
          result: result
        });

        this.events.$emit("GFE_OnReceive." + result.function, {
          result: result
        });
      } else {
        if (result.result !== "EXECUTION_OK") {
          //WE TRIED TO RECONNECT BUT ERROR OCCURS
          //EMIT RESULT TO ORIGIN FUNCTION
          Events.$emit("GFE_OnReceive", {
            result: {
              function: this.tryReconnectTempPayload.function,
              result: result.result
            }
          });

          //REMOVE TEMP PAYLOAD
          this.tryReconnectTempPayload = null;
        }
      }

      //CHECK IF WE SHOULD SKIP
      if (this.skipGFEReceiveCallback)
        return;

      // ON ERROR TRY AGAIN
      // NEED TO AUTHENTICATE AGAIN
      /*
      if (result.result === "OTHER_ERROR_UNAUTHENTICATED_ADMIN_USER") {
        if (this.lastOperatePayload !== null) {
          this.lastOperationRunning = true;

          this.runAsAdmin = true;
          this.GFE_GetChallenge(this.settings.adminID);
        }
      } else {
        if (!this.lastOperationRunning) {
          console.log("lastOperatePayload", "CLEAR");
          this.lastOperatePayload = null;
        }
      }
      */

      if (result.function === "GetChallenge") {
        if (result.result === "EXECUTION_OK") {
          if (!this.runAsAdmin)
            this.GFE_AuthenticateUserForTimeAdmin(this.settings.clientID, result.output.challenge);
          else
            this.GFE_AuthenticateUserForAdmin("Administrator", result.output.challenge);
        }
      }

      if (result.function === "AuthenticateUserForAdmin") {
        if (result.result === "EXECUTION_OK") {
          this.runAsAdmin = false;

          //CHECK IF NORMAL USER NEEDS AUTHENTICATED
          if (this.settings.clientID !== null)
            this.GFE_GetChallenge(this.settings.clientID);
        }
      }

      if (result.function === "AuthenticateUserForTimeAdmin") {
        if (result.result === "EXECUTION_OK") {
          this.GFE_UpdateTime(this.settings.clientID);
        }
      }

      if (result.function === "UpdateTime") {
        if (result.result === "EXECUTION_OK") {
          this.GFE_GetStorageInfo();
        }
      }

      if (result.function === "GetStorageInfo") {
        if (result.result === "EXECUTION_OK") {
          this.storageInfo = result.output;

          //TSE IS NOW READY
          this.tseReady = true;

          // SELF TEST (ONLY SINGLE MODE)
         // if (!this.useProxy)
            this.startSelfTestTimer();


          //CHECK IF WE TRIED TO RECONNECT
          if (this.tryReconnect && this.tryReconnectTempPayload !== null) {
            this.operate(this.tryReconnectTempPayload, true);
          }

          // CHECK IF LAST OPERATION FAILS
          if (this.lastOperatePayload !== null) {
            this.lastOperationRunning = false;
            this.operate(this.lastOperatePayload, true);
          }
        }
      }

      if (result.function === "RunTSESelfTest") {
        if (result.result === "EXECUTION_OK") {
          this.storageInfo = result.output;

          this.GFE_GetStorageInfo();
        }
      }
    }
  }

  GFE_OnError(e) {
    //console.log(e);
  }

  GFE_Setup() {
    let data = {
      storage: {
        type: "TSE",
        vendor: "TSE1"
      },
      function: "SetUp",
      input: {
        puk: "123456",
        adminPin: "11111",
        timeAdminPin: "22222",
      },
      compress: {
        required: false,
        type: ""
      }
    };

    this.operate(data);
  }

  GFE_SetupForPrinter() {
    let data = {
      storage: {
        type: "TSE",
        vendor: "TSE1"
      },
      function: "SetUpForPrinter",
      input: {
        puk: "123456",
        adminPin: "11111",
        timeAdminPin: "22222",
      },
      compress: {
        required: false,
        type: ""
      }
    };

    this.operate(data);
  }

  GFE_GetChallenge(userId = "Administrator") {
    let data = {
      storage: {
        type: "TSE",
        vendor: "TSE1"
      },
      function: "GetChallenge",
      input: {
        userId: userId
      },
      compress: {
        required: true,
        type: "zip_deflate"
      }
    };

    this.operate(data);
  }

  GFE_AuthenticateUserForAdmin(userId = "Administrator", challenge) {
    let data = {
      storage: {
        type: 'TSE',
        vendor: 'TSE1'
      },
      function: 'AuthenticateUserForAdmin',
      input: {
        userId: userId,
        pin: '11111',
        hash: this.calculateHash(challenge + 'EPSONKEY'),
      },
      compress: {
        required: true,
        type: 'zip_deflate'
      }
    };

    this.operate(data);
  }

  GFE_RegisterSecretKey(userId = "Administrator") {
    let data = {
      storage: {
        type: 'TSE',
        vendor: 'TSE1'
      },
      function: 'RegisterSecretKey',
      input: {
        userId: userId,
        secretKey: 'EPSONKEY'
      },
      compress: {
        required: true,
        type: 'zip_deflate'
      }
    };

    this.operate(data);
  }

  GFE_RegisterClient(clientID = "ClientIDPOS1") {
    let data = {
      storage: {
        type: "TSE",
        vendor: "TSE1"
      },
      function: "RegisterClient",
      input: {
        userId: "Administrator",
        clientId: clientID
      },
      compress: {
        required: true,
        type: "zip_deflate"
      }
    };

    this.operate(data);
  }

  GFE_GetRegisteredClientList() {
    let data = {
      storage: {
        type: 'TSE',
        vendor: 'TSE1'
      },
      function: 'GetRegisteredClientList',
      input: {},
      compress: {
        required: false,
        type: ''
      }
    };

    this.operate(data);
  }

  GFE_AuthenticateUserForTimeAdmin(clientId = "ClientIDPOS1", challenge) {
    let data = {
      storage: {
        type: "TSE",
        vendor: "TSE1"
      },
      function: "AuthenticateUserForTimeAdmin",
      input: {
        clientId: clientId,
        pin: "22222",
        hash: this.calculateHash(challenge + 'EPSONKEY'),
      },
      compress: {
        required: true,
        type: "zip_deflate"
      }
    };

    this.operate(data);
  }

  GFE_UpdateTime(clientId = "ClientIDPOS1") {
    let data = {
      storage: {
        type: "TSE",
        vendor: "TSE1"
      },
      function: "UpdateTime",
      input: {
        userId: clientId,
        newDateTime: (process.env.VUE_APP_MODE === 'testing' ? "2019-02-05T13:58:42Z" : this.getTime()),
        useTimeSync: false
      },
      compress: {
        required: true,
        type: "zip_deflate"
      }
    };

    this.operate(data);
  }

  GFE_GetStorageInfo() {
    let data = {
      storage: {
        type: "TSE",
        vendor: "TSE1"
      },
      function: "GetStorageInfo",
      input: {},
      compress: {
        required: true,
        type: "zip_deflate"
      }
    };

    this.operate(data);
  }

  GFE_GetLogMessageCertificate() {
    let data = {
      storage: {
        type: "TSE",
        vendor: "TSE1"
      },
      function: "GetLogMessageCertificate",
      input: {},
      compress: {
        required: false,
        type: ""
      }
    };

    this.operate(data);
  }

  GFE_StartTransaction(clientId = "ClientIDPOS1") {
    let data = {
      storage: {
        type: "TSE",
        vendor: "TSE1"
      },
      function: "StartTransaction",
      input: {
        clientId: clientId,
        processData: "",
        processType: "",
        additionalData: ""
      },
      compress: {
        required: true,
        type: "zip_deflate"
      }
    };

    this.operate(data);
  }

  GFE_FinishTransaction(clientId = "ClientIDPOS1", transactionNumber, processData, processType) {
    let data = {
      storage: {
        type: "TSE",
        vendor: "TSE1"
      },
      function: "FinishTransaction",
      input: {
        clientId: clientId,
        transactionNumber: transactionNumber,
        processData: processData,
        processType: processType,
        additionalData: ""
      },
      compress: {
        required: true,
        type: "zip_deflate"
      }
    };

    this.operate(data);
  }

  GFE_ArchiveExport() {
    //REQUIRES LOGGED IN AS ADMIN USER
    let data = {
      storage: {
        type: 'TSE',
        vendor: 'TSE1'
      },
      function: 'ArchiveExport',
      input: {},
      compress: {
        required: false,
        type: ''
      }
    };

    this.operate(data);
  }

  GFE_GetExportData() {
    //REQUIRES LOGGED IN AS ADMIN USER
    let data = {
      storage: {
        type: 'TSE',
        vendor: 'TSE1'
      },
      function: 'GetExportData',
      input: {},
      compress: {
        required: false,
        type: 'zip_deflate'
      }
    };

    this.operate(data);
  }

  GFE_FinalizeExport(deleteData = true) {
    //REQUIRES LOGGED IN AS ADMIN USER
    let data = {
      storage: {
        type: 'TSE',
        vendor: 'TSE1'
      },
      function: 'FinalizeExport',
      input: {
        deleteData: deleteData
      },
      compress: {
        required: false,
        type: ''
      }
    };

    this.operate(data);
  }

  GFE_DisableSecureElement() {
    //REQUIRES LOGGED IN AS ADMIN USER
    let data = {
      storage: {
        type: 'TSE',
        vendor: 'TSE1'
      },
      function: 'DisableSecureElement',
      input: {},
      compress: {
        required: false,
        type: ''
      }
    };

    this.operate(data);
  }

  GFE_GetStartedTransactionList(clientId = "ClientIDPOS1") {
    let data = {
      storage: {
        type: 'TSE',
        vendor: 'TSE1'
      },
      function: 'GetStartedTransactionList',
      input: {
        clientId: clientId
      },
      compress: {
        required: false,
        type: ''
      }
    };

    this.operate(data);
  }

  GFE_RunTSESelfTest() {
    Events.$emit("GFE_RunningSelfTest");

    //REQUIRES LOGGED IN AS ADMIN USER
    let data = {
      storage: {
        type: 'TSE',
        vendor: 'TSE1'
      },
      function: 'RunTSESelfTest',
      input: {},
      compress: {
        required: false,
        type: ''
      }
    };

    this.operate(data);
  }

  // -------- CUSTOM FUNCTIONS ----------
  finishInvoiceTransaction(clientId = "ClientIDPOS1", transactionPayload, positions, paymentType, sellerID, splitPayment) {

    this.GFE_FinishTransaction(clientId, transactionPayload.transactionNumber, this.createInvoiceProcessData(positions, paymentType, sellerID, splitPayment), "Kassenbeleg-V1");
  }

  finishOrderbonTransaction(clientId = "ClientIDPOS1", transactionPayload, positions) {
    this.GFE_FinishTransaction(clientId, transactionPayload.transactionNumber, this.createOrderbonProcessData(positions), "Beleg");
  }

  finishUnfinishedTransaction(clientId = "ClientIDPOS1", transactionNumber) {
    this.GFE_FinishTransaction(clientId, transactionNumber, this.createUnfinishedTransactionProcessData(), "Kassenbeleg-V1");
  }

  createInvoiceProcessData(positions = [], paymentType = 0, sellerID = 101, splitPayment = [], encrypt = true) {
    let string;

    if (sellerID !== 101)
      string = "Beleg";
    else
      string = "AVTraining";

    /*
    --- TAX ---
    1. Allgemeiner Steuersatz (19% / 16%)
    2. Ermäßigter Steuersatz (7% / 5%)
    3. Durchschnittsatz (§24(1)Nr.3 UStG) (10.7%)
    4. Durchschnittsatz (§24(1)Nr.1 UStG) (5.5%)
    5. Gutscheine (0%)
    */
    let total = 0.00;
    let taxValues = [0.00, 0.00, 0.00, 0.00, 0.00];

    positions.forEach((position) => {
      //CHECK IF IS VOID
      if (position.isVoid)
        return;

      //GET SELLPRICE + CHECK DISCOUNT
      //let sellPrice = ((100 - position.discount) / 100) * position.sellPrice;

      if (typeof(position.discount) == "undefined"){
        position.discount = 0.00;
      }
      if (typeof(position.taxValue) == "undefined"){
        position.taxValue = 0.00;
      }
      if (typeof(position.takeAwayTaxing) == "undefined"){
        position.takeAwayTaxing = 0.00;
      }
      let sellPrice = parseFloat((((((position.sellPrice * ((100 - position.discount) / 100)) + Number.EPSILON) * 100) / 100)));
      sellPrice = format(sellPrice, {precision: 14});
      sellPrice = roundNumber(sellPrice,2);


      //CHECK DEPOSIT
      if (position.hasOwnProperty("depositgroupID")) {
        if (position.depositgroupID > 0) {
          let deposit = store.getters['deposit/depositgroups'].find((depositGroup) => {
            return depositGroup.id === position.depositgroupID;
          });

          if (deposit)
            sellPrice += deposit.priceTotal;
        }
      }

      sellPrice = sellPrice * position.amount;
      // CHECK IF WE HAVE MENU ITEM
      if(position.isMenuItem === 1) {
        // MENU ITEM
        // GET TAX VALUES FROM ITEM
        for(let taxValue in position.menuTaxRules) {
          if (!position.menuTaxRules.hasOwnProperty(taxValue))
            continue;

          let taxPercentage = position.menuTaxRules[taxValue];
          taxPercentage = taxPercentage / 100;

          // PARSE TAX VALUE
          taxValue = parseFloat(taxValue);

          if (taxValue === 19 || taxValue === 16)
            taxValues[0] += (sellPrice * taxPercentage);

          if (taxValue === 7 || taxValue === 5)
            taxValues[1] += (sellPrice * taxPercentage);

          if (taxValue === 0)
            taxValues[4] += (sellPrice * taxPercentage);
        }
      }
      else {
        if(parseFloat(position.taxValue) === 19 || parseFloat(position.taxValue) === 16)
          taxValues[0] += sellPrice;

        if(parseFloat(position.taxValue) === 7 || parseFloat(position.taxValue) === 5)
          taxValues[1] += sellPrice;

        if(parseFloat(position.taxValue) === 0)
          taxValues[4] += sellPrice;
      }

      //ADD TO TOTAL
      total += sellPrice;
    });

    string += "^" + taxValues[0].toFixed(2) + "_" + taxValues[1].toFixed(2) + "_" + taxValues[2].toFixed(2) + "_" + taxValues[3].toFixed(2) + "_" + taxValues[4].toFixed(2);


    //PAYMENT TYPES
    let bar = 0.00;
    let unbar = 0.00;

    if (paymentType > 0 && paymentType !== 7) {
      if (paymentType === 1) {
        bar = total;
      } else {
        unbar = total;
      }
    } else if (paymentType === 7 && splitPayment.length > 0) {
      //LOOP THROUGH SPLIT PAYMENT OBJECT
      splitPayment.forEach((payment) => {
        if (payment.id === 1) {
          bar += parseFloat(payment.amount);
        } else {
          unbar += parseFloat(payment.amount);
        }
      });
    }

    string += "^";
    if(bar.toFixed(2) != 0.00){
      string += bar.toFixed(2) + ":Bar";
    }
    if(bar.toFixed(2) != 0.00 && unbar.toFixed(2) != 0.00){
      string += "_"
    }
    if(unbar.toFixed(2) != 0.00) {
      string += unbar.toFixed(2) + ":Unbar";
    }
    if (encrypt) {
      return window.btoa(string);
    } else {
      return string;
    }
  }

  createOrderbonProcessData(positions = [], encrypt = true) {
    let string = "";

    positions.forEach((position) => {
      //CHECK IF IS VOID
      if (position.isVoid)
        return;

      //ESCAPE NAME
      let name = position.name.replace('"', '""');
      let sellPrice = position.sellPrice.toFixed(2);

      string += position.amount + ';"' + name + '";' + sellPrice + '\r';
    });

    if (encrypt) {
      return window.btoa(string);
    } else {
      return string;
    }
  }

  createUnfinishedTransactionProcessData(encrypt = true) {
    let string = "AVBelegabbruch^0.00_0.00_0.00_0.00_0.00^0.00:Bar_0.00:Unbar";

    if (encrypt) {
      return window.btoa(string);
    } else {
      return string;
    }
  }

  createInvoiceQRCode(positions, paymentType, sellerID, splitPayment, transactionNumber, signatureCounter, startTime, logTime, signature, clientID) {
    let string = "V0;" + clientID + ";Kassenbeleg-V1;";
    string += this.createInvoiceProcessData(positions, paymentType, sellerID, splitPayment, false) + ";";
    string += transactionNumber + ";";
    string += signatureCounter + ";";
    string += startTime + ";";
    string += logTime + ";";
    string += this.storageInfo.tseInformation.signatureAlgorithm + ";";
    string += "unixTime;";
    string += signature + ";";
    string += this.storageInfo.tseInformation.tsePublicKey;

    return string;
  }

  calculateHash(input) {
    var shaObj = new jsSHA("SHA-256", "TEXT");
    shaObj.update(input);

    return window.btoa(shaObj.getHash("BYTES"));
  }

  getTime() {
    var date = new Date();
    date = date.toISOString();

    return (date.split('.')[0] + "Z");
  }

  operate(data, removeData = false) {
    if (this.tryReconnect && this.tryReconnectTempPayload === null && !this.tseReady && !this.connected) {
      //SAVE OPERATE DATA IN TEMP VAR
      this.tryReconnectTempPayload = data;

      //TRY TO RECONNECT
      if (!this.useProxy) {
        this.connect();
      } else {
        this.connectProxy();
      }

      return;
    }

    //REMOVE TEMP PAYLOAD
    if (removeData) {
      this.tryReconnectTempPayload = null;
      this.lastOperatePayload = null;
    }

    // SAVE OPERATE DATA, IN CASE OPERATION FAILS
    // BUT ONLY WHEN PAYLOAD IS EMPTY / NULL
    /*
    if (this.lastOperatePayload === null) {
      this.lastOperatePayload = data;
    }
    */

    let timeout = 50000;

    try {
      let result;

      if (!this.useProxy)
        result = this.germanyFiscalElement.operate(JSON.stringify(data), timeout);
      else
        result = this.proxyConnection.emit("jsonCommand", {
          eventName: data.function,
          json: data
        });

      return {success: true, result: result}

    } catch (e) {
      return {success: false};
    }
  }

  getValue(obj, key, undefinedValue) {
    var splitedKey = key.split('.');
    var value = obj;
    for (var i = 0; i < splitedKey.length; i++) {
      if (!(splitedKey[i] in value)) {
        return undefinedValue;
      }
      value = value[splitedKey[i]];
    }
    return value;
  }

  startSelfTestTimer() {
    // CLEAR OLD TIMEOUT
    clearTimeout(this.selfTestTimer);

    let nextSelfTest = this.storageInfo.tseInformation.timeUntilNextSelfTest;
    //nextSelfTest -= 72000; // -20 hour
    nextSelfTest -= 36000; // -10 hour
    //nextSelfTest -= 89800; // -20 hour

    console.log("started startSelfTestTimer in " + nextSelfTest + " seconds");

    this.selfTestTimer = setTimeout(() => {
      this.tseReady = false;
      if (!this.useProxy) {
        this.GFE_RunTSESelfTest();
      }else{
        let selfTestData = {
          storage: {
            type: 'TSE',
            vendor: 'TSE1'
          },
          function: 'RunTSESelfTest',
          input: {},
          compress: {
            required: false,
            type: ''
          }
        };
        this.proxyConnection.emit("jsonCommand", {
          eventName: "RunTSESelfTest",
          json: selfTestData
        });


        let updateTimeData = {
          storage: {
            type: "TSE",
            vendor: "TSE1"
          },
          function: "UpdateTime",
          input: {
            userId: this.settings.clientID,
            newDateTime:  this.getTime(),
            useTimeSync: false
          },
          compress: {
            required: true,
            type: "zip_deflate"
          }
        };
        console.log(updateTimeData);
        setTimeout(function(that){
          console.log(updateTimeData);
          that.proxyConnection.emit("jsonCommand", {
          eventName: "UpdateTime",
          json: updateTimeData
        });
        },25000,this);

        Events.$emit("GFE_RunningSelfTest");
      }
    }, nextSelfTest * 1000);
  }
}
