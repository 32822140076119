<template>
  <div class="d-inline-flex">
    <div v-if="!this.$vuetify.breakpoint.smAndDown" class="mx-2 my-auto" style="font-size: 0.9em;font-weight: bolder;">
      <div class="small">{{api.auth.posID + ' - ' + settings.settings.system_name }} ({{ cashierAlias }})</div>
      <div class="small">{{ this.currentTime }}</div>
    </div>
    <div v-if="!this.$vuetify.breakpoint.smAndDown">
      <v-progress-circular
          v-if="TSEPrinterStatus === 'LOADING'"
          indeterminate
          color="primary"
          style="margin-right: 20px;"
      />

      <div v-else class="header-dots" style="margin-right: 10px;">
            <span slot="button-content">
                <div class="icon-wrapper icon-wrapper-alt rounded-circle">
                    <div class="icon-wrapper-bg bg-focus"></div>
                    <v-icon :style="{'color': tseStateColor}" size="30">sim_card</v-icon>
                </div>
            </span>
      </div>
    </div>
    <div v-else>
      <v-progress-circular
          v-if="TSEPrinterStatus === 'LOADING'"
          indeterminate
          color="primary"
          style="margin-right: 20px;"
      />


      <span v-else slot="button-content">
                <div style="max-width: 25px !important; max-height: 25px !important"
                     class="icon-wrapper icon-wrapper-alt rounded-circle">
                    <div class="icon-wrapper-bg bg-focus"></div>
                    <v-icon :style="{'color': tseStateColor}" size="20">sim_card</v-icon>
                </div>
            </span>

    </div>

    <!-- ----------------- ERROR DIALOGS ----------------- -->
    <!-- DEVICE IN USE -->
    <v-dialog v-model="showDeviceinuseError" persistent width="600">
      <v-card>
        <v-card-title>{{ $t("generic.lang_tseInUse") }}</v-card-title>
        <v-card-text style="font-size: 18px;" class="text-center" v-html="$t('generic.lang_deviceInUseErrorBody')">
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="showDeviceinuseError = false" :disabled="refreshLoading">{{ $t("generic.lang_understand") }}</v-btn>
          <v-btn color="success" @click="refreshPage" :loading="refreshLoading" :disabled="refreshLoading">
            {{ $t("generic.lang_checkoutReload") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- NEEDS SELF TEST -->
    <v-dialog v-model="showNeedsSelfTestError" persistent width="600">
      <v-card>
        <v-card-title>{{ $t("generic.lang_tseNeedsReboot") }}</v-card-title>
        <v-card-text style="font-size: 18px; margin-top: 20px;" class="text-center" v-html="$t('generic.lang_needsSelfTestErrorBody')">
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" @click="refreshPage" :loading="refreshLoading" :disabled="refreshLoading">
            {{ $t("generic.lang_checkoutReload") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {Events} from "@/plugins/events";

export default {
  name: "HeaderPrinters",

  data() {
    return {
      eposDeviceState: null,
      showDeviceinuseError: false,
      showNeedsSelfTestError: false,
      refreshLoading: false,
      currentTime: null,
      timer: null
    }
  },

  computed: {
    ...mapState([
      "api",
      "cashierIDs",
      "settings"
    ]),
    TSEPrinterStatus() {
      return this.eposDeviceState;
    },
    tseStateColor() {
      if (this.TSEPrinterStatus === "READY")
        return "#81C784";
      else if (this.TSEPrinterStatus === "ERROR")
        return "#ff0000";
      else
        return "#FF6D00";
    },
    cashierAlias() {
      let alias = ""
      let currentCashier = this.cashierIDs.cashiers.find(cashier => {
        return cashier.id === this.api.auth.cashierID
      });
      alias = currentCashier.aliasName;
      return alias;
    }
  },

  mounted() {
    Events.$on("eposDevice", (payload) => {
      if (payload.action === "disconnect")
        this.eposDeviceState = "DISCONNECTED";

      if (payload.action === "createDeviceCallback.error")
        if (payload.code === "DEVICE_IN_USE")
          this.showDeviceinuseError = true;
    });

    Events.$on("GFE_RunningSelfTest", () => {
      this.eposDeviceState = "LOADING";
    });

    Events.$on("GFE_OnReceive", (payload) => {
      if (payload.result.result === "EXECUTION_OK") {
        if (payload.result.function === "GetChallenge")
          this.eposDeviceState = "LOADING";

        if (payload.result.function === "GetStorageInfo")
          this.eposDeviceState = "READY";
      } else if (payload.result.result === "TSE1_ERROR_WRONG_STATE_NEEDS_SELF_TEST") {
        this.showNeedsSelfTestError = true;
        this.eposDeviceState = "DISCONNECTED";
      }
    });

    //update current time
    this.timer = setInterval(() => {
      this.currentTime = this.$moment().format('DD.MM.yyyy HH:mm');
      this.$forceUpdate();
    }, 1000)

  },
  beforeDestroy() {
    if (this.timer)
      clearInterval(this.timer)
  },
  methods: {
    refreshPage() {
      this.refreshLoading = true;

      window.location = '';
    }
  }
}
</script>
