export let kioskRoutes = {
    meta: {
        menuTitle: ('Kiosk'),
        icon: 'pe-7s-albums',
        permission: {
            requiredModule: 67,
            requiredPermission: "",
            hybridDisplay: ""
        }
    },
    childs: [
        //kiosk overview
        {
            path: '/kiosk/overview',
            name: 'kiosk.overview',
            meta: {
                siteTitle: ('generic.lang_overview'),
                menuTitle: ('generic.lang_overview'),
                layout: 'default',
                icon: 'pe-7s-pen',
                permission: {
                    requiredModule: 67,
                    requiredPermission: "manage_kiosk",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/kiosk/KioskOverview')
        },
        {
            path: '/kiosk/design',
            name: 'kiosk.design',
            meta: {
                siteTitle: ('tablebee.lang_design_settings'),
                menuTitle: ('tablebee.lang_design_settings'),
                layout: 'default',
                icon: 'pe-7s-pen',
                permission: {
                    requiredModule: 67,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/kiosk/KioskDesignView')
        },
        {
            path: '/kiosk/products-management',
            name: 'kiosk.products.management',
            meta: {
                siteTitle: ('erp.lang_products_management'),
                menuTitle: ('erp.lang_products_management'),
                layout: 'default',
                icon: 'pe-7s-box1',
                permission: {
                    requiredModule: 67,
                    requiredPermission: "manage_kiosk",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/kiosk/KioskProductsView')
        },
        // Start kiosk terminals
        {
            path: '/kiosk/terminals',
            name: 'kiosk.terminals',
            meta: {
                siteTitle: ('kiosk.lang_kiosk_management'),
                menuTitle: ('kiosk.lang_kiosk_management'),
                layout: 'default',
                icon: 'pe-7s-display2',
                permission: {
                    requiredModule: 67,
                    requiredPermission: "manage_kiosk",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/kiosk/kioskManagement/TerminalsView')
        },
        {
            path: '/kiosk/terminals/create-terminal',
            name: 'kiosk.terminals.create.terminal',
            meta: {
                hideInNavigation: true,
                siteTitle: ('kiosk.lang_kiosk_management'),
                menuTitle: ('kiosk.lang_kiosk_management'),
                layout: 'default',
                icon: '',
                permission: {
                    requiredModule: 67,
                    requiredPermission: "manage_kiosk",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/kiosk/kioskManagement/CreateTerminalView')
        },
        {
            props: true,
            path: '/kiosk/terminals/edit-terminal/:id',
            name: 'kiosk.terminals.edit.terminal',
            meta: {
                hideInNavigation: true,
                siteTitle: ('kiosk.lang_kiosk_management'),
                menuTitle: ('kiosk.lang_kiosk_management'),
                layout: 'default',
                icon: '',
                permission: {
                    requiredModule: 67,
                    requiredPermission: "manage_kiosk",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/kiosk/kioskManagement/EditTerminalView')
        },
        // End kiosk terminals
        {
            path: '/kiosk/menu-assignment',
            name: 'kiosk.menu.assignment',
            meta: {
                siteTitle: ('generic.lang_menu_assignment'),
                menuTitle: ('generic.lang_menu_assignment'),
                layout: 'default',
                icon: 'pe-7s-pen',
                permission: {
                    requiredModule: 67,
                    requiredPermission: "manage_kiosk",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/kiosk/menuAssignment/MenuAssignmentView')
        },
        {
            props: true,
            path: '/kiosk/menu-assignment/edit-menu-assignment/:id',
            name: 'kiosk.menu.assignment.edit.menu.assignment',
            meta: {
                hideInNavigation: true,
                siteTitle: ('generic.lang_menu_assignment'),
                menuTitle: ('generic.lang_menu_assignment'),
                layout: 'default',
                icon: 'pe-7s-pen',
                permission: {
                    requiredModule: 67,
                    requiredPermission: "manage_kiosk",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/kiosk/menuAssignment/EditMenuAssignmentView')
        },
        {
            path: '/kiosk/images-template/',
            name: 'kiosk.kisokImagesTemplate',
            meta: {
                siteTitle: ('settings.lang_imageTemplates'),
                menuTitle: ('settings.lang_imageTemplates'),
                layout: 'default',
                icon: 'pe-7s-config',
                permission: {
                    requiredModule: 67,
                    requiredPermission: "manage_kiosk",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/kiosk/KioskImagesTemplates')
        },
        {
            path: '/kiosk/global-settings',
            name: 'kiosk.global.settings',
            meta: {
                siteTitle: ('settings.lang_settings'),
                menuTitle: ('settings.lang_settings'),
                layout: 'default',
                icon: 'pe-7s-config',
                permission: {
                    requiredModule: 67,
                    requiredPermission: "manage_kiosk",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/kiosk/KioskGlobalSettingView')
        },
        {
            path: '/kiosk/images-template/edit/:id',
            name: 'kiosk.kisokImagesTemplate.edit',
            meta: {
                siteTitle: ('settings.lang_settings'),
                menuTitle: ('settings.lang_settings'),
                layout: 'default',
                icon: 'pe-7s-config',
                hideInNavigation: true,
                permission: {
                    requiredModule: 67,
                    requiredPermission: "manage_kiosk",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/kiosk/KioskImageTemplate')
        },
    ],
    groups: []
};
