export default {
    namespaced: true,

    state: {
        availableCashierIDs: [],
        cashiers: []
    },

    mutations: {
        update(state, data) {
            state.availableCashierIDs = data;
        },
        setCashiers(state, data) {
            state.cashiers = data;
        },
    },
    getters: {
        cashiers: (state) => state.cashiers
    },
    actions: {
        update({commit}, data) {
            commit("update", data);
        },
        setCashiers({commit}, data) {
            commit("setCashiers", data);
        },
    },
};
