import randomString from "randomstring";
import Vue from "vue";
import { roundNumber } from "@/plugins/round";
import { format } from "mathjs";
import { calcGrossPrice, getNetPrice } from "@/plugins/tax";

//CHECK IF THE ITEM IS A GIFTCARD OR VOUCER ETC...
const isSpecialItem = (item, specialPrefixes) => {
  return specialPrefixes.some((prefix) => item.ean.startsWith(prefix));
};

export default {
  namespaced: true,

  state: {
    customer: null,
    customerProject: null,
    freeText: null,
    orderedItems: [],
    selectedItem: null,
    savedOrderID: 0,
    transactionPayload: {},
    additionalStatisticUUIDs: [],
  },

  mutations: {
    setAdditionalStatistic(state, val) {
      state.additionalStatisticUUIDs = val;
    },
    setFreetext(state, text) {
      state.freeText = text;
    },
    bookItem(state, item) {
      //RESET SELECTED
      state.selectedItem = null;

      const newItem = {
        amount: isNaN(parseFloat(item.weight)) ? 1.0 : item.weight,
        isWeighted: item.isWeighted,
        scalePositionId: item.scalePositionId,
        randomPositionID: randomString.generate(15),
        id: item.id,
        name:
          item.warenameOverwrite !== null ? item.warenameOverwrite : item.name,
        ean: item.ean,
        course: state.selectedCourse,
        icon: item.icon,
        badgeColor: item.badgeColor,
        sellPrice: item.sellPrice,
        netPrice: getNetPrice(item.sellPrice, item.taxValue, 2),
        originalSellPrice: item.originalSellPrice,
        selectedExtras: item.selectedExtras,
        discount: item.discount,
        mealSizeID: item.mealSizeID,
        internalWareID: item.internalWareID,
        itemgroupID: item.itemgroupID,
        depositgroupID: item.depositgroupID,
        isPriceChanged: item.isPriceChanged,
        isVoid: false,
        needsVoidPrint: false,
        voidReason: "",
        freeText: item.freeText,
        taxValue: item.taxValue,
        takeAwayTaxing: item.takeAwayTaxing,
        isVariation: item.isVariation,
        variationColor: item.variationColor,
        variationSize: item.variationSize,
        bestBeforeDate: item.bestBeforeDate,
        batchNo: item.batchNo,
        serialNo: item.serialNo,
        isMenuItem: item.isMenuItem,
        menuTaxRules: item.menuTaxRules,
        payOutItem: item.payOutItem,
        additionalStatisticUUIDs: [],
        individualBookingNo: item.individualBookingNo,
        itemNotDiscountable: item.itemNotDiscountable === 1,
        priceCannotChange: item.priceCannotChange === 1,
      };

      if (item?.ean?.includes("GF$") && item?.min_redeem_cart_value) {
        newItem.min_redeem_cart_value = item.min_redeem_cart_value;
      }

      Vue.set(state.orderedItems, state.orderedItems.length, newItem);

      //SELECT ITEM
      this.commit("pos/wholesaleRetail/selectInvoiceItem", newItem);
    },
    selectInvoiceItem(state, item) {
      if (state.selectedItem !== item) {
        state.selectedItem = item;
      } else {
        state.selectedItem = null;
      }
    },
    setItems(state, data) {
      state.orderedItems = data.orders;
    },
    appendItems(state, data) {
      state.orderedItems.push(...data.orders);
    },
    setCustomer(state, data) {
      state.customer = data;
    },
    setCustomerProject(state, data) {
      state.customerProject = data;
    },
    numpadQTY(state, data) {
      if (state.selectedItem !== null) {
        if (
          state.selectedItem &&
          !state.selectedItem.isWeighted &&
          !state.selectedItem.isVoid &&
          !isSpecialItem(state.selectedItem, [
            "EVTB$",
            "V$",
            "GF$",
            "ZMYLE$",
            "MYWORLD$",
          ])
        ) {
          state.selectedItem.amount =
            data > 0 ? parseFloat(data.replace(",", ".")) : 0;
        }
      }
    },
    numpadPrice(state, data) {
      if (state.selectedItem !== null) {
        if (
          !state.selectedItem.isVoid &&
          !state.selectedItem.priceCannotChange &&
          !isSpecialItem(state.selectedItem, [
            "V$",
            "GF$",
            "ZMYLE$",
            "MYWORLD$",
          ])
        ) {
          //CHECK IF PAY OUT ITEM
          if (state.selectedItem.payOutItem === 1) {
            state.selectedItem.netPrice = parseFloat(data) * -1;
            state.selectedItem.sellPrice =
              calcGrossPrice(parseFloat(data), state.selectedItem.taxValue, 2) *
              -1;
          } else {
            state.selectedItem.netPrice = parseFloat(data);
            state.selectedItem.sellPrice = calcGrossPrice(
              parseFloat(data),
              state.selectedItem.taxValue,
              2
            );
          }

          if (
            state.selectedItem.sellPrice !==
            state.selectedItem.originalSellPrice
          ) {
            state.selectedItem.isPriceChanged = true;
          } else {
            state.selectedItem.isPriceChanged = false;
          }
        }
      }
    },
    openSavedOrder(state, data) {
      state.savedOrderID = data.id;
    },
    reset(state) {
      state.selectedItem = null;
      state.freeText = null;
      state.customer = null;
      state.customerProject = null;
      state.orderedItems = [];
      state.savedOrderID = 0;
      state.numberOfScannedItems = 0;
      state.transactionPayload = {};
    },
    setTransactionPayload(state, payload) {
      state.transactionPayload = payload;
    },
  },

  actions: {
    bookItem({ commit }, data) {
      commit("bookItem", data);
    },
    selectInvoiceItem({ commit }, data) {
      commit("selectInvoiceItem", data);
    },
    appendItems({ commit }, data) {
      commit("appendItems", data);
    },
    setItems({ commit }, data) {
      commit("setItems", data);
    },
    setCustomer({ commit }, data) {
      commit("setCustomer", data);
    },
    setCustomerProject({ commit }, data) {
      commit("setCustomerProject", data);
    },
    numpadQTY({ commit }, data) {
      commit("numpadQTY", data);
    },
    numpadPrice({ commit }, data) {
      commit("numpadPrice", data);
    },
    openSavedOrder({ commit }, data) {
      commit("openSavedOrder", data);
    },
    setTransactionPayload({ commit }, data) {
      commit("setTransactionPayload", data);
    },
  },

  getters: {
    bookedItemsCount(state) {
      if (!Array.isArray(state.orderedItems)) {
        return 0;
      }

      return state.orderedItems.length;
    },
    totalPrice: (state, getters, rootState) => {
      let total = 0;

      state.orderedItems.forEach((item) => {
        let itemPrice = 0;

        //SKIP IF VOIDED
        if (item.isVoid || item.amount === 0) return;

        //CHECK DISCOUNT
        let discount = 0;
        if (item.hasOwnProperty("discount")) discount = item.discount;

        //itemPrice = parseFloat((Math.round(((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100).toFixed(2));
        itemPrice = parseFloat(
          ((item.sellPrice * ((100 - discount) / 100) + Number.EPSILON) * 100) /
            100
        );

        itemPrice = format(itemPrice, { precision: 14 });
        itemPrice = roundNumber(itemPrice, 2);

        //CHECK IF DEPOSIT
        if (item.hasOwnProperty("depositgroupID")) {
          if (item.depositgroupID > 0) {
            let deposit = rootState.deposit.deposits.find((depositGroup) => {
              return depositGroup.id === item.depositgroupID;
            });

            if (deposit) itemPrice += deposit.priceTotal;
          }
        }

        let weightCalc = 0.0;
        weightCalc = parseFloat(itemPrice * item.amount);
        weightCalc = format(weightCalc, { precision: 14 });
        weightCalc = roundNumber(weightCalc, 2);

        total += parseFloat(weightCalc);
        total = format(total, { precision: 14 });
        total = roundNumber(total, 2);
      }, 0);

      return total;
    },
    subTotalPrice: (state, getters, rootState) => {
      let total = 0;

      state.orderedItems.forEach((item) => {
        let itemPrice = 0;

        //SKIP IF VOIDED
        if (item.isVoid || item.amount === 0) return;

        //CHECK DISCOUNT
        let discount = 0;
        if (item.hasOwnProperty("discount")) discount = item.discount;

        itemPrice =
          ((getNetPrice(item.sellPrice, item.taxValue) *
            ((100 - discount) / 100) +
            Number.EPSILON) *
            100) /
          100;

        //CHECK IF DEPOSIT
        if (item.hasOwnProperty("depositgroupID")) {
          if (item.depositgroupID > 0) {
            let deposit = rootState.deposit.deposits.find((depositGroup) => {
              return depositGroup.id === item.depositgroupID;
            });

            if (deposit) itemPrice += deposit.priceTotal;
          }
        }

        let weightCalc = itemPrice * item.amount;

        total += weightCalc;
      }, 0);

      return roundNumber(total, 2);
    },
    goodsValue: (state, getters, rootState) => {
      let total = 0;

      state.orderedItems.forEach((item) => {
        let itemPrice = 0;

        //CHECK IF VOIDED
        if (item.isVoid || item.amount === 0) return;

        if (
          item.sellPrice < 0 &&
          isSpecialItem(item, ["V$", "GF$", "ZMYLE$", "MYWORLD$"])
        ) {
          return;
        }
        //CHECK DISCOUNT
        let discount = 0;
        if (item.hasOwnProperty("discount")) discount = item.discount;

        //itemPrice = parseFloat((Math.round(((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100).toFixed(2));
        itemPrice = parseFloat(
          ((item.sellPrice * ((100 - discount) / 100) + Number.EPSILON) * 100) /
            100
        );
        itemPrice = format(itemPrice, { precision: 14 });
        itemPrice = roundNumber(itemPrice, 2);
        //CHECK IF DEPOSIT
        if (item.hasOwnProperty("depositgroupID")) {
          if (item.depositgroupID > 0) {
            let deposit = rootState.deposit.deposits.find((depositGroup) => {
              return depositGroup.id === item.depositgroupID;
            });

            if (deposit) itemPrice += parseFloat(deposit.priceTotal);
          }
        }

        let weightCalc = 0.0;
        weightCalc = parseFloat(itemPrice * item.amount);
        weightCalc = format(weightCalc, { precision: 14 });
        weightCalc = roundNumber(weightCalc, 2);

        total += parseFloat(weightCalc.toFixed(2));
      }, 0);
      return parseFloat(total.toFixed(2));
    },
    selectedItem: (state) => {
      return state.selectedItem;
    },
  },
};
