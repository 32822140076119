import Vue from "vue";

export default {
    namespaced: true,

    state: {
        savedStates: {}
    },

    mutations: {
        saveState(state, data) {
            Vue.set(state.savedStates, data.routeName, data.state);
        },
    },

    actions: {
        saveState({commit}, data) {
            commit("saveState", data);
        }
    },

    getters: {
        getSavedState: (state) => (routeName) => {
            //CHECK IF SAVEDSTATE EXISTS OR NOT
            if(!state.savedStates.hasOwnProperty(routeName))
                return null;

            return state.savedStates[routeName];
        }
    }
};
