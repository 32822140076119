import axios from "axios";
import Vue from "vue";

export default {
    namespaced: true,

    state: {
        mealSizes: [],
    },

    mutations: {
        init(state, data) {
            state.mealSizes = data;
        },
        backgroundSync(state, payload) {
            const transaction = payload.transaction;
            const transactionType = payload.type;

            if (transactionType === "INSERT") {
                //INSERT COURSE
                Vue.set(state.mealSizes, state.mealSizes.length, {
                    id: transaction.id,
                    name: transaction.name,
                    sorting: transaction.sorting,
                });
            }

            if (transactionType === "UPDATE") {
                //FIND MEALSIZE
                let mealsize = state.mealSizes.find((mealsize) => {
                    if (mealsize.id === transaction.id) {
                        return true;
                    }
                });

                //UPDATE MEALSIZE
                if (mealsize) {
                    mealsize.name = transaction.name;
                    mealsize.sorting = transaction.sorting;
                }
            }

            if (transactionType === "DELETE") {
                //FIND MEALSIZE
                let mealsize = state.mealSizes.find((mealsize) => {
                    if (mealsize.id === transaction.id) {
                        return true;
                    }
                });

                if (mealsize) {
                    let mealsizeIndex = state.mealSizes.indexOf(mealsize);
                    //DELETE MEALSIZE
                    Vue.delete(state.mealSizes, mealsizeIndex);
                }
            }
        }
    },

    actions: {
        init({commit}) {
            return axios.post('get/indexeddb/mealSizes/',).then((res) => {
                commit("init", res.data);
            }).catch(() => {

            });
        }
    }
};
