export let deliveryRoutes = {
    meta: {
        siteTitle: ('generic.lang_lieferdienst'),
        menuTitle: ('generic.lang_lieferdienst'),
        layout: 'default',
        icon: 'pe-7s-headphones',
        permission: {
            requiredModule: 13,
            requiredPermission: "",
            hybridDisplay: ""
        }
    },
    childs: [
        {
            path: '/pos/delivery',
            name: 'pos.delivery',
            meta: {
                siteTitle: ('generic.lang_lieferdienst'),
                menuTitle: ('generic.lang_lieferdienst'),
                layout: 'default',
                icon: 'pe-7s-headphones',
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 13,
                    requiredPermission: "delivery_cashier",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/delivery/Delivery'),
        },
        {
            path: '/pos/delivery/settings',
            name: 'pos.delivery.settings',
            meta: {
                siteTitle: ('settings.lang_nav_deliverysettings'),
                menuTitle: ('settings.lang_nav_deliverysettings'),
                layout: 'default',
                icon: 'pe-7s-settings',
                permission: {
                    requiredModule: 13,
                    requiredPermission: "delivery_settings",
                    hybridDisplay: "Server"
                }
            },
            component: () => import('../views/delivery/DeliverySettings'),
        },
        {
            path: '/pos/delivery/settings/deliveryarea/create',
            name: 'pos.delivery.settings.deliveryarea.create',
            meta: {
                siteTitle: 'Create Delivery Area',
                menuTitle: 'Create Delivery Area',
                layout: 'default',
                icon: '',
                hideInNavigation: true,
                permission: {
                    requiredModule: 13,
                    requiredPermission: "",
                    hybridDisplay: "Server"
                }
            },
            component: () => import('../views/delivery/settings/DeliveryAreaCreate'),
        },
        {
            path: '/pos/delivery/settings/deliveryarea/edit/:id',
            name: 'pos.delivery.settings.deliveryarea.edit',
            meta: {
                siteTitle: 'Edit Delivery Area',
                menuTitle: 'Edit Delivery Area',
                layout: 'default',
                icon: '',
                hideInNavigation: true,
                permission: {
                    requiredModule: 13,
                    requiredPermission: "",
                    hybridDisplay: "Server"
                }
            },
            component: () => import('../views/delivery/settings/DeliveryAreaEdit'),
        },
        {
            path: '/pos/delivery/settings/status/create',
            name: 'pos.delivery.settings.orderstatus.create',
            meta: {
                siteTitle: 'Add Order status',
                menuTitle: 'Add Order status',
                layout: 'default',
                icon: '',
                hideInNavigation: true,
                permission: {
                    requiredModule: 13,
                    requiredPermission: "",
                    hybridDisplay: "Server"
                }
            },
            component: () => import('../views/delivery/settings/OrderStatusCreate'),
        },
        {
            path: '/pos/delivery/settings/status/edit/:id',
            name: 'pos.delivery.settings.orderstatus.edit',
            meta: {
                siteTitle: 'Edit Order status',
                menuTitle: 'Edit Order status',
                layout: 'default',
                icon: '',
                hideInNavigation: true,
                permission: {
                    requiredModule: 13,
                    requiredPermission: "",
                    hybridDisplay: "Server"
                }
            },
            component: () => import('../views/delivery/settings/OrderStatusEdit'),
        },
    ],
    groups: [],
};
