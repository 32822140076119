import Vue from 'vue';

// --- SENTRY MONITORING INIT
 import * as Sentry from "@sentry/vue";
import vuetify from './plugins/vuetify';
// --- App ---
import App from './App';
// --- App Layout ---
import Default from './Layout/Wrappers/baseLayout.vue';
import POS from './Layout/Wrappers/posLayout.vue';
import Pages from './Layout/Wrappers/pagesLayout.vue';
import Apps from './Layout/Wrappers/appLayout.vue';
// --- EPSON SDK ---
import VueEpson from '@billsys/vue-epson';
// --- Lodash ---
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
// --- Router ---
import router from './router';
// --- Vuex Store ---
import store from './store';
// --- VueBootstrap v4 ---
import BootstrapVue from 'bootstrap-vue';
// --- Internationalisation ---
import VueI18n from 'vue-i18n';
import messages from './i18n';
// --- VUE MARKDOWN PARSER FOR HELP ---//
//import VueSimpleMarkdown from 'vue-simple-markdown'
// You need a specific loader for CSS files like https://github.com/webpack/css-loader
//import 'vue-simple-markdown/dist/vue-simple-markdown.css'
// vue vue2-datepicker
import 'vue2-datepicker/index.css';
// --- ePosClass ---
import eposClass from './plugins/ePosClass';
// -- export to excel --
import VueExcelXlsx from 'vue-excel-xlsx';
// --- SocketIO ---
import VueSocketIO from 'vue-socket.io';
// --- Axios ---
import axios from 'axios';
import VueAxios from 'vue-axios';
// --- Sweetalert2 ---
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import DatetimePicker from 'vuetify-datetime-picker';
// --- VueTouchKeyboard ---
import VueTouchKeyboard from 'vue-touch-keyboard';
import './assets/touchKeyboard.css';

// --- CurrencyField ---
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib';
// --- vue Moment ---
import Vuemoment from 'vue-moment';
import * as moment from 'moment';
import 'moment/locale/de';

// --- vue touch to handle touch events ---
import VueTouch from 'vue-touch';

// --- vue2leaflet styles ---
import 'leaflet/dist/leaflet.css';

// -- leaflet marker fix --
import L from 'leaflet';

// -- add global mixins --
import functions from '@/mixins/helpers/functions';
import LocaleCurrency from 'locale-currency';
import { Events } from './plugins/events';

Vue.mixin(functions);

// --- PWA ---
//import './registerServiceWorker';

// GET STORE FOR HYBRID CHECK:
window.store = store;
window.eventBus = new Vue();

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

if (process.env.VUE_APP_MODE !== 'testing'){
// && this.$store.getters['permissions/checkModule'](64)) { @@aymane Driff Dont change!!!!

 Sentry.init({
     Vue,
     dsn: "https://25d26614a3264d64af2a45e9b1d2f346@sentry.3pos.de/4",
     release: "3posv3_frontend@"+process.env.VUE_APP_VERSION,
     integrations: [
         new Sentry.BrowserTracing({
             routingInstrumentation: Sentry.vueRouterInstrumentation(router)
         }),
     ],
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
     tracesSampleRate: 0.2,
 });
}

// A custom horizontal swipe event.
VueTouch.registerCustomEvent('left-swipe', {
  type: 'swipe',
  direction: 'right',
  cancelable: false,
});

VueTouch.registerCustomEvent('right-swipe', {
  type: 'swipe',
  direction: 'left',
});

VueTouch.registerCustomEvent('up-swipe', {
  type: 'swipe',
  direction: 'up',
});

VueTouch.registerCustomEvent('down-swipe', {
  type: 'swipe',
  direction: 'down',
});

Vue.use(VueTouch);

Vue.config.productionTip = false;

Vue.component('pos-layout', POS);
Vue.component('default-layout', Default);
Vue.component('userpages-layout', Pages);
Vue.component('apps-layout', Apps);

// --- FONTS --
require('typeface-anton');

Vue.use(VueEpson);

Vue.use(VueLodash, { name: 'custom', lodash: lodash });

Vue.use(BootstrapVue);

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  silentTranslationWarn: true, //DISABLE WARNINGS
  messages,
});
// we're using another variable for translation for printer plugins  geoloc translations
// geol stands for geo language
// so if you want to use use import {geol} from "the mains.js path"
export const geol = new VueI18n({
  locale: 'de',
  fallbackLocale: 'de',
  silentTranslationWarn: true, //DISABLE WARNINGS
  messages,
});

//Vue.use(VueSimpleMarkdown)

Vue.prototype.$eposClass = new eposClass();

Vue.use(VueExcelXlsx);
let apiServerIP = process.env.VUE_APP_API_HOST;
let socketServerIP = process.env.VUE_APP_SOCKET_SERVER_HOST;
if (process.env.VUE_APP_HYBRID == 'true') {
  if (
    window.location.hostname != 'localhost' &&
    window.location.hostname != 'devkasse.3pos.de' &&
    window.location.hostname != 'kasse.3pos.de'
  ) {
    apiServerIP =
      location.protocol + '//' + window.location.hostname + ':8010/pos';
    socketServerIP =
      location.protocol + '//' + window.location.hostname + ':8025';
  }
}

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: socketServerIP,
    options: {
      autoConnect: false,
    },
  })
);

//TSE SOCKET
/*
Vue.prototype.$tseSocket = io('http://localhost:3000', {
    autoConnect: false
});
*/

Vue.use(VueAxios, axios);

axios.defaults.baseURL = apiServerIP;
let hasNetworkError = false;
axios.interceptors.response.use(
  function (response) {
    if(hasNetworkError){
      hasNetworkError = false;
      Events.$emit('api-error', hasNetworkError);
    }
    return response;
  },
  function (error) {

    if (error.message === 'Network Error') {
      hasNetworkError = true;
      Events.$emit('api-error', hasNetworkError);
    }

    if (!error.response) {
      return Promise.reject(error);
    } else if (error.response.status === 419 || error.response.status === 401) {
      store
        .dispatch('user/logout', { vm: new Vue() })
        .then(() => {
          axios.defaults.headers.common['3POSAPITOKEN'] = '';

          router.replace({
            name: 'init',
          });
        })
        .catch((err) => {
          //console.log(err);
        });
      return {data:{ message: '401', success: false, status:false}}
    } else if (error.response.status === 404) {
      return Promise.reject({ message: '404' });
    } else if (error.response.status === 500) {
      return Promise.reject(error);
    }
  }
);

const swalOptions = {
  reverseButtons: true,
  cancelButtonColor: '#d63d3d',
  cancelButtonText: 'Cancel',
  confirmButtonText: 'OK',
};

Vue.use(DatetimePicker);

Vue.use(VueSweetalert2, swalOptions);

Vue.use(VueTouchKeyboard);

Vue.component('v-text-field', VTextField);
Vue.use(VCurrencyField, {
  locale: 'de-DE',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
});

moment.locale('de', {
  week: {
    dow: 1, // "Monday" is the first day of the week
  },
});
Vue.use(Vuemoment, {
  moment,
});

// --- REMOVE BEFORE RELEASE ---
//Vue.config.devtools = true;
//Vue.config.performance = true;

new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount('#app');
