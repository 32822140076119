<template>
  <v-dialog
      v-model="dialog"
      persistent
      width="400"
  >
    <v-card color="#FF6D00" dark>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <p class="text-center white--text" style="margin-bottom: 5px;">{{$t('generic.lang_dataisbeingloaded')}}</p>
              <v-progress-linear
                  class="mb-0"
                  color="white"
                  indeterminate
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "InitData",

  data() {
    return {
      dialog: true
    }
  }
}
</script>