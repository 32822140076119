export let canteenRoutes = {
    meta: {
        menuTitle: ('generic.lang_nav_canteen'),
        icon: 'pe-7s-study',
        permission: {
            requiredModule: 41,
            requiredPermission: "",
            hybridDisplay: ""
        }
    },
    childs: [
        {
            path: '/canteen/orders',
            name: 'canteen.orders',
            meta: {
                siteTitle: ('generic.lang_orders'),
                menuTitle: ('generic.lang_orders'),
                layout: 'default',
                icon: 'pe-7s-shuffle',
                permission: {
                    requiredModule: 41,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/kantinopel/KantinopelOrders')
        },
        {
            path: '/canteen/archive',
            name: 'canteen.archive',
            meta: {
                siteTitle: ('generic.lang_archive'),
                menuTitle: ('generic.lang_archive'),
                layout: 'default',
                icon: 'pe-7s-box1',
                permission: {
                    requiredModule: 41,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/kantinopel/KantineopelArchive')
        },
        {
            header: false,
            path: '/canteen/menu',
            name: 'canteen.menu',
            meta: {
                siteTitle: ('generic.lang_menu'),
                menuTitle: ('generic.lang_menu'),
                layout: 'default',
                icon: 'pe-7s-menu',
                permission: {
                    requiredModule: 41,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/kantinopel/CanteenMenu')
        },
        {
            header: false,
            path: '/canteen/customers',
            name: 'canteen.customers',
            meta: {
                siteTitle: ('customers.lang_customers'),
                menuTitle: ('customers.lang_customers'),
                layout: 'default',
                icon: 'pe-7s-users',
                permission: {
                    requiredModule: 41,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/customers/SearchCustomers')
        },
        {
            header: false,
            path: '/canteen/settings',
            name: 'canteen.settings',
            meta: {
                siteTitle: ('settings.lang_settings'),
                menuTitle: ('settings.lang_settings'),
                layout: 'default',
                icon: 'pe-7s-settings',
                permission: {
                    requiredModule: 41,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/kantinopel/KantinopelSettings')
        },
        {
            header: false,
            path: '/canteen/notifications',
            name: 'canteen.notifications',
            meta: {
                siteTitle: ('generic.lang_gui_messages'),
                menuTitle: ('generic.lang_gui_messages'),
                layout: 'default',
                icon: 'pe-7s-settings',
                permission: {
                    requiredModule: 41,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/kantinopel/CanteenNotification')
        },
        {
            header: false,
            path: '/canteen/MealCheckout',
            name: 'canteen.MealCheckout',
            meta: {
                siteTitle: ('generic.lang_foodDelivery'),
                menuTitle: ('generic.lang_foodDelivery'),
                layout: 'default',
                icon: 'pe-7s-settings',
                permission: {
                    requiredModule: 41,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/kantinopel/MealCheckout')
        }
    ],
    groups: []
};
