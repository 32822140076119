<template>
    <v-btn color="info" @click="callHelpAgent" elevation="0">
        <v-icon>
            mdi-help-circle
        </v-icon>
        {{ $t("generic.lang_nav_help") }}
    </v-btn>
</template>

<script>

export default {
    name: "RetailSelfCheckoutHelpBtn",
    methods: {
        callHelpAgent(){
            this.$swal({
                title: this.$t('generic.lang_warning'),
                text: this.$t('generic.lang_areYouSureToCallHelpAgent'),
                icon: "warning",
                confirmButtonText: this.$t('generic.lang_yes'),
                cancelButtonText: this.$t('generic.lang_cancel'),
                showCancelButton: true,
                showLoaderOnConfirm: true,
                preConfirm: () => {
                    // call the help agent 
                    this.$socket.emit("pos.selfcheckout.help", { cashierID: this.$store.state.api.auth.cashierID })
                },
                allowOutsideClick: () => false,
            });
        }
    },
}
</script>