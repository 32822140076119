import axios from "axios";
import Vue from "vue";

export default {
    namespaced: true,

    state: {
        freeTextTags: [],
    },

    mutations: {
        init(state, data) {
            state.freeTextTags = data;
        },
        backgroundSync(state, payload) {
            const transaction = payload.transaction;
            const transactionType = payload.type;

            if(transactionType === "INSERT") {
                Vue.set(state.freeTextTags, state.freeTextTags.length, {
                    name: transaction.name,
                    itemgroups: transaction.itemgroups,
                    position: transaction.position,
                });
            }

            if(transactionType === "UPDATE") {
                let freeTextTag = state.freeTextTags.find((freeTextTag) => {
                    if(freeTextTag.name === transaction.oldname) {
                        return true;
                    }
                });

                if(freeTextTag) {
                    freeTextTag.name = transaction.name;
                    freeTextTag.itemgroups = transaction.itemgroups;
                    freeTextTag.position = transaction.position;
                }
            }

            if(transactionType === "DELETE") {
                let freeTextTag = state.freeTextTags.find((freeTextTag) => {
                    if(freeTextTag.name === transaction.name) {
                        return true;
                    }
                });

                if(freeTextTag) {
                    let freeTextTagIndex = state.freeTextTags.indexOf(freeTextTag);

                    Vue.delete(state.freeTextTags, freeTextTagIndex);
                }
            }
        }
    },

    actions: {
        init({commit}) {
            return axios.post('get/indexeddb/freeTextTags/').then((res) => {
                commit("init", res.data.tags);
            });
        }
    }
};
