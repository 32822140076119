import ePosTSEPrinterClass from "./ePosTSEPrinterClass";
import * as xmljs from 'xml-js';
import Vue from "vue";

export default class eposClass {
    constructor() {
        this.tsePrinters = {};
    }

    // --- TSE PRINTERS ---
    addTSEPrinter(eposDevice, printer, shouldSetup = false, loginAsAdmin = false, useProxy = false) {
        this.tsePrinters[printer.id] = new ePosTSEPrinterClass(eposDevice, printer, shouldSetup, loginAsAdmin, useProxy);

        return this.tsePrinters[printer.id];
    }

    removeTSEPrinter(printerID) {
        Vue.delete(this.tsePrinters, printerID);
    }

    getTSEPrinters() {
        return this.tsePrinters;
    }

    getTSEPrinter(id) {
        if (!this.tsePrinters.hasOwnProperty(id))
            return null;

        return this.tsePrinters[id];
    }

    printXML(printObj, printerIPAddress, printerDev, sslEnabled = false) {
        //TRANSPORT METHOD
        let transportMethod = 'http';

        if (sslEnabled) {
            transportMethod = 'https';
        }

        let url = transportMethod + '://' + printerIPAddress + '/cgi-bin/epos/service.cgi?devid=' + printerDev + '&timeout=30000';

        let xmlPrintStr = document.implementation.createDocument("", "", null);
        let headerEl = xmlPrintStr.createElement("s:Envelope");
        headerEl.setAttribute("xmlns:s", "http://schemas.xmlsoap.org/soap/envelope/");

        let bodyEl = xmlPrintStr.createElement("s:Body");
        let eposPrintEl = xmlPrintStr.createElementNS("http://www.epson-pos.com/schemas/2011/03/epos-print", "epos-print");
        let qrCodeSize = 0;
        // eposPrintEl.setAttribute("xmlns", "http://www.epson-pos.com/schemas/2011/03/epos-print");

        bodyEl.appendChild(eposPrintEl);
        headerEl.appendChild(bodyEl);
        xmlPrintStr.appendChild(headerEl);

        printObj.forEach(function (element) {
            if (element["font"]) {
                let fontEl = xmlPrintStr.createElementNS("http://www.epson-pos.com/schemas/2011/03/epos-print", "text");

                if (element["font"] === "A") {
                    fontEl.setAttribute("font", "font_a");
                } else if (element["font"] === "B") {
                    fontEl.setAttribute("font", "font_b");
                } else {
                    fontEl.setAttribute("font", "font_c");
                }

                eposPrintEl.appendChild(fontEl);
            } else if (element["align"]) {
                let alignEl = xmlPrintStr.createElementNS("http://www.epson-pos.com/schemas/2011/03/epos-print", "text");

                if (element["align"] === "left") {
                    alignEl.setAttribute("align", "left");
                }
                if (element["align"] === "center") {
                    alignEl.setAttribute("align", "center");
                }
                if (element["align"] === "right") {
                    alignEl.setAttribute("align", "right");
                }

                eposPrintEl.appendChild(alignEl);
            } else if (element["reverse"]) {
                let reverseEl = xmlPrintStr.createElementNS("http://www.epson-pos.com/schemas/2011/03/epos-print", "text");

                reverseEl.setAttribute("ul", "false");
                reverseEl.setAttribute("em", "false");
                reverseEl.setAttribute("color", "color_1");

                if (element["reverse"] === "on") {
                    reverseEl.setAttribute("reverse", "true");
                } else {
                    reverseEl.setAttribute("reverse", "false");
                }

                eposPrintEl.appendChild(reverseEl);
            }
            else if (element["bold"]) {
                let boldEL = xmlPrintStr.createElementNS("http://www.epson-pos.com/schemas/2011/03/epos-print", "text");

                boldEL.setAttribute("ul", "false");
                boldEL.setAttribute("em", "false");
                boldEL.setAttribute("color", "color_1");

                if (element["bold"] === "on") {
                    boldEL.setAttribute("dw", "true");
                    boldEL.setAttribute("dh", "false");
                } else {
                    boldEL.setAttribute("dw", "false");
                    boldEL.setAttribute("dh", "false");
                }

                eposPrintEl.appendChild(boldEL);
            }
            else if (element["underlined"]) {
                let underlineEl = xmlPrintStr.createElementNS("http://www.epson-pos.com/schemas/2011/03/epos-print", "text");

                underlineEl.setAttribute("reverse", "false");
                underlineEl.setAttribute("em", "false");
                underlineEl.setAttribute("color", "color_1");

                if (element["underlined"] === "on") {
                    underlineEl.setAttribute("ul", "true");
                } else {
                    underlineEl.setAttribute("ul", "false");
                }

                eposPrintEl.appendChild(underlineEl);
            } else if (element["text"]) {
                let textEl = xmlPrintStr.createElementNS("http://www.epson-pos.com/schemas/2011/03/epos-print", "text");
                textEl.innerHTML = element["text"].toString().replace(/&/g, '&amp;') //<= start with
                    .replace(/</g, '&lt;')
                    .replace(/>/g, '&gt;')
                    .replace(/"/g, '&quot;')
                    .replace(/'/g, '&apos;') + '\n';
                eposPrintEl.appendChild(textEl);
            } else if (element["image"]) {
                console.log("image");
                let imageEl = xmlPrintStr.createElementNS("http://www.epson-pos.com/schemas/2011/03/epos-print","image");

                imageEl.setAttribute("width", "400");
                imageEl.setAttribute("height", "32");
                imageEl.setAttribute("color", "color_1");
                imageEl.setAttribute("mode", "mono");

                imageEl.innerHTML = element["image"].replace(/&/g, '&amp;') //<= start with
                    .replace(/</g, '&lt;')
                    .replace(/>/g, '&gt;')
                    .replace(/"/g, '&quot;')
                    .replace(/'/g, '&apos');
                eposPrintEl.appendChild(imageEl);
            } else if (element["qrCodeSize"]) {

                qrCodeSize = parseInt(element["qrCodeSize"])?parseInt(element["qrCodeSize"]) : 0;

            } else if (element["qrCode"]) {
                let qrEl = xmlPrintStr.createElementNS("http://www.epson-pos.com/schemas/2011/03/epos-print", "symbol");
                qrEl.setAttribute("type", "qrcode_model_2");
                qrEl.setAttribute("level", "default");

                qrEl.setAttribute("width", 3 + qrCodeSize);
                qrEl.setAttribute("height", 3 + qrCodeSize);
                qrEl.setAttribute("size", 0);
                qrEl.innerHTML = element["qrCode"];

                eposPrintEl.appendChild(qrEl);
            } else if (element["printerLogo"]) {

                var logoEl = xmlPrintStr.createElementNS("http://www.epson-pos.com/schemas/2011/03/epos-print", "logo");
                logoEl.setAttribute("key1", "48");
                logoEl.setAttribute("key2", "48");


                eposPrintEl.appendChild(logoEl);
            } else if (element["barcode128"]) {
                let barcodeEl = xmlPrintStr.createElementNS("http://www.epson-pos.com/schemas/2011/03/epos-print", "barcode");

                barcodeEl.setAttribute("type", "code39");
                barcodeEl.setAttribute("hri", "below");
                barcodeEl.setAttribute("font", "font_a");
                barcodeEl.setAttribute("height", 32);
                barcodeEl.setAttribute("width", 2);
                barcodeEl.innerHTML = element["barcode128"];

                eposPrintEl.appendChild(barcodeEl);
            } else if (element["feed"]) {
                let feedEl = xmlPrintStr.createElementNS("http://www.epson-pos.com/schemas/2011/03/epos-print", "feed");

                feedEl.setAttribute("line", parseInt(element["feed"]));

                eposPrintEl.appendChild(feedEl);
            } else if (element["fontSize"]) {
                let fontSizeEl = xmlPrintStr.createElementNS("http://www.epson-pos.com/schemas/2011/03/epos-print", "text");

                fontSizeEl.setAttribute("width", parseInt(element["fontSize"]));
                fontSizeEl.setAttribute("height", parseInt(element["fontSize"]));

                eposPrintEl.appendChild(fontSizeEl);
            } else if (element["cut"]) {
                let cutEl = xmlPrintStr.createElementNS("http://www.epson-pos.com/schemas/2011/03/epos-print", "cut");

                cutEl.setAttribute("type", "feed");

                eposPrintEl.appendChild(cutEl);
            } else if (element["cashDrawer"]) {
                let drawerEl = xmlPrintStr.createElementNS("http://www.epson-pos.com/schemas/2011/03/epos-print", "pulse");

                drawerEl.setAttribute("drawer", "drawer_1");
                drawerEl.setAttribute("time", "pulse_400");

                eposPrintEl.appendChild(drawerEl);
            }
        });

        // Send print document
        return new Promise(function (resolve, reject) {
            let xhr = new XMLHttpRequest();
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
            xhr.setRequestHeader('If-Modified-Since', 'Thu, 01 Jan 1970 00:00:00 GMT');

            xhr.onload = function () {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        const result = JSON.parse(xmljs.xml2json(xhr.response));

                        if (result.elements[0].elements[0].elements[0].attributes.success === "true") {
                            resolve();
                        } else {
                            if (!result.elements[0].elements[0].elements[0].attributes.hasOwnProperty("code"))
                                reject("Unknown error");

                            // PARSE STATUS CODE
                            /*
                            EPTR_AUTOMATICAL        An automatically recoverable error occurred
                            EPTR_BATTERY_LOW        No remaining battery
                            EPTR_COVER_OPEN         A cover open error occurred
                            EPTR_CUTTER             An autocutter error occurred
                            EPTR_MECHANICAL         A mechanical error occurred
                            EPTR_REC_EMPTY          No paper in roll paper end sensor
                            EPTR_UNRECOVERABLE      An unrecoverable error occurred
                            SchemaError             The request document contains a syntax error
                            DeviceNotFound          The printer with the specified device ID does not exist
                            PrintSystemError        An error occurred on the printing system
                            EX_BADPORT              An error was detected on the communication port
                            EX_TIMEOUT              A print timeout occurred
                            */
                            const code = result.elements[0].elements[0].elements[0].attributes.code;

                            if (code === "EPTR_REC_EMPTY") reject("Es befindet sich kein Papier mehr im Drucker");
                            else if (code === "EPTR_CUTTER") reject("Auto cutter error generated");
                            else if (code === "EPTR_COVER_OPEN") reject("Cover open. Bitte überprüfen.")
                            else reject("Unhandled Error code: " + status + " (Check eposXML error codes");
                        }
                    }
                }

                reject("Unknown error")
            };

            xhr.onerror = (err) => {
                reject("Unknown error");
            };

            xhr.send(xmlPrintStr);
        });
    }
}
