import axios from "axios";
import Vue from "vue";

export default {
    namespaced: true,

    state: {
        deposits: [],
    },

    mutations: {
        init(state, data) {
            state.deposits = data;
        },
        backgroundSync(state, payload) {
            const transaction = payload.transaction;
            const transactionType = payload.type;

            if (transactionType === "INSERT") {
                //INSERT DEPOSIT
                Vue.set(state.deposits, state.deposits.length, {
                    id: transaction.id,
                    name: transaction.name,
                    priceTotal: transaction.priceTotal,
                });
            }

            if (transactionType === "UPDATE") {
                //FIND DEPOSIT
                let deposit = state.deposits.find((deposit) => {
                    if (deposit.id === transaction.id) {
                        return true;
                    }
                });

                //UPDATE DEPOSIT
                if (deposit) {
                    deposit.name = transaction.name;
                    deposit.priceTotal = transaction.priceTotal;
                }
            }

            if (transactionType === "DELETE") {
                //FIND DEPOSIT
                let deposit = state.deposits.find((deposit) => {
                    if (deposit.id === transaction.id) {
                        return true;
                    }
                });

                if (deposit) {
                    let depositIndex = state.deposits.indexOf(deposit);
                    //DELETE DEPOSIT
                    Vue.delete(state.deposits, depositIndex);
                }
            }
        }
    },

    actions: {
        init({commit}) {
            return axios.post('get/indexeddb/deposit/').then((res) => {
                commit("init", res.data);
            });
        }
    },

    getters: {
        depositgroups: (state) => {
            return state.deposits;
        }
    }
};
