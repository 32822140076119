export const ENDPOINTS = {
  POS: {
    GASTRO: {
      TABLES: {
        GET: "get/gastro/tables/",
        INFO: "get/gastro/table_details/",
        WAITERS: {
          GET: "get/cashier/availableClerks/",
          UPDATE: "update/cashier/changeTableOwnership/",
        },
        GETALL: "get/TableObj/",
        GETROOMBYTABLE: "get/tablebee/roomName/",
      },
      ORDER: {
        GETBYTABLENAME: "get/gastro/orderByTableName/",
        CREATE: "create/gastro/order/",
        CREATEZERO: "create/gastro/zeroOrder/",
        CREATEFROMPREORDER: "create/gastro/orderFromPreOrder/",
      },
      ORDERS: {
        GET: "get/gastro/orders/",
      },
      PREORDER: {
        GETBYTABLENAME: "get/gastro/preOrderByTableName/",
        GETALL: "get/gastro/preOrders/",
        DELETE: "delete/cashier/preOrders/"
      },
      MERGETABLE: "create/gastro/mergeTable/",
      MERGETABLEWOPARTY: "create/gastro/mergeTableWOParty/",
      SPLITTABLE: "create/gastro/splitTable/",
      CHANGETABLE: "create/gastro/changeTable/",
      CHANGERFIDTABLE: "create/gastro/changeRFIDTable/",
      MERGETABLEPARTIES: "create/gastro/mergeTableParties/",
      MERGESELECTEDPARTIES: "create/gastro/mergeTblParties/",
      UNIQUE: "get/settings/duplicateTable/",
      PICKUPNAMES: "get/cashier/nextPickUpNumber/",
      ITEMGARNISH: "get/cashier/getItemExtras/",
      CALLRATIOTECPAGER: "get/cashier/callRatiotecPager/",
    },
    SALE: {
      CREATE: "create/sale/",
      TIPP: "create/sale/tipp/",
      UPDATETSEPAYLOAD: "update/sale/tseInvoiceData/",
      PRECHECKPAYWITHCUSTOMERCARD: "create/sale/precheckPayWithCustomerCard/",
    },
    ESCPRINT: {
      CREATE: "get/escPrint/",
    },
    RETURNSALE: {
      CREATE: "create/returnSale/",
    },
    ACTIVESHIFT: {
      GET: "get/cashier/activeShift/",
      CHECK: "get/cashier/activeShift/",
      CREATE: "create/cashier/activeShift/",
      CLOSE: "create/cashier/closeActiveShift/",
      CLOSE_ACTIVE_SHIFTS: "create/cashier/closeActiveShifts/",
    },
    RETAIL: {
      ORDER: {
        CREATE: "create/retail/order/",
        GET: "get/retail/orders/",
      },
      ORDERS: {
        GET: "get/retail/orders/",
        DELETE: "delete/retail/orders/",
      },
      SCAN: "get/retail/scan/",
    },
    EVENTBEE: {
      ORDER: {
        CREATE: "create/eventBee/event2OrderPos/",
      },
      EVENTS: {
        GET: "get/eventBee/eventDetailsPOS/",
        GET_ALL: "get/eventBee/eventListPOS/",
      },
      RESERVED_SEATS: "get/eventBee/reservedSeatsByDateEventplan/"
    },
    CASHBOOKVALUE: "get/cashier/cashbookValue/",
    ACTIVEECTERMINAL: "get/cashier/activeECTerminal/",
    USERFULLNAME: "get/cashier/userFullName/",
    CHECKSTOCKCOUNTSALE: "get/cashier/checkStockCountSale/",
    CUSTOMERGROUPITEMPRICE: "get/cashier/customerGroupItemPrice/",
    HAPPYHOURRULES: "get/cashier/happyHour/",
    CUSTOMERBYACCOUNTCARD: "get/cashier/customerByAccountCard/",
  },
  DATATABLES: {
    ERP: {
      ITEMS: "datatables/erp/searchItems/",
      ITEMSTORAGEINFO: "datatables/erp/getItemStorageInformations/",
      ITEMBARCODES: "datatables/erp/additionalBarcodes/",
      MHDLIST: "datatables/erp/mhdItems/",
      ALLERGENE: "datatables/erp/allergens/",
      ADDITIVE: "datatables/erp/additives/",
      DEPOSIT: "datatables/erp/deposit/",
      EXTRATAB: "datatables/erp/garnishTabs/",
      FREETEXTTAG: "datatables/erp/freeTextTags/",
      SIZEVARIATION: "datatables/erp/mealSize/",
      TAXSETUP: "datatables/erp/tax/",
      VOUCHER: "datatables/erp/voucher/",
      VOUCHEROLD: "datatables/erp/oldVoucher/",
      VOUCHERACTIVITY: "datatables/erp/searchInvoiceByVoucher/",
      GIFTCARD: "datatables/erp/giftCard/",
      GIFTCARDOLD: "datatables/erp/oldGiftCards/",
      ITEMGROUPS: "datatables/erp/itemgroups/",
      SERIALNUMBERS: "datatables/erp/serialNumbers/",
      INVENTORYITEMS: "datatables/erp/searchInventoryItems/",
      GARNISH: "datatables/erp/searchGarnish/",
      GARNISHGROUP: "datatables/erp/garnish/groups/",
      PRINTSHELFLABEL: "datatables/erp/shelfLabelItems/",
      PRINTWARELABEL: "datatables/erp/searchItems4LabelPrinting/",
      DELEGATEGARNISH: "datatables/erp/delegateGarnishItem/",
      DELEGATEGARNISHITEMGROUP: "datatables/erp/delegateGarnishWaregroup/",
      VOIDREASONS: "datatables/erp/voidReasons/",
      COURSESETTINGS: "datatables/erp/courses/",
      PRODUCERS: "datatables/erp/producer/",
      SUPPLIERS: "datatables/erp/supplier/",
      STORAGEDETAILS: "datatables/storage/displayStorageDetails/",
      STORAGEOVERVIEW: "datatables/storage/storageOverview/",
      STOCKTAKING: {
        OPEN: "datatables/erp/openStockTakings/",
        CLOSE: "datatables/erp/closedStockTakings/",
        DETAILS: "datatables/erp/stockTakingOverview/",
      },
      PRICETEMPLATES: {
        ALL: "datatables/erp/priceTMPL/",
        ITEMS: "datatables/erp/priceTMPLData/",
      },
      GOODSRECEIPT: "datatables/storage/goodsReceipt/",
      GOODSISSUE: "datatables/storage/goodsIssue/",
      WAREVARIATION: "datatables/erp/itemVariations/",
      INGRREDIENT: "datatables/erp/searchIngredients/",
      INGRREDIENTGROUP: "datatables/erp/ingredient/groups/",
      PACKAGES: "datatables/erp/packages/",
      WAREORDERS: "datatables/erp/wareorder/",
      MEALTYPE: "datatables/erp/mealType/",
      HAPPYHOUR: "datatables/erp/happyHour/",
      DAILYGOODS: "datatables/erp/dailyGoods/",
      SPECIALOFFERS: "datatables/erp/SpecialOffer/",
    },
    ACCOUNTING: {
      RETOUROVERVIEW: "datatables/accounting/retourOverview/",
      SEARCHINVOICES: "datatables/accounting/searchInvoice/",
      UNPAIDINVOICES: "datatables/accounting/unpaidInvoices/",
      POSTINGRECORDS: "datatables/accounting/accountingPostingsCashBook/",
      DAILYREPORT: "datatables/accounting/dailyReportArchive/",
      MONTHLYREPORT: {
        ARCHIVE: "datatables/accounting/monthlyReportArchive/",
      },
      WAITERSALES: "datatables/cashier/operatorSales/",
      WAITERTIPS: "datatables/cashier/operatorTips/",
      DEBITINVOICE: {
        PAID: "datatables/accounting/searchDebitInvoice/",
        UNPAID: "datatables/accounting/searchUnpaidDebitInvoice/",
      },
    },
    ANALYTICS: {
      SALESPERDATE: "datatables/cashier/salesPerDay/",
      SALESPERHOUR: "datatables/cashier/salesPerHour/",
      UMSATZANALYSEN: {
        SALESPERTIME: "get/accounting/salesPerTime/",
        SALESPERINHOUSETAKEAWAY: "get/accounting/salesPerInhouseTakeAway/",
        SALESPERDAY: "get/accounting/salesPerDay/",
        SALESPERMONTH: "get/accounting/salesPerMonth/",
        SALESPERYEAR: "get/accounting/salesPerYear/",
        SALESPERPAYEMENT: "get/accounting/salesPerPayment/",
      },
    },
    CUSTOMERS: {
      CUSSALUTATION: "datatables/customer/customerSalutation/",
      CUSTDEPARTMENTS: "datatables/customer/customerDepartment/",
      SEARCH: "datatables/customer/searchCustomer/",
      SEARCHINVOICES: "datatables/customer/searchInvoice/",
      ACCOUNT: "datatables/customer/creditEntries/",
      CUSTOMERGROUPS: {
        ALL: "datatables/customer/customerGroups/",
        GROUPSWIREGROUPS: "datatables/customer/customerGroupsWaregroups/",
        GROUPITEMS: "datatables/customer/customerGroupsItems/",
      },
      CUSTOMERPROJECTS: "datatables/customer/customerProjects/",
      CUSTOMERCONTACTS: "datatables/customer/searchCustomerContact/",
    },
    SETTINGS: {
      PICKUPNAME: "datatables/settings/pickUpNumberNames/",
      ALIAS: "datatables/settings/cashiers/",
      USER: "datatables/settings/displayUsers/",
      EMPLOYEES: "datatables/settings/displayEmployees/",
      KITCHENMONITORS: "datatables/settings/displayKitchenmonitors/",
      ORDERBON_ARCHIVE: "datatables/settings/orderbonArchive/",
      FISCAL: {
        FISCALDEVICES: "datatables/settings/fiscalDevices/",
        FISCALCLIENTS: "datatables/settings/fiscalClients/",
        FISCALDEVICESAT: "datatables/settings/fiscalDevices_at/",
        FISCALCLIENTSAT: "datatables/settings/fiscalClients_at/",
      },
      USERGROUP: "datatables/settings/permissions/",
      ROLES: "datatables/settings/userRoles/",
      CASHIERMODULES: {
        TEMPLATES: "datatables/settings/cashiermodules/templates/",
      },
      SYSTEMSETTINGS: {
        INVOICESETTINGS: {
          TEXTTEMPLATES: "datatables/settings/invoiceTextTemplate/",
          DELIVERYTERMSTEMPLATES: "datatables/settings/deliveryTermsTemplate/",
        },
      },
      ADDITIONALSTATISTICS: "datatables/cashier/additionalStatistics/",
      ADDITIONALPAYMENTS: "datatables/settings/additionalPayments/",
    },
    PRINTSETTINGS: {
      PRINTER: "datatables/printSettings/printer/",
      TEMPLATES: "datatables/printSettings/receiptTmpl/",
    },
    DELIVERY: {
      PRODUCTGROUPS: "datatables/localbee/itemgroups/",
      PRODUCTS: "datatables/localbee/items/",
      DELIVERYAREA: "datatables/delivery/area/",
      DELIVERYSTATUSES: "datatables/delivery/statuses/",
      INVOICES: "datatables/delivery/deliveryInvoices/",
    },
    TABLEBEE: {
      PRODUCTSGROUP: "datatables/tablebee/itemgroups/",
      PRODUCTS: "datatables/tablebee/items/",
      TEMPORDERS: "datatables/tablebee/pendingOrders/",
      STATISTICS: "datatables/tablebee/invoiceTablebee/",
    },
    KIOSK: {
      PRODUCTSGROUP: "datatables/kiosk/itemgroups/",
      PRODUCTS: "datatables/kiosk/items/",
      STATISTICS: "datatables/kiosk/invoice/",
      SEARCHITEMSMENU: "datatables/kiosk/searchItemsMenu/",
      SEARCHMENUS: "datatables/kiosk/searchMenus/",
      TERMINALS: "datatables/kiosk/terminals/",
    },
    POS_REPLICATION: {
      REPLICATION_TEMPLATE: "datatables/replication/replicationTMPL/",
      EDIT_TEMPLATE_DATATABLE: "datatables/replication/replicationTMPLData/",
    },
    PMS: {
      SETTINGS: {
        DEPARTMENTS: "datatables/pms/departments/",
      },
      BREAKFASTLIST: "datatables/pms/reservations/",
    },
    EVENTBEE: {
      EVENTS: "datatables/eventBee/event/",
      PRICESEGMENTS: "datatables/eventBee/priceSegment/",
      ATTRIBUTEGROUPS: "datatables/eventBee/attributeGroups/",
      EVENTGROUPS: "datatables/eventBee/group/",
      REFUNDPOLICIES: "datatables/eventBee/refundPolicy/",
      REFUNDSANDCANCELLATIONS: "datatables/eventBee/retourOverview/",
      POOLS: "datatables/eventBee/pools/",
      TICKET_GENERATOR: "datatables/eventBee/ticketGenerator/",
      SEATING_MAPS: "datatables/eventBee/seatingMap/",
      SEATCATEGORIES: "datatables/eventBee/seatCategories/"
    },
    APIKEYS: "datatables/settings/apiKeys/",
    KANTINE: {
      SHORTNAMES: "datatables/canteen/menuShortName/",
      CLASS: "datatables/canteen/classes/",
      GROUP: "datatables/canteen/groups/",
    },
    BILLING: {
      INVOICES: "datatables/accounting/searchInvoicesFacture/",
      DRAFTS: "datatables/facture/searchInvoiceDraft/",
      QUOTATIONS: "datatables/facture/searchQuotations/",
      DELIVERYNOTES: "datatables/facture/searchDeliveryNotes/",
      PROFORMATS: "datatables/facture/searchProForma/",
      COLLECTIVEBILLSDAYCUSTOMER:
        "datatables/facture/searchDeliveryNotesByDayAndCustomerId/",
    },
    MODULESETTINGS: {
      CASHRECYCLERS: "datatables/settings/cashRecyclers/",
      SCALE: "datatables/settings/scales/",
      EXTERNALAPPS: "datatables/settings/externalApps/",
      WEBHOOKS: "datatables/settings/webhooks/",
    },
    ONLINESHOP: {
      ORDERS: "datatables/accounting/searchInvoiceWebshop/",
      CUSTOMERS: "datatables/customer/searchCustomerOnlineshop/",
    },
  },
  GENERIC: {
    CASHIERIDS: {
      GET: "get/cashierIDs/",
    },
    HELP: {
      GET: "get/help/",
    },
  },
  RESERVATION: {
    RESERVATIONS: {
      CREATE: "create/reservation/reservations/",
      GET: "get/reservation/reservations/",
      UPDATE: "update/reservation/reservations/",
      UPDATE_PREPARATION: "update/reservation/reservations/preparation/",
      UPDATE_TABLE: "update/reservation/reservationTables/",
      DELETE: "delete/reservation/reservations/",
      STATISTICS: "get/reservation/statistics/",
      TABLES: "get/reservation/tables/",
      RESERVATIONBYROOM: "get/reservation/reservationByRoom/",
    },
    ARCHIVE: {
      GET: "get/reservation/archive/",
    },
    SETTINGS: {
      TYPES: {
        UPDATE: "update/reservation/settings/types/",
        GET: "get/reservation/settings/types/",
        DELETE: "delete/reservation/reservationType/",
        DATATABLE: "/datatables/reservations/reservationType/",
        ITEMS: {
          GET: "get/reservation/settings/types/items/",
        },
      },
      CONFIG: {
        UPDATE: "update/reservation/settings/config/",
        GET: "get/reservation/settings/config/",
        EMAILCONFIG: {
          UPDATE: "update/reservation/email/",
          GET: "get/reservation/email/",
        },
      },
      EMAILTEMPLATES: {
        GET: "get/reservation/mailTmpl/",
        GETALL: "get/reservation/mailTmpls/",
        UPDATE: "update/reservation/mailTmpl/",
        CREATE: "create/reservation/mailTmpl/",
        DELETE: "delete/reservation/mailTmpl/",
      },
      OPENHOURS: {
        UPDATE: "update/reservation/settings/openinghours/",
        GET: "get/reservation/settings/openinghours/",
      },
      SERVICE: {
        UPDATE: "update/reservation/serviceType/",
        GET: "get/reservation/serviceTypes/",
        DELETE: "delete/reservation/serviceType/",
        CREATE: "create/reservation/serviceType/",
      },
      MENU: {
        UPDATE: "update/reservation/menuType/",
        DATATABLE: "datatables/reservations/menuType/",
        DELETE: "delete/reservation/menuType/",
        CREATE: "create/reservation/menuType/",
        GET: "get/reservation/menuTypes/",
      },
      WEBINTERFACE: {
        WELCOMECONFIG: {
          GET: "get/reservation/settings/welcomeMessages/",
          UPDATE: "update/reservation/settings/welcomeMessages/",
        },
        TERMSANDCONDITIONS: {
          GET: "get/reservation/settings/termsConditions/",
          UPDATE: "update/reservation/settings/termsConditions/",
        },
        OFFLINEMESSAGE: {
          GET: "/get/reservation/settings/offlineMessages/",
          UPDATE: "/update/reservation/settings/offlineMessages/",
        },
      },
    },
  },
  TABLEBEE: {
    TEMPORDERS: {
      DELETE: "delete/tablebee/pendingOrders/",
      APPROVE: "update/tablebee/approvePendingOrders/",
    },
    OVERVIEW: {
      STATISTICS: "get/dashboard/tablebee/tableeSalesOverview/",
    },
    SETTINGS: {
      EXTRA_CSS: {
        PRODUCTCARD: {
          UPDATE: "update/tablebee/productCardCss/",
          GET: "get/tablebee/productCardCss/",
        },
      },
      SERVICETIMES: {
        GET: "get/tablebee/openingHours/",
        UPDATE: "update/tablebee/openingHours/",
      },
      STATSUS: {
        UPDATE: "update/tablebee/serviceStatus/",
        GET: "get/tablebee/serviceStatus/",
      },
      PAYMENTS: {
        GET: "get/tablebee/paymentOptions/",
        BULKACTIVE: "update/tablebee/activePaymentOptions/",
        PAYMENT_OPTIONS_TRANSLATIONS:
          "update/tablebee/paymentOptionTranslations/",
      },
      OTHERS: {
        UPDATE: "update/tablebee/settings/",
        GET: "get/tablebee/settings/",
      },
      AUTOPRINTSETTINGS: {
        GET: "get/tablebee/settings/autoPrinting/",
        UPDATE: "update/tablebee/settings/autoPrinting/",
      },
    },
    PRODUCTS: {
      UPDATE: "update/tablebee/item/",
    },
    PRODUCTSGROUP: {
      UPDATE: "update/tablebee/itemgroup/",
      BULKDISCOUNT: "update/tablebee/groupDiscount/",
      AUTOMATION: {
        GET: "get/tablebee/itemgroupAutomation/",
        UPDATE: "update/tablebee/itemgroup/automation/",
      }
    },
    TABLES: {
      UPDATE_STATUS: "update/tablebee/table/",
      BULK_UPDATE_STATUS: "update/tablebee/table/",
      UPDATE_STATUS_MESSAGE: "update/tablebee/inactiveTable/",
      GET_STATUS_MESSAGE: "get/tablebee/inactiveTable/",
      RENEW_UUID: "update/tablebee/renewTable/",
      UPDATE_UUID: "update/tablebee/changeTableUuid/",
    },
    NOTIFICATIONS: {
      GET: "get/tablebee/notifications/",
      OPEN: "update/tablebee/openNotification/",
    },
  },
  KIOSK: {
    OVERVIEW: {
      STATISTICS: "get/dashboard/kiosk/salesOverview/",
    },
    SETTINGS: {
      EXTRA_CSS: {
        PRODUCTCARD: {
          UPDATE: "update/kiosk/productCardCss/",
          GET: "get/kiosk/productCardCss/",
        },
      },
      SERVICETIMES: {
        GET: "get/kiosk/openingHours/",
        UPDATE: "update/kiosk/openingHours/",
      },
      STATSUS: {
        UPDATE: "update/kiosk/serviceStatus/",
        GET: "get/kiosk/serviceStatus/",
      },
      PAYMENTS: {
        GET: "get/kiosk/paymentOptions/",
        BULKACTIVE: "update/kiosk/activePaymentOptions/",
        PAYMENT_OPTIONS_TRANSLATIONS: "update/kiosk/paymentOptionTranslations/",
      },
      OTHERS: {
        UPDATE: "update/kiosk/settings/",
        GET: "get/kiosk/settings/",
      },
      AUTOPRINTSETTINGS: {
        GET: "get/kiosk/settings/autoPrinting/",
        UPDATE: "update/kiosk/settings/autoPrinting/",
      },
      DESIGN: {
        COLOR: {
          GET: "get/kiosk/color/",
          UPDATE: "update/kiosk/color/",
        },
        TEXT: {
          GET: "get/kiosk/texts/",
          UPDATE: "update/kiosk/texts/",
        },
        FRONTPAGE: {
          GET: "get/kiosk/frontPage/",
          UPDATE: "update/kiosk/frontPage/",
          TEMPLATE:{
            GET:"get/kiosk/kioskFrontpageTemplate/",
            GETALL:"get/kiosk/kioskFrontpageTemplates/",
            UPDATE:"update/kiosk/kioskFrontendTemplate/",
            CREATE:"create/kiosk/kioskFrontpageTemplate/",
            DELETE:"delete/kiosk/kioskFrontpageTemplate/",
          }
        },
        LAYOUT: {
          GET: "get/kiosk/layoutSettings/",
          UPDATE: "update/kiosk/layoutSettings/"
        }
      },
    },
    PRODUCTS: {
      UPDATE: "update/kiosk/item/",

    },
    PRODUCTSGROUP: {
      UPDATE: "update/kiosk/itemgroup/",
      BULKDISCOUNT: "update/kiosk/groupDiscount/",
      ITEMS:{
        ENABLEALL: "update/kiosk/itemgroup/enableAllItems/",
        DISABLEALL: "update/kiosk/itemgroup/disableAllItems/",
      },
      AUTOMATION: {
        GET: "get/kiosk/itemgroupAutomation/",
        UPDATE: "update/kiosk/itemgroup/automation/",
      }
    },
    MENU_ASSIGNEMENT: {
      MENU_GARNISH: {
        GET: "get/kiosk/itemDelegateMenuGarnishId/",
        UPDATE: "update/kiosk/itemDelegateMenuGarnishId/",
      },
      ITEM_DELEGATE_MENU: "update/kiosk/itemDelegateMenu/",
    },
    TERMINALS: {
      GET: "get/kiosk/editTerminal/",
      CREATE: "create/kiosk/terminal/",
      UPDATE: "update/kiosk/terminal/",
      DELETE: "delete/kiosk/terminal/",
      CONFIG_TOKEN: "get/kiosk/terminalConfigToken/",
      UPLOADHEADER: "create/kiosk/terminal/headerImage/",
    },
    IMAGETEMPLATES: {
      GET: "get/kiosk/kioskSlideTemplate/",
      GETALL: "get/kiosk/kioskSlideTemplates/",
      CREATE: "create/kiosk/kioskSlideTemplate/",
      UPDATE: "update/kiosk/kioskSlideTemplate/",
      LOGO: {
        UPDATE: "update/kiosk/kioskSlideTemplate/logo/",
        DELETE: "delete/kiosk/kioskSlideTemplate/logo/",
      },
      SLIDERIMAGE: {
        CREATE: "create/kiosk/kioskSlideTemplate/slideshowImage/",
        DELETE: "delete/kiosk/kioskSlideTemplate/slideshowImage/",
      },
      DELETE: "delete/kiosk/kioskSlideTemplate/",
    },
  },
  DELIVERY: {
    SETTINGS: {
      QUICK: {
        GET: "get/delivery/quickSettings/",
        UPDATE: "update/delivery/quickSettings/",
      },
      LOCALBEE: {
        SUBDOMAIN: {
          GET: "get/localbee/subdomainGenerator/",
          UPDATE: "update/localbee/subdomainGenerator/",
        },
        GENERALINFO: {
          GET: "get/localbee/generalInfo/",
          UPDATE: "update/localbee/generalInfo/",
        },
        DYNAMICCONTENT: {
          GET: "get/localbee/dynamicContent/",
          UPDATE: "update/localbee/dynamicContent/",
        },
        EMAIL: {
          TEMPLATES: {
            GET: "get/localbee/mailTmpl/ ",
            GETALL: "get/localbee/mailTmpls/",
            UPDATE: "update/localbee/mailTmpl/",
          },
        },
        DELIVERYTIMES: {
          GET: "get/localbee/openingHours/",
          UPDATE: "update/localbee/openingHours/",
        },
        PAYMENT: {
          GET: "get/localbee/paymentOptions/",
          UPDATE: "update/localbee/paymentOptions/",
          DELIVERY: {
            GET: "get/delivery/paymentOptionsDelivery/",
            UPDATE: "update/delivery/paymentOptionsDelivery/",
            PAYMENT_OPTIONS_TRANSLATIONS:
              "update/delivery/paymentOptionTranslationsDelivery/",
          },
          TAKEAWAY: {
            GET: "/get/delivery/paymentOptionsTakeAway/",
            UPDATE: "/update/delivery/paymentOptionsTakeAway/",
            PAYMENT_OPTIONS_TRANSLATIONS:
              "update/delivery/paymentOptionTranslationsTakeAway/",
          },
        },
        AUTOPRINTSETTINGS: {
          GET: "get/localbee/settings/autoPrinting/",
          UPDATE: "update/localbee/settings/autoPrinting/",
        },
        OTHERSETTINGS: {
          GET: "get/localbee/settings/",
          UPDATE: "update/localbee/settings/",
        },
        PRODUCTS: {
          UPDATE: "update/localbee/item/",
          BULKACTION: "update/localbee/itemActivate/",
        },
        DESIGN: {
          LOGOS: {
            GET: "get/localbee/logo/",
            UPDATE: "update/localbee/logoUpload/",
          },
          LAYOUT: {
            GET: "get/localbee/layout/",
            UPDATE: "update/localbee/layout/",
          },
        },
        PRODUCTGROUPS: {
          UPDATE: "update/localbee/itemgroup/",
          BULKACTION: {
            DISCOUNT: "update/localbee/itemGroupDiscount/",
          },
        },
        SELFSERVICE: {
          DESIGN: {
            COLOR: {
              GET: "get/localbee/selfServiceSettings/color/",
              UPDATE: "update/localbee/selfServiceSettings/color/",
            },
            LOGOS: {
              GET: "get/localbee/selfServiceSettings/images/",
              UPDATE: "update/localbee/selfServiceSettings/images/",
            },
            TEXT: {
              GET: "get/localbee/selfServiceSettings/texts/",
              UPDATE: "update/localbee/selfServiceSettings/texts/",
            },
            MESSAGES: {
              GET: "get/localbee/selfServiceSettings/messages/",
              UPDATE: "update/localbee/selfServiceSettings/messages/",
            },
            CONDITIONS: {
              GET: "get/localbee/selfServiceSettings/conditions/",
              UPDATE: "update/localbee/selfServiceSettings/conditions/",
            },
          },
          QRCODE: "create/TableQrCode/",
        },
      },
      DELIVERYAREA: {
        CREATE: "create/delivery/area/",
        GET: "get/delivery/area/",
        UPDATE: "update/delivery/area/",
        DELETE: "delete/delivery/area/",
        SEARCH: "get/delivery/deliveryAreaSearch/",
      },
      DELIVERYSTATUS: {
        CREATE: "create/delivery/statuses/",
        GET: "get/delivery/statuses/",
        GETACTIVE: "get/delivery/activeStatus/",
        UPDATE: "update/delivery/statuses/",
        DELETE: "delete/delivery/statuses/",
      },
    },
    ORDER: {
      GET: "get/delivery/order/",
      CREATE: "create/delivery/order/",
      DELETE: "delete/delivery/deleteOrder/",
      STATUS: {
        UPDATE: "update/delivery/order/status/",
        UPDATEALLUNOPENED: "update/delivery/order/openAllOrders/",
        BULKUPDATE: "update/delivery/order/orderStatusId/",
      },
      OPEN: {
        UPDATE: "update/delivery/order/openOrder/",
        UPDATEALL: "update/delivery/order/bulkOpenOrders/",
        GET: "get/delivery/unopenedOrder/",
      },
    },
    ORDERS: {
      GET: "get/delivery/orders/",
      GETUNOPENED: "get/delivery/unopenedOrders/",
      STATS: "get/delivery/ordersStatistics/",
    },
  },
  EVENTBEE: {
    SEATING_MAP:{
      GET: "get/eventBee/seatingMap/",
      CREATE: "create/eventBee/seatingMap/",
      UPDATE: "update/eventBee/seatingMap/",
      DELETE: "delete/eventBee/seatingMap/",
    },
    EVENT: {
      CREATE: "create/eventBee/event/",
      UPDATE: "update/eventBee/event/",
      GET: "get/eventBee/event/",
      DELETE: "delete/eventBee/event/",
      POSITION: "update/eventBee/eventPosition/",
      STATUS: "update/eventBee/eventStatus/",
      TICKETS: "get/eventBee/eventTickets/",
    },
    EMAIL: {
      SEND: "create/emails/eventbee/sendTemplate/",
    },
    PLANS: {
      UPDATE: "update/eventBee/eventPlaning/",
      GET: "get/eventBee/eventPlaning/",
      DELETE: "delete/eventBee/eventPlaning/",
    },
    ORDERS: {
      ALL: "get/eventBee/orders/",
      CANCEL: "update/eventBee/cancelOrder/",
      OVERVIEW: {
        WEEKLY_PLACES: "get/eventBee/eventWeeklyOverview/",
        GET: "get/eventBee/eventOverview/",
      },
      CHECKEDIN: "get/eventBee/checkedIn/",
    },
    ORDER_LIST: {
      DOWNLOAD_PDF: "get/eventBee/ordersPdf/",
      ORDER_DETAILS: "get/eventBee/order/",
      UPDATE: "update/eventBee/order/",
    },
    SERVICEPROVIDERS: "get/eventBee/users/",
    SETTINGS: {
      ATTRIBUTEGROUP: {
        GET: "get/eventBee/eventAttributes/",
        UPDATE: "update/eventBee/attributeGroup/",
        CREATE: "create/eventBee/attributeGroup/",
        DELETE: "delete/eventBee/attributeGroup/",
      },
      POOL: {
        GET: "get/eventBee/eventPools/",
        UPDATE: "update/eventBee/pool/",
        CREATE: "create/eventBee/pool/",
        DELETE: "delete/eventBee/pool/",
      },
      PRICESEGMENTS: {
        GET: "get/eventBee/priceSegment/",
        UPDATE: "update/eventBee/priceSegment/ ",
        CREATE: "create/eventBee/priceSegment/",
        DELETE: "delete/eventBee/priceSegment/",
      },
      SEATCATEGORY: {
        GET: "get/eventBee/seatCategories/",
        UPDATE: "update/eventBee/seatCategories/",
        CREATE: "create/eventBee/seatCategories/",
        DELETE: "delete/eventBee/seatCategories/",
      },
      PAYMENTS: {
        GET: "get/eventBee/paymentOptions/",
        BULKACTIVE: "update/eventBee/activePaymentOptions/",
        PAYMENT_OPTIONS_TRANSLATIONS:
          "update/eventBee/paymentOptionTranslations/",
      },
      EVENTGROUPS: {
        GET: "get/eventBee/group/ ",
        UPDATE: "update/eventBee/group/ ",
        CREATE: "create/eventBee/group/",
        DELETE: "delete/eventBee/group/",
      },
      REFUNDPOLICIES: {
        GET: "get/eventBee/refundPolicy/",
        UPDATE: "update/eventBee/refundPolicy/",
        CREATE: "create/eventBee/refundPolicy/",
        DELETE: "delete/eventBee/refundPolicy/",
      },
      CUSTOMER_NOTIFICATIONS: {
        GETSMS: "get/eventBee/smsNotification/",
        GETEMAIL: "get/eventBee/emailNotification/",
        UPDATESMS: "update/eventBee/smsNotification/",
        UPDATEEMAIL: "update/eventBee/emailNotification/",
      },
      EMAIL: {
        TEMPLATES: {
          GET: "get/eventBee/mailTmpl/",
          GETALL: "get/eventBee/mailTmpls/",
          UPDATE: "update/eventBee/mailTmpl/",
          CREATE: "create/eventBee/mailTmpl/",
          DELETE: "delete/eventBee/mailTmpl/",
        },
        CONFIG: {
          GET: "get/eventBee/email/",
          UPDATE: "update/eventBee/email/",
        },
        TEST: "create/emails/testEventbee/",
      },
      DYNAMICCONTENT: {
        GETFOOTER: "get/eventBee/footer/",
        GETHOME: "get/eventBee/homePage/",
        UPDATEFOOTER: "update/eventBee/footer/",
        UPDATEHOME: "update/eventBee/homePage/",
        GETINVOICELAYOUT: "get/eventBee/ticketSettings/",
        UPDATEINVOICELAYOUT: "update/eventBee/ticketSettings/",
      },
      GLOBALSETTINGS: {
        GET: "get/eventBee/globalSettings/",
        UPDATE: "update/eventBee/globalSettings/",
      },
      TICKET_CODES: {
        CREATE: "create/eventBee/ticketCodeGenerator/",
      },
    },
    POS: {
      TICKETS: {
        CHECK_VALIDITY: "get/cashier/ticketCheck/",
      },
    },
    ANALYTICS: {
      EVENTSANDGROUPSREPORT: "get/eventBee/eventSummary/",
      DAILYREPORT: "get/eventBee/dashboardSummary/",
    },
  },
  VOUCHERBEE: {
    SETTINGS: {
      PAYMENTS: {
        GET: "get/voucherBee/paymentOptions/",
        BULKACTIVE: "update/voucherBee/activePaymentOptions/",
        PAYMENT_OPTIONS_TRANSLATIONS:
          "update/voucherBee/paymentOptionTranslations/",
      },
      EMAIL: {
        CONFIG: {
          GET: "get/voucherBee/email/",
          UPDATE: "update/voucherBee/email/",
        },
        TEST: "create/emails/testVoucherbee/",
      },
      DYNAMICCONTENT: {
        GETFOOTER: "get/voucherBee/footer/",
        GETHOME: "get/voucherBee/homePage/",
        UPDATEFOOTER: "update/voucherBee/footer/",
        UPDATEHOME: "update/voucherBee/homePage/",
      },
      IMAGETEMPLATE: {
        GET: "get/voucherBee/voucherImageTemplate/",
        GETALL: "get/voucherBee/voucherImageTemplates/",
        CREATE: "create/voucherBee/voucherImageTemplate/",
        DELETE: "delete/voucherBee/voucherImageTemplate/",
      },
    },
  },
  PMS: {
    SETTINGS: {
      DEPARTMENTS: {
        CREATE: "create/pms/department/",
        UPDATE: "update/pms/department/",
        DELETE: "delete/pms/department/",
        GET: "get/pms/department/",
      },
      GLOBALSETTINGS: {
        UPDATE: "update/pms/settings/",
        GET: "get/pms/settings/",
      },
    },
    BREAKFASTLIST: {
      UPDATE: "update/pms/reservation/",
      GET: "get/pms/reservation/",
      HISTORY: "get/pms/timeline/",
      EXPORT: "get/pms/reservationsPdf/",
    },
  },
  KANTINE: {
    ARCHIVE: {
      GET: "get/canteen/orderArchive/",
    },
    ORDER: {
      DELETE: "delete/canteen/orders/",
      GET: "get/canteen/orders/",
    },
    MEALCHECKOUT: {
      SCAN: "get/canteen/mealCheckOut/scan/",
      SERVE: "update/canteen/mealCheckOut/serveOrder/",
    },
    MENU: {
      NOTE: {
        UPDATE: "update/canteen/messageOfTheDay/",
        GET: "get/canteen/messageOfTheDay/",
      },
      ITEMS: {
        GET: "get/canteen/menu/",
        UPDATE: "update/canteen/menu/",
        DELETE: "delete/canteen/menu/",
      },
    },
    SETTINGS: {
      GLOBALINFO: {
        UPDATE: "update/canteen/settings/",
        GET: "get/canteen/settings/",
      },
      GLOBALSETTINGS: {
        UPDATE: "update/canteen/globalSettings/",
        GET: "get/canteen/globalSettings/",
        MENUSHORTNAME: {
          GET: "get/canteen/menuShortName/",
          GETALL: "get/canteen/menuShortNames/",
          CREATE: "create/canteen/menuShortName/",
          UPDATE: "update/canteen/menuShortName/",
          DELETE: "delete/canteen/menuShortName/",
        },
      },
      LOGOS: {
        UPDATE: "update/canteen/logoUpload/",
        GET: "get/canteen/logos/",
      },
      IMAGES: {
        UPDATE: "update/canteen/imageUpload/",
        GET: "get/canteen/images/",
      },
      CARD: {
        GET: "get/canteen/cardImage/",
        UPDATE: "update/canteen/cardImage/",
      },
      CLASS: {
        CREATE: "create/canteen/class/",
        GET: "get/canteen/class/",
        DELETE: "/delete/canteen/classes/",
        UPDATE: "update/canteen/class/",
      },
      CLASSES: {
        GET: "get/canteen/classes/",
      },
      GROUP: {
        CREATE: "create/canteen/groups/",
        GET: "get/canteen/group/",
        UPDATE: "update/canteen/groups/",
        DELETE: "delete/canteen/groups/",
      },
      GROUPS: {
        GET: "get/canteen/groups/",
      },
      CUSTOMER_CLASS_GROUP: {
        GET: "get/customer/getCustomersClassAndGroup/",
        UPDATE: "/update/customer/customerClassGroup/",
      },
      EMAILS: {
        UPDATE: "update/canteen/email/",
        GET: "get/canteen/email/",
        TEST: "create/emails/testCanteen/",
        BROADCAST: "create/emails/canteen/broadcast/",
        TEMPLATES: {
          GET: "get/canteen/mailTmpl/",
          GETALL: "get/canteen/mailTmpls/",
          UPDATE: "update/canteen/mailTmpl/",
        },
      },
      PAYMENTS: {
        UPDATE: "update/canteen/paymentProviders/",
        GET: "get/canteen/paymentProviders/",
      },
    },
  },
  POS_REPLICATION: {
    REPLICATION_TEMPLATE: {
      CREATE: "create/replication/replicationTMPL/",
      DELETE: "delete/replication/replicationTMPL/",
      UPDATE_STATE: "update/replication/replicationTMPLDataState/",
      UPDATE_DATA: "datatables/replication/updateReplicationTMPLData/",
    },
    ASSIGN_TEMPLATE: {
      GET: "get/replication/replicationSystems/",
      UPDATE: "update/replication/delegate/",
    },
    SETTINGS: {
      GET: "get/replication/settings/",
      UPDATE: "update/replication/settings/",
    },
  },
  ERP: {
    ORDERWARE: {
      GET: "get/wareorder/prepareOrder/",
      CREATE: "create/erp/wareorder/",
      DETAILS: "get/wareorder/details/",
      PRINTA4: "get/erp/itemOrderA4Pdf/",
      UPDATECONTROL: "update/erp/orderwarereceipt/",
      NOTES: "update/erp/wareorder/notes/",
    },
    ITEM: {
      STOCKHISTORY: "get/erp/checkItemStockHistory/",
      DUPLICATE: "create/erp/duplicateItem/",
      CREATE: "create/erp/item/",
      DELETE: "delete/erp/item/",
      GET: "get/erp/createItem/",
      SEARCH: "get/erp/itemSearch/", //WITH SUPPLIER CONTROL
      SEARCH2: "get/erp/itemSearch2/", // ONLY ITEMS FOR CASHIER / POS SEARCH
      SEARCHSTOCKTAKING: "get/erp/itemSearchStockTaking/", // ONLY FOR STOCK TAKING
      POSLAYOUTSEARCH: "get/erp/posLayoutItemSearch/",
      VARIATIONS: {
        GET: "get/erp/itemvariations/",
      },
      DONTASKFOREXTRAS: "update/erp/item/dontAskForExtras/",
      DELEGATEGARNISH: {
        UPDATE: "datatables/erp/updateItemGarnishData/",
      },
      GETCONTROLSYSTEM: "get/erp/warecontrolsystem/",
      TOGGLESTATUS: "update/erp/toggleItemActiveState/",
      UPDATEWARECONTROLSYSTEMQTY: "update/erp/wareControlSystemQTY/",
      UPDATEWARECONTROLSYSTESUPPLIERS: "update/erp/wareControlSystemSuppliers/",
      SUPPLIERSDATA: "get/erp/wareSuppliersControl/",
      RECIPE: {
        GET: "get/erp/editItemRecipe/",
        UPDATE: "update/erp/itemRecipe/",
      },
      BARCODES: {
        CREATE: "create/erp/additionalBarcode/",
        UPDATE: "update/erp/additionalBarcode/",
        DELETE: "delete/erp/additionalBarcode/",
        CHECKDUPLICATEEAN: "get/erp/checkDuplicateEANCode/",
        CHECKDUPLICATEINTERNALID: "get/erp/checkDuplicateInternalId/",
      },
      SERIALNUMBERS: {
        CREATE: "create/erp/serialNumber/",
        DELETE: "delete/erp/serialNumber/",
        CHECK: "get/cashier/checkSerialNumber/",
      },
      WAREINFO: {
        GET: "get/retail/wareinfo/",
        STORAGELOGS: {
          EXCEL: "get/reports/erp/itemStorageLog/",
        },
      },
      CUSTOMER_GROUP_PRICES: {
        PRICES_PER_ITEM: "get/erp/customerPricesPerItem/",
        UPDATE: "update/erp/customerGroupItem/",
      },
      IMPORT: {
        DRAFT_CREATE: "create/erp/wareImport/",
        DRAFT_GET: "datatables/erp/wareImportTempData/",
        FINISH: "create/erp/wareImportFinish/",
        IMPORT_ITEM_CONTROLLSYSTEM_DATA: "create/erp/wareSupplierImport/",
      },
      EXPORT: {
        EXCEL: "get/erp/itemExport/",
      },
      ADDITIONALIMAGES: {
        CREATE: "create/erp/item/productImage/",
        GET: "get/erp/editItem/productImages/",
        DELETE: "delete/erp/item/productImage/"
      }
    },
    UNITS: {
      GET: "get/erp/units/",
    },
    PACKAGES: {
      GET: "get/erp/packages/",
      UPDATE: "update/erp/packages/",
      CREATE: "create/erp/packages/",
      DELETE: "delete/erp/packages/",
    },
    EDITITEM: {
      UPDATE: "update/erp/item/",
      DELETE: "delete/erp/item/",
      GET: "get/erp/editItem/",
      WAREVARIATION: {
        CREATE: "create/erp/itemVariation/",
        GET: "get/erp/itemVariation/",
        EDIT: "update/erp/itemVariation/",
        DELETE: "delete/erp/item/",
      },
    },
    GARNISHGROUP: {
      GET: "get/erp/garnish/groups/",
      GET_ALl_WITH_ITEMS: "get/erp/garnish/groups/",
      CREATE: "create/erp/garnish/groups/",
      UPDATE: "update/erp/garnish/groups/",
      DELETE: "delete/erp/garnish/groups/",
    },
    ITEMGROUP: {
      CREATE: "create/erp/itemgroup/",
      DELETE: "delete/erp/itemgroup/",
      GET: "get/erp/createItemgroup/",
      DONTASKFOREXTRAS: "update/erp/itemgroup/dontAskForExtras/",
      DELEGATEGARNISH: {
        UPDATE: "datatables/erp/updateWaregroupGarnishData/",
      },
    },
    EDITITEMGROUP: {
      DELETE: "delete/erp/itemgroup/",
      UPDATE: "update/erp/itemgroup/",
      GET: "get/erp/editItemgroup/",
    },
    MEALTYPE: {
      CREATE: "create/erp/mealType/",
      DELETE: "delete/erp/mealType/",
      UPDATE: "update/erp/mealType/",
      GET: "get/erp/mealType/",
    },
    ALLERGENE: {
      CREATE: "create/erp/allergene/",
      DELETE: "delete/erp/allergen/",
      UPDATE: "update/erp/allergen/",
      GET: "get/erp/editAllergene/",
      GETALL: "get/erp/allergens/",
    },
    ADDITIVE: {
      CREATE: "create/erp/additive/",
      DELETE: "delete/erp/additive/",
      UPDATE: "update/erp/additive/",
      GET: "get/erp/editAdditive/",
    },
    VOIDREASONS: {
      CREATE: "create/erp/voidReason/",
      DELETE: "delete/erp/voidReason/",
      UPDATE: "update/erp/voidReason/",
      GET: "get/erp/editVoidReason/",
    },
    COURSES: {
      CREATE: "create/erp/course/",
      DELETE: "delete/erp/course/",
      UPDATE: "update/erp/course/",
      GET: "get/erp/editCourse/",
    },
    CUSTOMER: {
      GET: "get/customer/selectDropdown/",
    },
    DEPOSIT: {
      CREATE: "create/erp/deposit/",
      DELETE: "delete/erp/deposit/",
      UPDATE: "update/erp/deposit/",
      GET: "get/erp/editDeposit/",
      GETITEMGROUP: "get/erp/depositItemgroupId/",
      UPDATEITEMGROUP: "update/erp/depositItemgroup/",
    },
    EXTRATAB: {
      CREATE: "create/erp/garnishTab/",
      DELETE: "delete/erp/garnishTab/",
      UPDATE: "update/erp/garnishTab/",
      GET: "get/erp/editGarnishTabs/",
    },
    FREETEXTTAG: {
      CREATE: "create/erp/freeTextTag/",
      DELETE: "delete/erp/freeTextTag/",
      UPDATE: "update/erp/freeTextTag/",
      GET: "get/erp/editFreeTextTag/",
    },
    GIFTCARD: {
      CREATE: "create/erp/giftCard/",
      GET: "get/erp/displayGiftCard/",
      UPDATE: "update/erp/giftcardUUID/",
      VOID: "update/erp/voidGiftCard/",
    },
    SIZEVARIATION: {
      CREATE: "create/erp/mealSize/",
      DELETE: "delete/erp/mealSize/",
      UPDATE: "update/erp/mealSize/",
      GET: "get/erp/editMealSize/",
    },
    TAXSETUP: {
      CREATE: "create/erp/tax/",
      DELETE: "delete/erp/tax/",
      UPDATE: "update/erp/taxRate/",
      GET: "get/erp/editTax/",
      GETALL: "get/erp/taxRates/",
      OFFSITE: {
        GET: "get/erp/tax/",
        UPDATE: "update/erp/offSiteSaleTax/",
      },
    },
    INVENTORYITEM: {
      CREATE: "create/erp/inventoryItem/",
      DELETE: "delete/erp/item/",
      UPDATE: "update/erp/inventoryItem/",
      GET: "get/erp/editInventoryItem/",
      GETDATA: "get/erp/createItem/",
      EAN: {
        CREATE: "get/erp/createEANCode/",
      },
    },
    GARNISH: {
      CREATE: "create/erp/garnish/",
      DELETE: "delete/erp/garnish/",
      UPDATE: "update/erp/garnish/",
      GET: "get/erp/editGarnish/",
      BULK_ACTIVATION: "update/erp/garnish/activate/",
      CHANGE_POSITION: "update/erp/garnishPosition/",
      BULK_PRICE_OVERRIDE: "update/erp/updateItemGarnishPrices/",
      OVERRIDE_PRICE: "update/erp/updateItemGarnishPrices/",
      RERANGE_ALPHABET: "update/erp/rearrangeGarnishByAlphabet/",
    },
    GARNISHTABS: {
      GET: "get/erp/garnishTabNames/",
    },
    VOUCHER: {
      CREATE: "create/erp/voucher/",
      GET: "get/erp/displayVoucher/",
      GETNEW: "get/cashier/voucher/",
      GETPDF: "create/erp/printVoucherPDF/",
      VOID: "update/erp/voidVoucher/",
    },
    PRODUCERS: {
      CREATE: "create/erp/producer/",
      DELETE: "delete/erp/producer/",
      UPDATE: "update/erp/producer/",
      GET: "get/erp/editProducer/",
      GETALL: "get/erp/producers/",
    },
    SUPPLIERS: {
      CREATE: "create/erp/supplier/",
      DELETE: "delete/erp/supplier/",
      UPDATE: "update/erp/supplier/",
      GET: "get/erp/editSupplier/",
      GETALL: "get/erp/suppliers/",
    },
    PRINTSHELFLABEL: {
      PRINTSMALL: "get/erp/printShelfLabel/",
      PRINTMEDIUM: "get/erp/printShelfLabelMedium/",
      PRINTLARGE: "get/erp/printShelfLabelLarge/",
      PRINTLEGACY: "get/erp/printShelfLabelLegacy/",
      SETTINGS: {
        GET: "get/erp/printShelfLabelSettings/",
        UPDATE: "update/erp/shelfLabelSettings/",
      },
    },
    PRINTWARELABEL: {
      PRINTPDFBYSIZE: "get/erp/itemLabel/",
      PRINTPDF: "get/label/getPDFLabel/",
      PRINT: "get/label/getZebraXML/",
      SEND: {
        POS: "pos/get/label/sendZebraXML/",
        V3API: "v3-API/pos/get/label/sendZebraXML/",
      },
      SETTINGS: {
        GET: "get/erp/printLabelSettings/",
        UPDATE: "update/erp/labelSettings/",
      },
    },
    STORAGEOVERVIEW: {
      GET: "get/storage/storageOverview/",
      UPDATE: "update/storage/storageDetails/",
      SHOW: "get/storage/editStorageDetails/",
      CREATE: "create/storage/createStorage/",
      DELETE: "delete/storage/deleteStorage/",
    },
    STOCKTAKING: {
      CREATE: "create/erp/stockTaking/",
      DELETE: "delete/erp/stockTaking/",
      UPDATE: "update/erp/stockTakingProcessing/",
      DETAILS: {
        DELETE: "delete/erp/stockTakingEntry/",
      },
      QUANTITY: "get/erp/takeStockOnlineCurrentItemQTY/",
      UPDATE_QT: "update/erp/takeStockOnlineItemQTY/",
      SHOW: "get/erp/stockTakingInformation/",
    },
    SOTORAGETRANSFER: {
      CREATE: "create/storage/createTransfer/",
    },
    STORAGELOGS: {
      GETALL: "get/storage/storagelogs/",
    },
    PRICETEMPLATES: {
      CREATE: "create/erp/priceTMPL/",
      DELETE: "delete/erp/priceTMPL/",
      GET: "get/erp/priceTMPL/",
      UPDATE: "update/erp/assignPriceTMPL/",
      PRICETMPLDATASTATE: "update/erp/priceTMPLDataState/",
      EDITITEM: "datatables/erp/updatePriceTMPLData/",
    },
    GOODSRECEIPT: {
      CREATE: "create/storage/goodsReceipt/",
      UPDATE: "update/erp/course/",
      GET: "get/erp/editCourse/",
      SHOW: "get/storage/goodsReceiptDisplay/",
      SAVEITEMS: "update/storage/storageTransferItems/",
      EDIT: "update/storage/storageTransferDetails/",
      CLEAR: "delete/storage/storageTransfer/",
      COMMIT: "update/storage/storageTransferCommitItems/",
      DOWNLOAD_EXCEL: "get/storage/goodsReceiptList/",
    },
    GOODSISSUE: {
      CREATE: "create/storage/goodsIssue/",
      UPDATE: "update/erp/course/",
      GET: "get/erp/editCourse/",
      SHOW: "get/storage/goodsIssueDisplay/",
      DOWNLOAD_EXCEL: "get/storage/goodsIssueList/",
    },
    STORAGES: {
      GET: "get/storage/storages/",
    },
    HAPPYHOUR: {
      CREATE: "create/erp/happyHour/",
      DELETE: "delete/erp/happyHour/",
      UPDATE: "update/erp/happyHour/",
      GET: "get/erp/editHappyHour/",
    },
    SPECIALOFFER: {
      CREATE: "create/erp/specialOffer/",
      DELETE: "delete/erp/specialOffer/",
      UPDATE: "update/erp/specialOffer/",
      GET: "get/erp/editSpecialOffer/",
    },
    INGRREDIENT: {
      DELETE: "delete/erp/item/",
      CREATEAN: "get/erp/createEANCode/",
      GERCHECKBOXES: "get/erp/createItem/",
      CREATE: "create/erp/ingredient/",
      GET: "get/erp/editIngredient/",
      UPDATE: "update/erp/ingredient/",
    },
    INGRREDIENTGROUP: {
      DELETE: "delete/erp/ingredient/groups/",
      CREATE: "create/erp/ingredient/groups/",
      GET: "get/erp/ingredient/groups/",
      UPDATE: "update/erp/ingredient/groups/",
      GETALL: "get/erp/ingredient/groups/",
    },
    MULTIPLESTORAGECOUNT: "get/erp/getMultipleStorageCount/",
    DAILYGOODS: {
      UPDATE: {
        AMOUNT: "update/erp/dailyAmount/",
        ISDAILYGOODS: "update/erp/dailyGoods/",
        QUANTITY_HISTORY: "update/erp/dailyAmountHistory/",
        IMPORT: "create/erp/itemDailyCSVUpload/",
      },
      GET: "get/erp/itemDailyAmount/",
      EXPORT: "get/erp/itemDailyAmountPdf/",
      EXPORTEXCEL: "get/reports/erp/dailyGoods/",
    },
  },
  CUSTOMERS: {
    CUSSALUTATION: {
      GET: "get/customer/customerSalutation/",
      EDIT: "update/customer/customerSalutation/",
      CREATE: "create/customer/customerSalutation/",
      DELETE: "delete/customer/customerSalutation/",
    },
    CUSTDEPARTMENTS: {
      GET: "get/customer/customerDepartment/",
      GET_ALL: "get/customer/customerDepartments/",
      EDIT: "update/customer/customerDepartment/",
      CREATE: "create/customer/customerDepartment/",
      DELETE: "delete/customer/customerDepartment/",
    },
    CUSTOMER: {
      GETCREATE: "get/customer/createCustomer/",
      GETEDIT: "get/customer/editCustomer/",
      CUSTOMERINFO: "get/customer/customerInfo/",
      GETALL: "get/customers/",
      UPDATE: "update/customer/",
      DELETE: "delete/customer/customer/",
      CREATE: "create/customer/",
      RECREATEACCOUNTUUID: "create/customer/recreateAccountUUID/",
      IMAGE: {
        UPDATE: "update/customer/customerImage/",
        DELETE: "delete/customer/customerImage/",
      },
      SHIPPINGCONTACTS: "get/customer/selectShippingContacts/",
      BILLINGCONTACTS: "get/customer/selectBillingContacts/",
      LUNCHBEESTATS: "get/customer/lunchbeeStats/",
      LUNCHBEEUSER: "create/customer/lunchbeeUser/",
      LUNCHBEEUSERRESET: "update/customer/lunchBeeAccountPassword/",
      CURRENTBALANCE: "get/customer/currentBalance/",
      ACCOUNTSTATS: "get/customer/accountStats/",
      CHARGEACCOUNT: "update/customer/chargeAccount/",
      DISCHARGEACCOUNT: "update/customer/dischargeAccount/",
      LUNCHBEE: {
        ALLERGENS: {
          UPDATE: "update/customer/customerAllergens/",
          GET: "get/customer/customerAllergens/",
          GETWITHNAMES: "get/customer/customerAllergensWithName/",
        },
      },
      GETINFO: "get/delivery/customerInfos/",
      GENERATETOKEN: "create/uuid/",
    },
    CUSTOMERGROUPS: {
      CREATE: "create/customer/customerGroup/",
      DELETE: "delete/customer/customerGroup/",
      GET: "get/customer/customerGroup/",
      GETALL: "get/customer/customerGroups/",
      UPDATE: "update/customer/customerGroup/",
      SETDISCOUNT: "update/customer/customerGroupItemGroupDiscount/",
      SETNEWSALEPRICE: "update/customer/customerGroupItemPrice/",
    },
    PROJECTS: {
      GETALL: "get/customer/customerProjectsByCustomerId/",
      PROJECTREPORT: "get/customer/customerProjectReport/",
    },
    IMPORT: {
      DRAFT_CREATE: "create/customer/customerImport/",
      DRAFT_GET: "datatables/customer/customerImportTempData/",
      FINISH: "create/customer/customerImportFinish/",
    },
  },
  BILLING: {
    INVOICEDATA: "get/facture/invoiceData/",
    BILL: {},
    DRAFT: {
      CREATBILLDRAFT: "create/facture/invoiceDraft/",
      GET: "get/facture/invoiceDraft/",
      UPDATE: "update/facture/invoiceDraft/",
      DELETE: "delete/facture/invoiceDraft/",
      TOPROFORMAT: "create/facture/proFormaFromInvoiceDraft/",
    },
    QUOTATIONS: {
      CREATE: "create/facture/quotation/",
      GET: "get/facture/quotation/",
      GETPDF: "get/facture/quotationData4PDF/",
      UPDATE: "update/facture/quotation/",
      DELETE: "delete/facture/quotation/",
      TODRAFT: "create/facture/invoiceDraftFromQuotation/",
    },
    DELIVERYNOTES: {
      GET: "get/facture/deliveryNote/",
      GETPDF: "get/facture/deliveryNoteData4PDF/",
      CREATE: "create/facture/deliveryNote/",
      UPDATE: "update/facture/deliveryNote/",
      TODRAFT: "create/facture/invoiceDraftFromDeliveryNote/",
      DELETE: "delete/facture/deliveryNote/",
    },
    PROFORMAT: {
      GETPDF: "get/facture/proFormaData4PDF/",
      GET: "get/facture/proForma/",
      UPDATE: "update/facture/proForma/",
      DELETE: "delete/facture/proForma/",
    },
    COLLECTIVEBILLS: {
      GETDAY: "get/facture/collectiveBillsDaily/",
    },
  },
  ONLINESHOP: {
    ORDER: {
      SETSTATUS: "update/onlineShop/orderStatus/",
    },
    ORDERS: {
      COUNT: "get/dashboardOnlineShop/countNewOrders/",
    },
  },
  SETTINGS: {
    GEOLOC: {
      GET: "get/settings/geoloc/",
      UPDATE: "update/settings/geoloc/",
    },
    BASIC: {
      GET: "get/settings/mainSettings/",
      UPDATE: "update/settings/mainSettings/",
      LOGOUPLOAD: "create/settings/logoUpload/",
      PASSWORD: {
        UPDATE: "update/settings/password/",
      },
      USERGROUP: {
        PERMISSION: "get/settings/permissionManagement/",
        CRETAEGROUP: "create/settings/permissionGroup/",
        GET: "get/settings/editPermissionManagement/",
        DELETE: "delete/settings/permissionGroup/",
        UPDATE: "update/settings/permissionGroup/",
      },
      ROLES: {
        CREATE: "create/settings/userRole/",
        DELETE: "delete/settings/userRole/",
        UPDATE: "update/settings/userRole/",
        GET: "get/settings/editUserRole/",
      },
      SYSTEMSETTINGS: {
        CASHIER: {
          ALIAS: {
            UPDATE: "update/settings/cashiers/alias/",
          },
          OPTIONS: {
            GET: "get/settings/editCashierSettings/",
            UPDATE: "update/settings/cashiers/settings/",
            TRANSLATION: {
              UPDATE: "update/settings/translation/",
              GET: "get/settings/translation/",
            },
          },
          SUBDOMAIN: {
            CREATE: "create/localbee/createAuthAndSubdomain/",
            GET: "get/localbee/subdomainGenerator/",
          },
        },
        EMAIL: {
          UPDATE: "/update/settings/emailSettings/",
          GET: "/get/settings/emailSettings/",
          TEST: "/create/emails/test/",
        },
        RECEIPTA4: {
          UPDATE: "/update/settings/invoiceSettings/",
          GET: "/get/settings/invoiceSettings/",
          TEXTTEMPLATES: {
            GET: "get/settings/invoiceTextTemplate/",
            GETALL: "get/settings/invoiceTextTemplates/",
            CREATE: "create/settings/invoiceTextTemplate/",
            UPDATE: "update/settings/invoiceTextTemplate/",
            DELETE: "delete/settings/invoiceTextTemplate/",
          },
          DELIVERYTERMS: {
            GET: "get/settings/deliveryTermsTemplate/",
            GETALL: "get/settings/deliveryTermsTemplates/",
            CREATE: "create/settings/deliveryTermsTemplate/",
            UPDATE: "update/settings/deliveryTermsTemplate/",
            DELETE: "delete/settings/deliveryTermsTemplate/",
          },
        },
        PAYMENT: {
          PAYMENTS: {
            GET: "get/settings/activePayments/",
          },
          CASHPAYMENT: {
            GET: "get/settings/paymentSettings/",
            UPDATE: "update/settings/paymentSettings/",
          },
          ECPAYMENT: {
            UPDATE: "/update/settings/ecPayment/",
            GET: "/get/settings/ecPayment/",
          },
          PAYPAL: {
            GET: "get/settings/paymentSettings/paypal/",
            UPDATE: "update/settings/paymentSettings/paypal/",
          },
          STRIPE: {
            CREATE: "create/settings/stripeConnect/",
            CHECK: "get/settings/stripeConnect/",
          },
          SECUPAY: {
            GET: "get/settings/paymentSettings/secupay/",
            UPDATE: "update/settings/paymentSettings/secupay/",
          },
          ADDITIONALPAYMENTS: {
            GET: "get/settings/editAdditionalPayment/",
            UPDATE: "update/settings/additionalPayment/",
            CREATE: "create/settings/additionalPayment/",
            DELETE: "delete/settings/additionalPayment/",
          },
        },
        GLOBALCASHIEROPTIONS: {
          GET: "get/settings/globalCashierOptions/",
          UPDATE: "update/settings/globalCashierOptions/",
        },
        ERPSETTINGS: {
          GET: "get/settings/erpSettingsOptions/",
          UPDATE: "update/settings/erpSettingsOptions/",
        },
        BILLING_OPTIONS: {
          GET: "get/settings/globalCashierBillingOptions/",
          UPDATE: "update/settings/globalCashierBillingOptions/",
        },
        CURRENCYANDROUNDING: {
          GET: "get/settings/globalCashierCurrencyOptions/",
          UPDATE: "update/settings/globalCashierCurrencyOptions/",
        },
        VMP: {
          MAINSETTINGS: {
            GET: "get/settings/vmpSettingsMain/",
            UPDATE: "update/settings/vmpSettingsMain/",
          },
          TRANSFERSETTINGS: {
            GET: "get/settings/vmpSettingsTransfer/",
            UPDATE: "update/settings/vmpSettingsTransfer/",
          },
        },
      },
      APPSETTINGS: {
        HYBRIDSETTINGS: {
          GET: "get/settings/appSettings/hybridSettings/",
          UPDATE: "update/settings/appSettings/hybridSettings/",
        },
      },
    },
    MODULESETTINGS: {
      CASHRECYCLER: {
        CREATE: "create/settings/cashRecycler/",
        GET: "get/settings/editCashRecycler/",
        UPDATE: "update/settings/cashRecycler/",
        DELETE: "delete/settings/cashRecycler/",
      },
      TABAKOS_API: {
        IMPORT: "3rdPartyIntegrations/tabakos/import/",
      },
      SCALE: {
        CREATE: "create/settings/scale/",
        GET: "get/settings/editScale/",
        UPDATE: "update/settings/scale/",
        DELETE: "delete/settings/scale/",
      },
      VCITA: {
        GET: "3rdPartyIntegrations/vcita/get/apiData/",
        UPDATE: "3rdPartyIntegrations/vcita/update/apiData/",
      },
      VOFFICE: {
        APIKEY: {
          UPDATE: "update/voffice/apiKey/",
          GET: "get/voffice/apiKey/",
        },
        UPDATE: "update/voffice/email/",
        GET: "get/voffice/email/",
        EMAILTEST: "get/voffice/emailTest/",
        CUSTOMERINFO: "get/cashier/vOfficeCustInfo/"
      },
      CALLCENTER: {
        PROVIDERS: {
          APIKEYS: {
            GET: "get/settings/apiKey/",
            CREATE: "create/settings/apiKey/",
            DELETE: "delete/settings/apiKey/",
          },
        },
      },
      ZMYLE: {
        GET: "3rdPartyIntegrations/zmyle/get/credentials/",
        UPDATE: "3rdPartyIntegrations/zmyle/update/credentials/",
        DETAILS: "3rdPartyIntegrations/zmyle/get/cardInformations/",
      },
      WOLT: {
        GET: "get/settings/woltDelivery/",
        UPDATE: "update/settings/woltDelivery/",
      },
      MERGEPORT: {
        GET: "3rdPartyIntegrations/mergeport/get/credentials/",
        UPDATE: "3rdPartyIntegrations/mergeport/update/credentials/",
      },
      SHOPWARE: {
        GET: "3rdPartyIntegrations/shopware/get/credentials/",
        UPDATE: "3rdPartyIntegrations/shopware/update/credentials/",
      },
      MYWORLD: {
        GET: "3rdPartyIntegrations/myworld/get/credentials/",
        UPDATE: "3rdPartyIntegrations/myworld/update/credentials/",
      },
      PAPERLESSINVOICE: {
        GET: "get/settings/paperlessInvoiceSettings/",
        UPDATE: "update/settings/paperlessInvoiceSettings/",
      },
      EXTERNALAPPS: {
        GETALL: "get/settings/externalApps/",
        GET: "get/settings/externalApp/",
        CREATE: "create/settings/externalApp/",
        UPDATE: "update/settings/externalApp/",
        DELETE: "delete/settings/externalApps/",
      },
      WEBHOOKS: {
        GET: "get/settings/webhook/",
        CREATE: "create/settings/webhook/",
        UPDATE: "update/settings/webhook/",
        DELETE: "delete/settings/webhooks/",
      },
      RATIOTEC_PAGER: {
        GET: "get/settings/ratiotecPager/",
        UPDATE: "update/settings/ratiotecPager/",
      },
      TIP_MANGER: {
        GENERAL_SETTINGS: {
          UPDATE: "update/settings/tipOptions/",
          GET: "get/settings/tipOptions/",
        },
        TIPS_MATRIX: {
          GET: "get/settings/tipDialogMatrix/",
          UPDATE: "update/settings/tipDialogMatrix/",
        },
        PAYMENT_METHODS: {
          GET: "get/settings/tipPayments/",
          UPDATE: "update/settings/tipPayments/",
        },
        ACTIVE_CASHIERS: {
          GET: "get/settings/tipPaymentCashiers/",
          UPDATE: "update/settings/tipPaymentCashiers/",
        },
        CUSTOMER_DISPLAY: {
          GET: "get/settings/tipCustomerDisplayOptions/",
          UPDATE: "update/settings/tipCustomerDisplayOptions/",
        },
      },
      DISCOUNT_MANAGER: {
        GET: "get/settings/discountDialogMatrix/",
        UPDATE: "update/settings/discountDialogMatrix/",
      },
      SMS_GATEWAY: {
        GET: "3rdPartyIntegrations/clicksend/get/credentials/",
        UPDATE: "3rdPartyIntegrations/clicksend/update/credentials/",
      },
      ACCOUNT_USAGE: {
        GET: "get/settings/accountUsage/",
      },
    },
    FISCAL: {
      FISCALDEVICE: {
        CREATE: "create/settings/fiscal_de/fiscalDevice/",
        GET: "get/settings/fiscal_de/fiscalDevice/",
        UPDATE: "update/settings/fiscal_de/fiscalDevice/",
        DELETE: "delete/settings/fiscal_de/fiscalDevice/",
        EXPORTDATA: {
          CREATE: "create/settings/fiscal_de/fiscalDevice/exportData/",
          GET: "get/fiscal/tseExport/",
        },
      },
      FISCALDEVICEFISKALY: {
        CREATE: "create/settings/fiscal_de/fiskaly/",
        GET: "get/settings/fiscal_de/fiskaly/",
        STARTTX: "create/cashier/fiskalyStartTransaction/",
      },
      FISCALDEVICES: {
        GET: "get/settings/fiscal_de/fiscalDevices/",
      },
      FISCALCLIENT: {
        GET: "get/settings/fiscal_de/fiscalClient/",
      },
      FISCALCLIENTS: {
        CREATE: "create/settings/fiscal_de/fiscalClient/",
        GET: "get/settings/fiscal_de/fiscalClients/",
        UPDATE: "update/settings/fiscal_de/fiscalClient/",
        DELETE: "delete/settings/fiscal_de/fiscalClient/",
      },
      FISCALY: {
        CREATE: "create/settings/fiskaly/organisation/",
      },
    },
    PICKUPNAME: {
      GET: "get/settings/pickUpNumber/",
      UPDATE: "update/settings/pickUpNumber/",
      CREATE: "create/settings/pickUpNumber/",
      DELETE: "delete/settings/pickUpNumber/",
    },
    PICKUPSETTINGS: {
      UPDATE: "update/settings/pickUpSettings/",
      GET: "get/settings/pickUpSettings/",
    },
    PICKUPMONITOR: {
      GET: "get/settings/globalPickupmonitorOptions/",
      UPDATE: "update/settings/globalPickupmonitorOptions/", 
    },
    KITCHENMONITORS: {
      GET: "get/settings/kitchenmonitors/",
      CREATE: "create/settings/kitchenmonitor/",
      DELETE: "delete/settings/kitchenmonitor/",
      UPDATE: "update/settings/kitchenmonitor/",
      GETALL: "get/settings/allKitchenMonitors/",
      SETTINGS: {
        GET: "get/settings/globalKitchenmonitorOptions/",
        UPDATE: "update/settings/globalKitchenmonitorOptions/"
      }
    },
    ORDERBON_ARCHIVE: {
      GET: "get/settings/orderbonArchiveData/",
    },
    CUSTOMERDISPLAYS: {
      GET: "get/settings/customerDisplays/",
      TEMPLATES: {
        GET: "get/settings/customerDisplayTemplates/",
      },
      TEMPLATE: {
        DELETE: "delete/settings/customerDisplayTemplate/",
        GET: "get/settings/customerDisplayTemplate/",
        UPDATE: "update/settings/customerDisplayTemplate/",
        CREATE: "create/settings/customerDisplayTemplate/",
      },
    },
    CUSTOMERDISPLAY: {
      TEMPLATE: {
        UPDATE: "update/settings/customerDisplay/template/",
        LOGO: {
          UPDATE: "update/settings/customerDisplayTemplate/logo/",
          DELETE: "delete/settings/customerDisplayTemplate/logo/",
        },
        SLIDESHOWIMAGE: {
          CREATE: "/create/settings/customerDisplayTemplate/slideshowImage/",
          DELETE: "/delete/settings/customerDisplayTemplate/slideshowImage/",
        },
      },
      DELETE: "delete/settings/customerDisplay/",
      CREATE: "create/settings/customerDisplay/",
    },
    PRICECHECK_TERMINAL: {
      TEMPLATES: {
        DATATABLE: "datatables/settings/priceCheckTemplates/",
        CREATE: "create/settings/priceCheckerTemplate/",
        DELETE: "delete/settings/priceCheck/",
      },
      TEMPLATE: {
        GET: "get/settings/priceCheckerTemplate/",
        COLOR: "create/settings/priceCheckerTemplate/color/",
        TEXT: "create/settings/priceCheckerTemplate/font/",
        LOGO: "create/settings/priceCheckerTemplate/slideshowLogo/",
        SLIDESHOWIMAGE: {
          CREATE: "create/settings/priceCheckerTemplate/slideshowImage/",
          DELETE: "delete/settings/priceCheck/slideshowImage/",
        },
        MESSAGES: "create/settings/priceCheckerTemplate/messages/",
        GENERAL_SETTINGS: "create/settings/priceCheckerTemplate/settings/",
      },
    },
    USER: {
      GETCREATE: "get/settings/createUser/",
      GET: "get/settings/editUser/",
      GETDRIVERS: "get/delivery/getDrivers/",
      CREATE: "create/settings/createUser/",
      DELETE: "delete/settings/user/",
      UPDATE: "update/settings/user/",
      GETALL: "get/settings/getUserDropDown/",
      CHECKDUPTOKEN: "get/settings/checkDuplicateUserToken/",
    },
    POSLAYOUT: {
      TEMPLATES: {
        GET: "get/settings/posLayoutTemplates/",
        CREATE: "create/settings/posLayoutTemplate/",
        DELETE: "delete/settings/posLayoutTemplate/",
      },
      TEMPLATE: {
        GET: "get/settings/posLayoutTemplate/",
        UPDATE: "update/settings/posLayoutTemplate/",
        RENDER: "get/cashier/posGrid/",
      },
      ASSIGNMENT: {
        UPDATE: "update/settings/posLayoutTemplateAssign/",
      },
      ASSIGNMENTS: {
        GET: "get/settings/posLayoutTemplatesAssign/",
      },
      CONFIG: {
        GET: "get/settings/layoutDesignSettings/",
        UPDATE: "update/settings/layoutDesignSettings/",
      },
    },
    CASHIERMODULES: {
      TEMPLATES: {
        GET: "get/settings/cashiermodules/templates/details/",
        GETALL: "get/settings/cashiermodules/templates/",
        DELETE: "delete/settings/cashiermodules/templates/",
        CREATE: "create/settings/cashiermodules/templates/",
        UPDATE: "update/settings/cashiermodules/templates/",
        SETMODULES: "update/settings/cashiermodules/modulesPerTemplate/",
      },
      ASSING: {
        GET: "get/settings/cashiermoduletemplates/",
        UPDATE: "update/settings/cashiermodules/cashierTemplates/",
        CASHIER: "get/settings/cashiermodules/templates/cachiers/",
      },
    },
    ROOMPLANER: {
      CREATE: "create/settings/room/",
      GET: "get/settings/room/",
      UPDATE: "update/settings/roomData/",
      DELETE: "delete/settings/room/",
      ROOMS: "get/settings/rooms/",
      GET_ALL: "get/settings/allRooms/",
      DETAILS: "get/settings/roomDetails/",
      TABLES: {
        DETAILS: "get/tableDetails/",
      },
      TABLEQR: "create/TableQrCode/table/",
      STATUSES: {
        GET: "get/TableStatus/",
        UPDATE: "update/erp/tableStatus/",
      },
    },
    ADDITIONALSTATISTICS: {
      GET: "get/cashier/additionalStatistics/",
      CREATE: "create/cashier/additionalStatistics/",
      UPDATE: "update/cashier/additionalStatistics/",
      DELETE: "delete/cashier/additionalStatistics/",
      ANALYTICS: {
        GET: "get/analytics/wareanalytics/additionalStats/",
        PDF: "get/analytics/pdf/salesByAdditionalStats/",
        EXCEL: "get/analytics/excel/salesByAdditionalStats/",
      },
    },
    SELFCHECKOUT: {
      GET: "get/settings/editSelfChekoutSettings/",
      UPDATE: "update/settings/selfCheckoutSettings/",
    },
  },
  PRINTSETTINGS: {
    PRINTER: {
      GET: "get/printSettings/editPrinter/",
      UPDATE: "update/printSettings/editPrinter/",
      CREATE: "create/printSettings/printer/",
      DELETE: "delete/printSettings/printer/",
    },
    TEMPLATES: {
      CREATE: "create/printSettings/receiptTmpl/",
      GET: "get/printSettings/receiptTmpl/",
      UPDATE: "update/printSettings/editReceiptTmpl/",
      DELETE: "delete/printSettings/receiptTmpl/",
      ASSIGN: "update/printSettings/receiptTemplateAssign/",
      ASSIGNED: "get/printSettings/receiptTemplatesTemplatesAssign/",
      ASSIGNORDERBON: "update/printSettings/orderbonTemplateAssign/",
      ASSIGNEDORDERBON: "get/printSettings/orderbonTemplatesTemplatesAssign/",
    },
  },
  ACCOUNTING: {
    CASHIER: {
      GET: "get/accounting/cashierOverview/",
    },
    CASHBOOKACCOUNTS: {
      GET: "get/accounting/editCashBookPostingRecord/",
      DELETE: "delete/accounting/cashBookPostingRecord/",
      CREATE: "create/accounting/cashBookPostingRecord/",
      UPDATE: "update/accounting/cashBookPostingRecord/",
    },
    DAILYREPORT: {
      GET: "get/accounting/dailyReport/getLast/",
      REPORTS: "get/accounting/dailyReport/reports/",
      SETTINGS: {
        GET: "get/accounting/dailyReport/settings/",
        UPDATE: "update/accounting/dailyReport/settings/",
      },
      AUTOMATION: {
        GET: "get/accounting/dailyReport/automation/",
        UPDATE: "update/accounting/dailyReport/automation/",
      },
      GET_DETAILS_BASE_DATA: "get/accounting/dailyReport/",
      CREATE_DAILY_SALE_REPORT: "create/accounting/dailySaleReport/",
      CREATE_MONTHLY_REPORT: "create/accounting/monthlySaleReport/",
      DOWNLOAD_DAILY_SALE_REPORT: "get/accounting/dailyReportA4Pdf/?",
      //charts
      UMSATZWAREGROUP: "get/accounting/dailyReport/umsatzWaregroup/",
      UMSATZCASHIER: "get/accounting/dailyReport/umsatzCashier/",
      //tables
      UMSATZTABLE: "get/accounting/dailyReport/umsatzTable/",
      UMSATZWAREGROUPTABLE: "get/accounting/dailyReport/umsatzWaregroupTable/",
      UMSATZCASHIERTABLE: "get/accounting/dailyReport/umsatzCashierTable/",
      CASHBOOK: "get/accounting/dailyReport/cashBook/",
      PRINTZ: "get/print/dailyReport/",
      CUMULATEPRINTZ: "get/print/dailyReportCummulate/",
      PRINTX: "get/print/dailyInterimReport/",
      PRINTUSERREPORT: "get/print/userShiftReport/",
      PRINTA4: "get/accounting/dailyReportA4Pdf/",
    },
    MANAGERREPORT: {
      PRINTA4: "get/accounting/managerReportA4Pdf/",
    },
    MONTHLYREPORT: {
      GETLAST: "get/accounting/monthlyReport/getLast/",
      CREATE_MONTHLY_REPORT: "create/accounting/monthlySaleReport/",
      GET: "get/accounting/monthlyReport/",
      UMSATZTABLE: "get/accounting/monthlyReport/umsatzTable/",
      REPRINT_REPORT: "get/accounting/monthlyReportA4Pdf/",
      UMSATZWAREGROUP: "get/accounting/monthlyReport/umsatzWaregroup/",
      UMSATZWAREGROUPTABLE:
        "get/accounting/monthlyReport/umsatzWaregroupTable/",
      UMSATZCASHIER: "get/accounting/monthlyReport/umsatzCashier/",
      UMSATZCASHIERTABLE: "get/accounting/monthlyReport/umsatzCashierTable/",
      CASHBOOK: "get/accounting/monthlyReport/cashBook/",
      PRINT: "get/print/monthlyReport/",
      PRINTA4: "get/accounting/monthlyReportA4Pdf/",
    },
    CASHBOOK: {
      GET: "get/accounting/cashBook/",
      GETENTRY: "get/accounting/cashBookEntry/",
      DELETE: "delete/accounting/cashBookEntry/",
      CREATE: "create/accounting/cashBookEntry/",
      UPDATE: "update/accounting/cashBookEntry/",
      LOCK: "update/accounting/cashBookLock/",
      UPDATETSEDATA: "update/accounting/cashBookEntry/fiscalData/",
      PRINTA4: "get/accounting/cashBookA4PDF/",
    },
    SEARCHINVOICE: {
      GET_PAYMENTTYPES: "get/accounting/paymentTypes/",
    },
    DISPLAYINVOICE: {
      GET: "get/accounting/invoiceData/",
      PRINTA4: "get/accounting/invoiceData4PDF/",
    },
    DISPLAYDELIVERY: {
      GET: "get/accounting/invoiceData/",
      PRINTA4: "get/accounting/deliveryData4PDF/",
    },
    SETTINGS: {},
    DATEV: {
      GET: "get/accounting/datev/",
      UPDATE: "update/accounting/datevSettings/",
      EXPORT: "",
      UPDATE_ONE_GROUP: "update/accounting/datevSettingsItemGroup/",
      SAPSETTINGS: {
        GET: "get/settings/sapFtpSettings/",
        UPDATE: "update/settings/sapFtpSettings/",
      },
    },
    INVOICE: {
      PAID: "update/accounting/unpaidInvoiceDate/",
    },
    WAITERSALES: {
      PRINTA4: "get/accounting/operatorReportA4Pdf/",
    },
    LAST_USER_INVOICE: "get/accounting/lastUserInvoice/",
  },
  DASHBOARD: {
    TOPITEMS: "get/dashboard/topTenItemsDaily/",
    OUTOFSTOCK: "get/dashboard/outofstock/",
  },
  TIMESHEET: {
    TIMETRACKING: {
      SHIFTINFO: "get/timeTrack/timeTrackShiftInfo/",
      CONTINUE: "update/timeTrack/continueTimetrackShift/",
      PAUSE: "update/timeTrack/pauseTimetrackShift/",
      STOP: "update/timeTrack/endTimetrackShift/",
      START: "create/timeTrack/startTimetrackShift/",
    },
    DAILYTIMECARD: {
      CREATE: "create/timeTrack/createCardEntry/",
      NEXT: "get/settings/nextUserID/",
    },
    MONTHLYTIMECARD: {
      MONTH: "get/timeTrack/timeCardMonthlyUser/",
      UPDATESHIFT: "update/timeTrack/shiftEntry/",
      COMMITSHIFT: "update/timeTrack/commitShift/",
    },
    ANALITICS: {
      USERS: "get/timeTrack/monthlyUserOverviewTable/",
      EXCELSINGEL: "get/reports/timeTrack/timeCardMonthlyUser/",
      PDFSINGL: "get/timeTrack/timeTrackUserReportPDF/",
      HISTORY: "get/timeTrack/monthlyUserHistory/",
      SENDEMAIL: "create/emails/timeTrackReport/",
    },
  },
  ANALYTICS: {
    WARE: {
      TOPWARES: "get/analytics/wareanalytics/topwares/",
      ARTICLEGROUPS: "get/analytics/wareanalytics/topwaregroups/",
      VOUCHERGIFTCARD: "get/analytics/wareanalytics/voucherGiftcard/",
      ORDERS: "get/analytics/wareanalytics/sales/",
      CATEGORIES: "get/analytics/wareanalytics/byType/",
      salesByItemgroupProducer:
        "get/analytics/wareanalytics/salesByItemgroupProducer/",
      MARGINS: {
        ITEMS: "get/analytics/wareanalytics/itemMargins/",
        ITEMGROUPS: "get/analytics/wareanalytics/itemGroupsMargins/",
      },
      CANCELLATION: {
        IMMEDIATE: "get/analytics/wareanalytics/immediateCancellation/",
        INVOICE: "get/analytics/wareanalytics/voids/",
      },
      GARNISH: "get/analytics/wareanalytics/garnishSaleReport/",
      EXPORT: {
        EXCEL: {
          IMMEDIATECANCELLATION: "get/analytics/excel/lineVoids/",
          VOIDCANCELLATION: "get/analytics/excel/voids/",
          SALESBYTYPE: "get/analytics/excel/salesByItemType/",
          ARTICLEGROUPS: "get/analytics/excel/salesPerWaregroup/",
          TOPWARES: "get/analytics/excel/topProducts/",
          ITEMMARGINS: "get/analytics/excel/itemMargins/",
          ITEMGROUPMARGINS: "get/analytics/excel/itemGroupMargins/",
          VOUCHERGIFTCARD: "get/analytics/excel/voucherGiftCard/",
          GARNISH: "get/reports/sales/garnishSaleReport/",
        },
        PDF: {
          IMMEDIATECANCELLATION: "get/analytics/pdf/lineVoids/",
          SALESBYTYPE: "get/analytics/pdf/salesByItemType/",
          ARTICLEGROUPS: "get/analytics/pdf/salesPerWaregroup/",
          TOPWARES: "get/analytics/pdf/topProducts/",
          ITEMMARGINS: "get/analytics/pdf/itemMargins/",
          ITEMGROUPMARGINS: "get/analytics/pdf/itemGroupMargins/",
          VOIDCANCELLATION: "get/analytics/pdf/voids/",
          VOUCHERGIFTCARD: "get/analytics/pdf/voucherGiftCard/",
        },
      },
    },
    ORDERS: "get/analytics/wareanalytics/sales/",
  },
  MIGRATE: {
    INFO: "migration/migrationInfo.php",
    SALES: "migration/sales.php",
    DAILY: "migration/dailyReports.php",
    MONTHLY: "migration/monthlyReports.php",
    ITEMSUPPLIERS: "migration/migrateSupplierStockControl.php",
    CASHBOOK: "migration/cashBook.php",
    PRINTER: "migration/printers.php",
    EXTRAITEMGROUPS: "migration/extrasItemGroups.php",
    EXTRAITEMS: "migration/extrasItems.php",
    FREETEXT: "migration/freeTextTags.php",
  },
  HYBRIDSTATE: {
    INFO: "get/hybridStatus/",
  },
  BARCODES: {
    CREATE: "create/barcodes/",
    HELP: "create/barcodes/help/",
  },
  SUPPORT: {
    ACCESS: "create/supportAccess/",
  },
  EMAILS: {
    ITEMORDERS: "create/emails/itemOrders/",
    INVOICES: "create/emails/invoices/",
    SUPPORT: "create/emails/help/",
    RESERVATIONS: {
      CONTACTS: "create/emails/reservations/contacts/",
      EMAILTEST: "create/emails/testReservation/",
    },
    AFTERSALE: "create/emails/emailAfterSale/",
  },
  DIRMEIER: {
    SETTINGS: {
      GET: "get/settings/dirmeierSettings/",
      UPDATE: "update/settings/dirmeierSettings/",
    },
  },
  HS3: {
    SEARCH: "get/hs3/search/",
    OCCUPATION: "get/hs3/occupation/",
    SETTINGS: {
      GET: "get/hs3/settings/",
      UPDATE: "update/hs3/settings/",
    },
    SYNCWARES: "get/hs3/syncWares/",
    LOG: "get/hs3/log/",
  },
  IBELSA: {
    ROOMS: {
      GET: "get/ibelsa/rooms/",
    },
    SEARCH: "get/ibelsa/search/",
    SETTINGS: {
      GET: "get/ibelsa/settings/",
      UPDATE: "update/ibelsa/settings/",
    },
  },
  SUITE8: {
    SEARCH: "get/suite8/search/",
    SETTINGS: {
      GET: "get/suite8/settings/",
      UPDATE: "update/suite8/settings/",
    },
    TESTAUTHENTICATION: "get/suite8/testAuthentication/",
  },
  STRATO: {
    SETTINGS: {
      GET: "get/settings/stratoEpages/",
      UPDATE: "update/settings/stratoEpages/",
    },
  },
  WOOCOMMERCE: {
    SETTINGS: {
      GET: "get/settings/woocommerceData/",
      UPDATE: "update/settings/woocommerceData/",
    },
  },
  SIHOT: {
    SEARCH: "get/sihot/search/",
    SEARCHVOUCHER: "get/sihot/searchVoucher/",
    SETTINGS: {
      GET: "get/sihot/settings/",
      UPDATE: "update/sihot/settings/",
    },
    DAYCLOSE: "create/sihot/dayClose/",
    OUTLETS: {
      GET: "get/sihot/outlets/",
      UPDATE: "update/sihot/outlets/",
    },
    CASHIERIDS: {
      GET: "get/sihot/cashierIDs/",
      UPDATE: "update/sihot/cashierIDs/",
    },
  },
};

export const KEYBOARD = {
  KEYSETS: {
    NORMAL: "normal",
    NUMERIC: "numeric",
  },
};
