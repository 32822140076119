//Price: 10.00 // Tax: 19.00
import { roundNumber } from "./round";

// Function to calculate the net price based on the gross price and tax value
export function getNetPrice(grossPrice, taxValue) {
  let total = 0;

  // Check if the tax value is less than 0.01, set it to 0.00
  if (Number(taxValue ?? 0) < 0.01) {
    taxValue = 0.0;
  }

  // Calculate the net price by dividing the gross price by (1 + tax value / 100)
  total = Number(grossPrice) / (1 + Number(taxValue) / 100);

  return total;
}

// Function to calculate the gross price based on the net price and tax value
export function getGrossPrice(netPrice, taxValue) {
  let total = 0;

  // Check if the tax value is less than 0.01, set it to 0.00
  if (Number(taxValue ?? 0) < 0.01) {
    taxValue = 0.0;
  }

  // Calculate the gross price by adding the net price and net price * (tax value / 100)
  total = Number(netPrice) + Number(netPrice) * (Number(taxValue) / 100);

  return total;
}

// Function to calculate the net price with rounding
export function calcNetPrice(grossPrice, taxValue, roundingPlaces = 2) {
  return roundNumber(getNetPrice(grossPrice, taxValue), roundingPlaces);
}

// Function to calculate the gross price with rounding
export function calcGrossPrice(netPrice, taxValue, roundingPlaces = 2) {
  return roundNumber(getGrossPrice(netPrice, taxValue), roundingPlaces);
}
