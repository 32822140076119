import axios from "axios";
import Vue from "vue";

export default {
    namespaced: true,

    state: {
        voidReasons: [],
    },

    mutations: {
        init(state, data) {
            state.voidReasons = data;
        },
        backgroundSync(state, data) {
            const transaction = data.transaction;
            const transactionType = data.type;

            if(transactionType === "INSERT") {
                //INSERT
                Vue.set(state.voidReasons, state.voidReasons.length, {
                    id: transaction.id,
                    name: transaction.name,
                    position: transaction.value,
                    color: transaction.color
                });
            }

            if(transactionType === "UPDATE") {
                //FIND
                let voidReason = state.voidReasons.find((voidReason) => {
                    if(voidReason.id === transaction.id) {
                        return true;
                    }
                });

                //UPDATE VOIDREASON
                if(voidReason) {
                    voidReason.name = transaction.name;
                    voidReason.position = transaction.position;
                    voidReason.color = transaction.color;
                }
            }

            if(transactionType === "DELETE") {
                //FIND
                let voidReason = state.voidReasons.find((voidReason) => {
                    if(voidReason.id === transaction.id) {
                        return true;
                    }
                });

                if(voidReason) {
                    let voidReasonIndex = state.voidReasons.indexOf(voidReason);

                    //DELETE VOIDREASON
                    Vue.delete(state.voidReasons, voidReasonIndex);
                }
            }
        },
    },

    actions: {
        init({commit}) {
            return axios.post('get/indexeddb/voidReasons/').then((res) => {
                let reasons = [];

                Array.from(res.data).forEach((voidReason) => {
                    reasons.push({
                        id: voidReason.voidReasonID,
                        position: voidReason.voidReasonsPosition,
                        name: voidReason.voidReasonsName,
                        color: voidReason.color
                    });
                });

                commit("init", reasons);
            });
        }
    }
};
