export let bookingRoutes = {
    meta: {
        menuTitle: ('generic.lang_reservation'),
        icon: 'pe-7s-ribbon',
        permission: {
            requiredModule: 12,
            requiredPermission: "",
            hybridDisplay: ""
        }
    },
    childs: [
        {
            path: '/reservations',
            name: 'reservations',
            meta: {
                siteTitle: ('generic.lang_reservation'),
                menuTitle: ('generic.lang_reservation'),
                layout: 'default',
                icon: 'pe-7s-ribbon',
                permission: {
                    requiredModule: 12,
                    requiredPermission: "reservation_createEditDisplay",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/reservation/reservations/')
        },
        {
            path: '/reservations/today',
            name: 'reservations.today',
            meta: {
                siteTitle: ('generic.lang_today'),
                menuTitle: ('generic.lang_today'),
                layout: 'default',
                icon: 'pe-7s-ribbon',
                permission: {
                    requiredModule: 12,
                    requiredPermission: "reservation_createEditDisplay",
                    hybridDisplay: ""
                }
            },
            component: () => import('@/views/reservation/reservations/TodayReservation.vue')
        },
        {
            path: '/reservation/archive',
            name: 'reservation.archive',
            meta: {
                siteTitle: 'settings.lang_reservationArchive',
                menuTitle: 'settings.lang_reservationArchive',
                layout: 'default',
                icon: 'pe-7s-box1',
                permission: {
                    requiredModule: 12,
                    requiredPermission: "reservation_archive",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/reservation/archive')
        },
        {
            header: false,
            path: '/reservation/settings',
            name: 'reservation.settings',
            meta: {
                siteTitle: ('settings.lang_reservationSettings'),
                menuTitle: ('settings.lang_reservationSettings'),
                layout: 'default',
                icon: 'pe-7s-settings',
                permission: {
                    requiredModule: 12,
                    requiredPermission: "reservation_settings",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/reservation/settings/')
        },
        {
            path: "/reservation/settings/type/create",
            name: "reservation.settings.type.create",
            childs: [],
            meta: {
                siteTitle: "erp.lang_inventoryItem",
                menuTitle: "erp.lang_inventoryItem",
                layout: "default",
                icon: "",
                hideInNavigation: true,
            },
            component: () => import("../views/reservation/settings/CreateType.vue"),
        },
        {
            path: "/reservation/settings/type/edit/:id",
            name: "reservation.settings.type.edit",
            childs: [],
            props: true,
            meta: {
                siteTitle: "erp.lang_inventoryItem",
                menuTitle: "erp.lang_inventoryItem",
                layout: "default",
                icon: "",
                hideInNavigation: true,
            },
            component: () => import("../views/reservation/settings/EditType.vue"),
        },
    ],
    groups: []
};
