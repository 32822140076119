import axios from "axios";
import Vue from "vue";

export default {
    namespaced: true,

    state: {
        devices: [],
        clients: []
    },

    mutations: {
        init(state, data) {
            state.devices = data.devices;
            state.clients = data.clients;
        },
        backgroundSyncDevices(state, payload) {
            const transaction = payload.transaction;
            const transactionType = payload.type;

            if(transactionType === "INSERT") {
                Vue.set(state.devices, state.devices.length, {
                    id: transaction.id,
                    type: transaction.type,
                    useTSEProxy: transaction.useTSEProxy,
                    TSEProxyIPAdress: transaction.TSEProxyIPAdress,
                    name: transaction.name,
                    description: transaction.description,
                    ip: transaction.ip,
                    port: transaction.port,
                    deviceID: transaction.deviceID,
                    puk: transaction.puk,
                    adminPIN: transaction.adminPIN,
                    timeAdminPIN: transaction.timeAdminPIN
                });
            }

            if(transactionType === "UPDATE") {
                let device = state.devices.find((device) => {
                    if(device.id === transaction.id) {
                        return true;
                    }
                });

                if(device) {
                    device.id = transaction.id;
                    device.type = transaction.type;
                    device.useTSEProxy = transaction.useTSEProxy;
                    device.TSEProxyIPAdress = transaction.TSEProxyIPAdress;
                    device.name = transaction.name;
                    device.description = transaction.description;
                    device.ip = transaction.ip;
                    device.port = transaction.port;
                    device.deviceID = transaction.deviceID;
                    device.puk = transaction.puk;
                    device.adminPIN = transaction.adminPIN;
                    device.timeAdminPIN = transaction.timeAdminPIN
                }
            }

            if(transactionType === "DELETE") {
                let device = state.devices.find((device) => {
                    if(device.id === transaction.id) {
                        return true;
                    }
                });

                if(device) {
                    let deviceIndex = state.devices.indexOf(device);

                    Vue.delete(state.devices, deviceIndex);
                }
            }
        },
        backgroundSyncClients(state, payload) {
            const transaction = payload.transaction;
            const transactionType = payload.type;

            if(transactionType === "INSERT") {
                Vue.set(state.clients, state.clients.length, {
                    id: transaction.id,
                    deviceID: transaction.deviceID,
                    name: transaction.name,
                    clientID: transaction.clientID,
                    cashierID: transaction.cashierID
                });
            }

            if(transactionType === "UPDATE") {
                let client = state.clients.find((client) => {
                    if(client.id === transaction.id) {
                        return true;
                    }
                });

                if(client) {
                    client.id = transaction.id;
                    client.deviceID = transaction.deviceID;
                    client.name = transaction.name;
                    client.clientID = transaction.clientID;
                    client.cashierID = transaction.cashierID;
                }
            }

            if(transactionType === "DELETE") {
                let client = state.clients.find((client) => {
                    if(client.id === transaction.id) {
                        return true;
                    }
                });

                if(client) {
                    let clientIndex = state.clients.indexOf(client);

                    Vue.delete(state.clients, clientIndex);
                }
            }
        }
    },

    actions: {
        init({commit}) {
            return axios.post('get/indexeddb/fiscal/').then((res) => {
                commit("init", {
                    devices: res.data.devices,
                    clients: res.data.clients
                });
            });
        }
    },

    getters: {
        fiscalClient: (state, getters, rootState) => {
            if(state.clients.length === 0)
                return null;

            let client = state.clients.find((client) => {
                return client.cashierID === rootState.api.auth.cashierID;
            });

            if(!client)
                return null;

            //FIND FISCAL DEVICE
            let device = state.devices.find((device) => {
                return device.id === client.deviceID;
            });

            if(!device)
                return null;

            //CLONE CLIENT
            let clientCopy = Object.assign({}, client);
            clientCopy.device = device;

            return clientCopy;
        }
    }
};
