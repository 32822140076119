export default {
    namespaced: true,

    state: {
        settings: {
            enabled: false
        },
    },

    mutations: {
        updateSettings(state, data) {
            state.settings = data;
        },
    },

    actions: {
        updateSettings({commit}, data) {
            commit('updateSettings', data);
        },
    },

    getters: {

    }
};
