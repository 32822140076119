<template>
  <v-menu bottom  min-width="300" transition="scroll-y-transition" nudge-bottom="70" >
    <template v-slot:activator="{on,attr}">
      <v-btn fab v-on="on" v-bind="attr" color="#e3e3e5" elevation="0" class="ma-0 mx-1" small>
        <v-avatar  color="transparent" left class="text-white" rounded size="30">
          O
        </v-avatar>
        <v-badge bottom  :content="orderCount" color="orange" :class="{blink_me:orderCount>0}" :value="orderCount"/>
      </v-btn>
    </template>
    <v-card >

      <div class="dropdown-menu-header">
        <div class="dropdown-menu-header-inner bg-plum-plate">
          <div class="menu-header-image dd-header-bg-1"></div>
          <div class="menu-header-content text-white">
            {{$t('generic.lang_online_shop_orders')}}
          </div>
        </div>
      </div>
      <div v-if="orders.length>0" class="grid-menu grid-menu-xl grid-menu-3col" >

      </div>
      <v-card-text v-else>
        <h5 class="text-center">{{$t('cashier.lang_noDeliveryOrders')}}</h5>
      </v-card-text>
    </v-card>
  </v-menu>

</template>

<script>
import {ENDPOINTS} from "../../../config";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faBallot,faBallotCheck,}from '@fortawesome/pro-regular-svg-icons';
import {
  faUserHeadset,
  faMapMarked,
  faBikingMountain,
  faBurgerSoda,
  faPrint,
  faReceipt,
  faCommentAltDots,
  faEdit,
  faTimesCircle,
  faRocketLaunch,
  faPersonCarry,
  faStopwatch,
  faBiking,
  faMapMarkerAlt,
  faHatChef,
  faKnifeKitchen,
  faWindowClose,
  faCheck, faFlagCheckered, faProjector, faBinoculars, faPizza, faSalad,
} from '@fortawesome/pro-light-svg-icons';
import {Events} from "../../../plugins/events";
import {mapState} from "vuex";
library.add(
    faUserHeadset,
    faMapMarked,
    faBikingMountain,
    faBurgerSoda,
    faPrint,
    faReceipt,
    faCommentAltDots,
    faEdit,
    faTimesCircle,
    faRocketLaunch,
    faPersonCarry,
    faStopwatch,
    faBiking,
    faUserHeadset,
    faMapMarkerAlt,
    faHatChef,
    faKnifeKitchen,
    faWindowClose,
    faCheck,
    faFlagCheckered,
    faProjector,
    faBinoculars,
    faPizza,
    faSalad,
    faBallot,
    faBallotCheck,
);
export default {
  name: "OnlineShopNotificationsComponent",
  components:{
    'font-awesome-icon': FontAwesomeIcon,
  },

  sockets: {
    "onlineShop.update": function (payload) {
      this.getOnlineShopOrders();
    },
  },

  data(){
    return{
      onlineDeliveryOrders:0,
      orderStatuses:[],
      loadingStatus:false,
      loadingOrders:false,
      orders:[],
      orderCount:0,
      sounds:[
        {
          id:0,
          name:this.$t('generic.lang_none'),
          src:'',
        },
        {
          id:1,
          name:"mixkit-bell-notification-933",
          src:require("@/assets/sounds/mixkit-bell-notification-933.wav"),
        },
        {
          id:2,
          name:"mixkit-clear-announce-tones-2861",
          src:require("@/assets/sounds/mixkit-clear-announce-tones-2861.wav"),
        },
        {
          id:3,
          name:"mixkit-correct-answer-reward-952",
          src:require("@/assets/sounds/mixkit-correct-answer-reward-952.wav"),
        },
        {
          id:4,
          name:"mixkit-doorbell-single-press-333",
          src:require("@/assets/sounds/mixkit-doorbell-single-press-333.wav"),
        },
        {
          id:5,
          name:"mixkit-happy-bells-notification-937",
          src:require("@/assets/sounds/mixkit-happy-bells-notification-937.wav"),
        },
        {
          id:6,
          name:"mixkit-magic-notification-ring-2344",
          src:require("@/assets/sounds/mixkit-magic-notification-ring-2344.wav"),
        },
        {
          id:7,
          name:"mixkit-musical-alert-notification-2309",
          src:require("@/assets/sounds/mixkit-musical-alert-notification-2309.wav"),
        },
        {
          id:8,
          name:"mixkit-sci-fi-reject-notification-896",
          src:require("@/assets/sounds/mixkit-sci-fi-reject-notification-896.wav"),
        },
        {
          id:9,
          name:"notification",
          src:require("@/assets/sounds/notification.mp3"),
        },
        {
          id:10,
          name:"notification2",
          src:require("@/assets/sounds/notification2.mp3"),
        },
        {
          id:11,
          name:"notification3",
          src:require("@/assets/sounds/notification3.mp3"),
        },
        {
          id:12,
          name:"notification4",
          src:require("@/assets/sounds/notification4.mp3"),
        },
        {
          id:13,
          name:"notification5",
          src:require("@/assets/sounds/notification5.mp3"),
        },
      ],

    }
  },
  computed:{
    ...mapState([
        "settings"
    ])
  },
  methods:{

    getOnlineShopOrders(){
      this.axios.post(ENDPOINTS.ONLINESHOP.ORDERS.COUNT, {

      }).then(res => {
        if (res.data.success) {
          this.orderCount = res.data.count
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        console.log(err)
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    playSound(){
      if(Number(this.settings.settings.notificationSoundID)){
        let sound=this.sounds.find(s=>Number(this.settings.settings.notificationSoundID) === s.id);
        if(sound){
          let audio = new Audio(sound.src);
          audio.play();
        }

      }
    }
  },
  beforeDestroy() {

  },
  mounted() {
    this.getOnlineShopOrders();
  },
}
</script>

<style scoped>

.blink_me {
  animation: blinker 2.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: .2;
  }
}

</style>