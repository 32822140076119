import store from "@/store";
import Vue from "vue";
import {geol} from "../../main.js";

// since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it

if(store.getters['settings/getSettingValue']('geoloc_systemcountry')){
    geol.locale = store.getters['settings/getSettingValue']('geoloc_systemcountry');
}

export function createVoidOrderBonPrintingData(items, data, printerOpts, globalFreetext, activeUser,isDup = false) {
    let printObj = [];

    // --- DATE ---
    let today = new Date(data.timestamp * 1000);
    let dd = today.getDate();
    let mm = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();
    let hours, minutes;

    // ITEM NAME TRANSLATION SETTINGS

    let enableOrderbonTranslations = Number(store.state.settings.settings.enableOrderbonTranslations);
    let printingTranslationType = Number(store.state.settings.settings.printingTranslationType);

    if (dd < 10) {
        dd = '0' + dd
    }

    if (mm < 10) {
        mm = '0' + mm
    }

    hours = today.getHours();
    minutes = today.getMinutes();

    if (minutes < 10) {
        minutes = "0" + minutes;
    }

    printObj.push({
        feed: 5
    });

    printObj.push({
        lineSpace: 30
    });

    printObj.push({
        font: "B"
    });

    printObj.push({
        align: "center"
    });

    printObj.push({
        fontSize: 3
    });
    // duplicate indicator
    if(isDup){
        printObj.push({
            reverse: 'on'
        });
        printObj.push({
            align: "centre"
        });

        printObj.push({
            text:geol.t('generic.lang_duplicate').toUpperCase(),
        });
        printObj.push({
            reverse: 'off'
        });
    }
    printObj.push({
        text: geol.t('generic.lang_void')
    });

    printObj.push({
        fontSize: 2
    });

    //CHECK IF CASHIER ALIAS NAME SHOULD BE SHOWN
    if(printerOpts.hasOwnProperty("showCashierAlias"))
        if(parseInt(printerOpts.showCashierAlias) === 1) {
            //GET CASHIER ALIAS NAME
            let cashier_alias = store.getters['api/auth/cashierAliasName'];

            printObj.push({
                text: cashier_alias
            });
        }

    printObj.push({
        reverse: 'on'
    });

    printObj.push({
        text: data.table+" / "+data.party
    });

    printObj.push({
        reverse: 'off'
    });

    printObj.push({
        align: "left"
    });

    // --- PRINT ITEMS ---
    printObj.push({
        text: "----------------------------"
    });

    items.forEach(function (item) {

        if(item.itemOrderingNameTranslationCanvas && enableOrderbonTranslations){
            if (printingTranslationType === 1) {
                printObj.push({
                    text: ("-") + (item.amount.toString() + "X "),
                });
                printObj.push({
                    image: item.itemOrderingNameTranslationCanvas,
                });
            } else {
                printObj.push({
                    text: ("-") + (item.amount.toString() + "X ").substr(0, 3).padEnd(3, ' ') + item.itemOrderingNameTranslation.substr(0, 15).padEnd(15, ' ')
                });

                if (item.name.length > 15) {
                    printObj.push({
                        text: "   " + item.itemOrderingNameTranslation.substr(15, 80)
                    });
                }
            }
        }else{
            printObj.push({
                text: ("-") + (item.amount.toString() + "X ").substr(0, 3).padEnd(3, ' ') + item.name.substr(0, 15).padEnd(15, ' ')
            });

            if (item.name.length > 15) {
                printObj.push({
                    text: "   " + item.name.substr(15, 80)
                });
            }
        }

            //EXTRAS
        if(item.selectedExtras.length > 0) {
            item.selectedExtras.forEach((extra) => {
                printObj.push({
                    text:  ("-").substr(0, 2).padEnd(2, ' ') + ("  "+extra.amount.toString() + "X ").substr(0, 53).padEnd(5, ' ') + extra.name.substr(0, 15).padEnd(15, ' ')
                });

                if (extra.name.length > 15) {
                    printObj.push({
                        text: "    " + extra.name.substr(15, 80)
                    });
                }
            });
        }

        //VOID REASON
        if(item.voidReason.length > 0) {
            printObj.push({
                fontSize: 1
            });

            printObj.push({
                text: '  '+item.voidReason
            });

            printObj.push({
                fontSize: 2
            });
        }
    });

    printObj.push({
        text: "----------------------------"
    });

    // FREETEXT
    if(globalFreetext !== null)
        if(globalFreetext.length > 3) {
            printObj.push({
                feed: 1
            });

            printObj.push({
                fontSize: 2
            });

            printObj.push({
                text: globalFreetext
            });

            printObj.push({
                feed: 1
            });
        }

    printObj.push({
        feed: 2
    });

    printObj.push({
        font: "B"
    });

    printObj.push({
        fontSize: 1
    });

    printObj.push({
        text: geol.t('generic.lang_date')+" / "+geol.t('generic.lang_time')+": "+dd + "." + mm + "." + yyyy+" - "+hours + ":" + minutes
    });

    //CHECK IF WAITER NAME SHOULD BE PRINTED
    let waiterPrintText = geol.t('generic.lang_waiter')+': '+data.userID;

    if(printerOpts.hasOwnProperty("showCashierAlias"))
        if(parseInt(printerOpts.showCashierAlias) === 1)
        waiterPrintText = geol.t('generic.lang_waiter')+': '+activeUser.name+" "+activeUser.last_name+" ("+activeUser.userID+")";

    printObj.push({
        text: waiterPrintText
    });

    printObj.push({
        feed: 2
    });

    printObj.push({
        cut: true
    });

    return printObj;
}