export let replicationRoutes = {
  meta: {
    menuTitle: ('generic.lang_nav_pos_replication'),
    icon: "pe-7s-copy-file",
    permission: {
      requiredModule: 68,
      requiredPermission: "replication_management",
      hybridDisplay: "Server",
    },
  },
  childs: [
    {
      path: "/posReplication/templates",
      name: "posReplication.templates",
      meta: {
        siteTitle: ('generic.lang_replication_templates'),
        menuTitle: ('generic.lang_replication_templates'),
        layout: "default",
        icon: "pe-7s-pen",
        permission: {
          requiredModule: 68,
          requiredPermission: "replication_management",
          hybridDisplay: "Server",
        },
      },
      component: () =>
        import("../views/posReplication/ReplicationTemplateView"),
    },
    {
      path: "/posReplication/templates/create",
      name: "posReplication.templates.create",
      meta: {
        hideInNavigation: true,
        layout: "default",
        icon: "lnr-users",
        permission: {
          requiredModule: 68,
          requiredPermission: "replication_management",
          hybridDisplay: "Server",
        },
      },
      component: () =>
        import("../views/posReplication/CreateReplicationTemplateView"),
    },
    {
      path: "/posReplication/templates/edit/:id",
      name: "posReplication.templates.edit",
      meta: {
        hideInNavigation: true,
        layout: "default",
        icon: "lnr-users",
        permission: {
          requiredModule: 68,
          requiredPermission: "replication_management",
          hybridDisplay: "Server",
        },
      },
      component: () =>
        import("../views/posReplication/EditReplicationTemplateView"),
    },
    {
      path: "/posReplication/assign-replication-group",
      name: "posReplication.assign.replication.group",
      meta: {
        siteTitle: ('generic.lang_assign_replication_group'),
        menuTitle: ('generic.lang_assign_replication_group'),
        layout: "default",
        icon: "pe-7s-box1",
        permission: {
          requiredModule: 68,
          requiredPermission: "replication_management",
          hybridDisplay: "Server",
        },
      },
      component: () => import("../views/posReplication/AssignTemplateView"),
    },
    {
      path: "/posReplication/settings",
      name: "posReplication.settings",
      meta: {
        siteTitle: "settings.lang_settings",
        menuTitle: "settings.lang_settings",
        layout: "default",
        icon: "pe-7s-config",
        permission: {
          requiredModule: 68,
          requiredPermission: "replication_management",
          hybridDisplay: "Server",
        },
      },
      component: () =>
        import("../views/posReplication/ReplicationSettingsView"),
    },
  ],
  groups: [],
};
