export let eventbeeRoutes = {
    meta: {
        menuTitle: 'EventBee',
        icon: 'pe-7s-ticket',
        permission: {
            requiredModule: 56, //use module 56 to activate eventbee
            requiredPermission: "",
            hybridDisplay: ""
        }
    },
    childs: [
        {
            path: '/eventbee/dashboard',
            name: 'eventbee.dashboard',
            meta: {
                siteTitle: ('generic.lang_dashboard'),
                menuTitle: ('generic.lang_dashboard'),
                layout: 'default',
                icon: 'pe-7s-graph',
                permission: {
                    requiredModule: 56,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/eventbee/EventsAnalytics'),
        },
        {
            path: '/eventbee/overview',
            name: 'eventbee.overview',
            meta: {
                siteTitle: ('generic.lang_overview'),
                menuTitle: ('generic.lang_overview'),
                layout: 'default',
                icon: 'pe-7s-paperclip',
                permission: {
                    requiredModule: 56,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/eventbee/EventsOverview'),
        },
        {
            path: '/eventbee/events',
            name: 'eventbee.events',
            meta: {
                siteTitle: ('eventbee.lang_events'),
                menuTitle: ('eventbee.lang_events'),
                layout: 'default',
                icon: 'pe-7s-ticket',
                permission: {
                    requiredModule: 56,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/eventbee/Events')
        },
        {
            path: '/eventbee/orders',
            name: 'eventbee.orders',
            meta: {
                siteTitle: ('generic.lang_orders'),
                menuTitle: ('generic.lang_orders'),
                layout: 'default',
                icon: 'pe-7s-cart',
                permission: {
                    requiredModule: 56,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/eventbee/EventOrders'),
        },
        {
            path: '/eventbee/refund-and-cancellation',
            name: 'eventbee.refundAndCancellation',
            meta: {
                siteTitle: ('eventbee.lang_refundAndCancelation'),
                menuTitle: ('eventbee.lang_refundAndCancelation'),
                layout: 'default',
                icon: 'pe-7s-cart',
                permission: {
                    requiredModule: 56,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/eventbee/RefundAndCancellation'),
        },
        {
            path: '/eventbee/setting',
            name: 'eventbee.setting',
            meta: {
                siteTitle: ('settings.lang_settings'),
                menuTitle: ('settings.lang_settings'),
                layout: 'default',
                icon: 'pe-7s-config',
                permission: {
                    requiredModule: 56,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/eventbee/settings/EventbeeSettings')
        },
        {
            path: '/eventbee/seatmap-editor/:uuid',
            name: 'eventbee.seatmap.editor',
            meta: {
                siteTitle: ('eventbee.lang_seatmap'),
                menuTitle: ('settings.lang_seatmap'),
                layout: 'userpages',
                icon: 'pe-7s-keypad',
                hideInNavigation: true,
                permission: {
                    requiredModule: 56,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../components/eventbee/seatmap/SeatMapEditor.vue')
        }
    ],
    groups: [],
};