import axios from "axios";
import Vue from "vue";

export default {
    namespaced: true,

    state: {
        extraTabs: [],
    },

    mutations: {
        init(state, data) {
            state.extraTabs = data;
        },
        resetState(state, data) {
            state.extraTabs = [];
        },
        backgroundSync(state, payload) {
            const transaction = payload.transaction;
            const transactionType = payload.type;

            if (transactionType === "INSERT") {
                Vue.set(state.extraTabs, state.extraTabs.length, {
                    id: transaction.id,
                    layout: transaction.layout,
                    name: transaction.name,
                    maxChoose: transaction.maxChoose,
                    minChoose: transaction.minChoose,
                    position: transaction.position,
                    showInLocalBee: transaction.showInLocalBee,
                    showInPOS: transaction.showInPOS,
                    showInTableBee: transaction.showInTableBee,
                    color: transaction.color
                });
            }
            if (transactionType === "UPDATE") {
                let extraTab = state.extraTabs.find((extraTab) => {
                    if (extraTab.id === transaction.id) {
                        return true;
                    }
                });

                if (extraTab) {
                    extraTab.layout = transaction.layout;
                    extraTab.name = transaction.name;
                    extraTab.maxChoose = transaction.maxChoose;
                    extraTab.minChoose = transaction.minChoose;
                    extraTab.position = transaction.position;
                    extraTab.showInLocalBee = transaction.showInLocalBee;
                    extraTab.showInPOS = transaction.showInPOS;
                    extraTab.showInTableBee = transaction.showInTableBee;
                    extraTab.color = transaction.color;
                }
            }
            if (transactionType === "DELETE") {
                let extraTab = state.extraTabs.find((extraTab) => {
                    if (extraTab.id === transaction.id) {
                        return true;
                    }
                });
                if (extraTab) {
                    let extraTabIndex = state.extraTabs.indexOf(extraTab);
                    Vue.delete(state.extraTabs, extraTabIndex);
                }
            }
        }
    },

    actions: {
        init({commit}) {
            return axios.post('get/indexeddb/garnishTabs/',).then((res) => {
                commit("init", res.data);
            }).catch(() => {

            });
        }
    }
};
