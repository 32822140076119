/**
 * @description define all reusable functions in the whole project
 */
export default {
    methods: {
        /**
         * @param {any} arr
         * @return {boolean}
         */
        isEmptyArr(arr) {
            return Array.isArray(arr) && arr.length === 0;
        },
        /**
         * @param {any} arr
         * @return {boolean}
         */
        isIssetArr(arr) {
            return Array.isArray(arr) && arr.length > 0;
        },
        /**
         * @description check if given variable is an object that have at less one key
         * @param {any} obj
         * @return {boolean}
         */
        isValidObject(obj) {
            return obj !== null && (typeof obj === "object") && Object.keys(obj).length > 0;
        },
        formatPartei(name) {
            if (name && !Number.isNaN(parseInt(name)) && parseInt(name) > 0) {
                return "Partei " + name;
            } else return name
        },
        getB64ImageSize(base64String){
            let stringLength = (base64String?base64String:'').length - 'data:image/png;base64,'.length;

            let sizeInBytes = 4 * Math.ceil((stringLength / 3))*0.5624896334383812;
            let sizeInKb = sizeInBytes/1000;
            return sizeInKb/1000; // size in MB
        }
    }
};
