import axios from "axios";
import Vue from "vue";
export default {
    namespaced: true,

    state: {
        extras: [],
    },

    mutations: {
        init(state, data) {
            state.extras = data;
        },
        resetState(state,data){
            state.extras = [];
        },
        backgroundSync(state, payload) {
            const transaction = payload.transaction;
            const transactionType = payload.type;

            if(transactionType === "INSERT") {
                Vue.set(state.extras, state.extras.length, {
                    id: transaction.id,
                    name: transaction.name,
                    taxVal: transaction.taxVal,
                    productImage: transaction.productImage,
                    active: transaction.active
                });
            }
            if(transactionType === "UPDATE") {
                let garnish = state.extras.find((garnish) => {
                    if(garnish.id === transaction.id) {
                        return true;
                    }
                });

                if(garnish) {
                    garnish.name = transaction.name;
                    garnish.productImage = transaction.productImage;
                    garnish.active = transaction.active;
                }
            }
            if(transactionType === "DELETE") {
                let garnish = state.extras.find((garnish) => {
                    if(garnish.id === transaction.id) {
                        return true;
                    }
                });
                if(garnish) {
                    let garnishIndex = state.extras.indexOf(garnish);
                    Vue.delete(state.extras, garnishIndex);
                }
            }
        }
    },

    actions: {
        init({commit}) {
            return axios.post('get/indexeddb/garnish/',).then((res) => {
                commit("init", res.data);
            }).catch(() => {

            });
        }
    }
};
