<template>
<v-card :ripple="false" flat tile elevation="0" color="transparent" class="d-flex align-center"  >
  <div class="pa-1">
    <img :src="placeTelImg" height="40" width="40">
  </div>
  <!--
  <v-chip small  light close color="white" style="font-size: 0.6em;border-color: #E6E9F4 !important;border-width: 1px !important;border-style: solid !important;" class="font-weight-bold mr-1 px-">
    <v-badge
        dot
        inline
        left
        color="success"
    ></v-badge>
    0614032788
  </v-chip>
  <v-chip small light close color="white" style="font-size: 0.6em;border-color: #E6E9F4 !important;border-width: 1px !important;border-style: solid !important;" class="font-weight-bold mr-1 px-">
    <v-badge
        dot
        inline
        left
        color="success"
    ></v-badge>
    0614032788
  </v-chip>
  -->
  <v-chip  small light close color="white" style="font-size: 0.6em;border-color: #E6E9F4 !important;border-width: 1px !important;border-style: solid !important;" class="font-weight-bold mr-1 px-">
    <v-btn x-small :outlined="false" depressed fab class="ma-0 pa-0" color="transparent">
      <font-awesome-icon  :icon="['fal','reply']" class="pa-0 ma-0" style="font-size: 1.2em;color: red"/>
    </v-btn>
    0123456789
  </v-chip>
</v-card>
</template>

<script>
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faReply,
} from '@fortawesome/pro-light-svg-icons';
library.add(
    faReply,
)
export default {
  name: "PlaceTelComponent",
  components:{
    'font-awesome-icon':FontAwesomeIcon,
  },
  data(){
    return{
      placeTelImg:require('@/assets/images/delivery/placetel-logo.png'),
    }
  },
}
</script>

<style scoped>

</style>