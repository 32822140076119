<template>
  <v-menu bottom  min-width="300" transition="scroll-y-transition" nudge-bottom="70" >
    <template v-slot:activator="{on,attr}">
      <v-btn fab v-on="on" v-bind="attr" color="#e3e3e5" elevation="0" class="ma-0" small>
        <v-badge bottom :content="orders.length" color="orange" overlap :class="{blink_me:orders.length>0}" :value="orders.length>0">
            <v-avatar  color="transparent" left class="text-white" rounded size="30">
              <v-img src="@/assets/images/delivery/Local Bee orange.svg"/>
            </v-avatar>
        </v-badge>
      </v-btn>
    </template>
    <v-card >

      <div class="dropdown-menu-header">
        <div class="dropdown-menu-header-inner bg-plum-plate">
          <div class="menu-header-image dd-header-bg-1"></div>
          <div class="menu-header-content text-white">
            <v-btn @click="redirectToDelivery" dark color="primary" class="text-decoration-none">{{ $t('generic.lang_lieferdienst') }}<v-icon>mdi-link-variant</v-icon></v-btn>
          </div>
        </div>
      </div>
      <div v-if="orders.length>0" class="grid-menu grid-menu-xl grid-menu-3col" >
        <div class="no-gutters row">
          <div v-if="orders.length>0" class="col-12 border-right border-bottom" >
            <button class="btn-icon-vertical btn-square btn-transition btn btn-success" @click="openAll">
              {{ $t('generic.lang_acceptAll') }}
            </button>
          </div>
          <v-progress-linear color="purple" indeterminate v-if="loadingStatus" />
          <div class="col-sm-6 col-xl-4" v-for="(status,index) in orderStatuses" :key="index">
            <button class="btn-icon-vertical btn-square btn-transition btn" style="display: grid;" @click="setOrdersStatus(status)">
              <font-awesome-icon class="mx-auto my-1" :icon="['fal',status.icon?status.icon:'person-carry']"
                                 :style="{color:status.color,fontSize:'30px'}" align="center"/>
              {{ status.name }}
            </button>
          </div>
        </div>
      </div>
      <v-card-text v-else>
        <h5 class="text-center">{{$t("delivery.lang_noOrdersYet")}}</h5>
      </v-card-text>
    </v-card>

    <PrintOrderBonComponent ref="orderbonPrint" no-loader/>
  </v-menu>

</template>

<script>
import {ENDPOINTS} from "../../../config";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {faBallot,faBallotCheck,}from '@fortawesome/pro-regular-svg-icons';
import {
  faUserHeadset,
  faMapMarked,
  faBikingMountain,
  faBurgerSoda,
  faPrint,
  faReceipt,
  faCommentAltDots,
  faEdit,
  faTimesCircle,
  faRocketLaunch,
  faPersonCarry,
  faStopwatch,
  faBiking,
  faMapMarkerAlt,
  faHatChef,
  faKnifeKitchen,
  faWindowClose,
  faCheck, faFlagCheckered, faProjector, faBinoculars, faPizza, faSalad,
} from '@fortawesome/pro-light-svg-icons';
import {Events} from "../../../plugins/events";
import PrintOrderBonComponent from "../../../components/delivery/PrintOrderBonComponent";
import {mapState} from "vuex";
library.add(
    faUserHeadset,
    faMapMarked,
    faBikingMountain,
    faBurgerSoda,
    faPrint,
    faReceipt,
    faCommentAltDots,
    faEdit,
    faTimesCircle,
    faRocketLaunch,
    faPersonCarry,
    faStopwatch,
    faBiking,
    faUserHeadset,
    faMapMarkerAlt,
    faHatChef,
    faKnifeKitchen,
    faWindowClose,
    faCheck,
    faFlagCheckered,
    faProjector,
    faBinoculars,
    faPizza,
    faSalad,
    faBallot,
    faBallotCheck,
);
export default {
  name: "HeaderDeliveryOrdersComponent",
  components:{
    PrintOrderBonComponent,
    'font-awesome-icon': FontAwesomeIcon,
  },
  sockets: {
    "delivery.orderCreated": function (payload) {
      if(Object.keys(payload?payload:{}).length<1){
        return;
      }
      this.playSound();
      this.checkOnlineDeliveryOrders().then(()=>{
        Events.$emit("showSnackbar", {
          color: "success",
          message: this.$t('delivery.lang_newLocalbeeOrderText')
        });
      })
    },
    "delivery.refreshOrders": function (payload) {
      this.checkOnlineDeliveryOrders()
    },
  },

  data(){
    return{
      onlineDeliveryOrders:0,
      orderStatuses:[],
      loadingStatus:false,
      loadingOrders:false,
      orders:[],
      sounds:[
        {
          id:0,
          name:this.$t('generic.lang_none'),
          src:'',
        },
        {
          id:1,
          name:"mixkit-bell-notification-933",
          src:require("@/assets/sounds/mixkit-bell-notification-933.wav"),
        },
        {
          id:2,
          name:"mixkit-clear-announce-tones-2861",
          src:require("@/assets/sounds/mixkit-clear-announce-tones-2861.wav"),
        },
        {
          id:3,
          name:"mixkit-correct-answer-reward-952",
          src:require("@/assets/sounds/mixkit-correct-answer-reward-952.wav"),
        },
        {
          id:4,
          name:"mixkit-doorbell-single-press-333",
          src:require("@/assets/sounds/mixkit-doorbell-single-press-333.wav"),
        },
        {
          id:5,
          name:"mixkit-happy-bells-notification-937",
          src:require("@/assets/sounds/mixkit-happy-bells-notification-937.wav"),
        },
        {
          id:6,
          name:"mixkit-magic-notification-ring-2344",
          src:require("@/assets/sounds/mixkit-magic-notification-ring-2344.wav"),
        },
        {
          id:7,
          name:"mixkit-musical-alert-notification-2309",
          src:require("@/assets/sounds/mixkit-musical-alert-notification-2309.wav"),
        },
        {
          id:8,
          name:"mixkit-sci-fi-reject-notification-896",
          src:require("@/assets/sounds/mixkit-sci-fi-reject-notification-896.wav"),
        },
        {
          id:9,
          name:"notification",
          src:require("@/assets/sounds/notification.mp3"),
        },
        {
          id:10,
          name:"notification2",
          src:require("@/assets/sounds/notification2.mp3"),
        },
        {
          id:11,
          name:"notification3",
          src:require("@/assets/sounds/notification3.mp3"),
        },
        {
          id:12,
          name:"notification4",
          src:require("@/assets/sounds/notification4.mp3"),
        },
        {
          id:13,
          name:"notification5",
          src:require("@/assets/sounds/notification5.mp3"),
        },
      ],

    }
  },
  computed:{
    ...mapState([
        "settings",
        "pos"
    ])
  },
  methods:{
    redirectToDelivery(){
      if (this.pos.gastro.table && Number(this.pos.gastro.table.name) !== 0) {
        this.$socket.emit("tableplan.leaveTable", this.pos.gastro.table.name);
      }
      this.$router.replace('/pos/delivery')
    },
    async checkOnlineDeliveryOrders(){
        this.axios.post(ENDPOINTS.DELIVERY.ORDERS.GETUNOPENED).then((res) => {
          if (res.data.success) {
            this.orders = res.data.orders
          }
        })
    },
    openAll(){
      if(this.orders.length<1){
        return;
      }
      this.axios.post(ENDPOINTS.DELIVERY.ORDER.OPEN.UPDATEALL).then(res => {
        if (res.data.success) {
          this.$root.$emit('updateOrders')
          this.$refs.orderbonPrint.printOrderBons(this.orders).finally(()=>{
            this.checkOnlineDeliveryOrders();
            this.$socket.emit('delivery.refreshOrders')
          })
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingOpenAll=false;
      })
    },
    async getOrderStatuses() {
      this.orderStatuses=[];
      this.loadingStatus=true;
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.DELIVERYSTATUS.GETACTIVE, {}).then(res => {
        if (res.data.status === 'SUCCESS') {
          this.orderStatuses=res.data.data;
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingStatus=false;
      })
    },
    setOrdersStatus(status){
      this.axios.post(ENDPOINTS.DELIVERY.ORDER.STATUS.UPDATEALLUNOPENED, {
        statusID:status.id,
      }).then(res => {
        if (res.data.success) {

          this.$root.$emit('updateOrders')
          this.$refs.orderbonPrint.printOrderBons(this.orders).finally(()=>{
            this.checkOnlineDeliveryOrders();
            this.$socket.emit('delivery.refreshOrders');
          })
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_statusUpdated'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + ' ' + err.message,
          color: "error"
        });
      }).finally(() => {
      })
    },
    playSound(){
      if(Number(this.settings.settings.notificationSoundID)){
        let sound=this.sounds.find(s=>Number(this.settings.settings.notificationSoundID) === s.id);
        if(sound){
          let audio = new Audio(sound.src);
          audio.play();
        }

      }
    }
  },
  beforeDestroy() {
    this.$root.$off('updateOrders');
  },
  mounted() {
    this.getOrderStatuses();
    this.checkOnlineDeliveryOrders();
    this.$root.$on('updateOrders',()=>{
      this.checkOnlineDeliveryOrders();
    })
  },
}
</script>

<style scoped>

.blink_me {
  animation: blinker 2.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: .2;
  }
}

</style>