export let accountingRoutes = {
    meta: {
        menuTitle: ('accounting.lang_nav_accounting'),
        icon: 'lnr-book',
    },
    childs: [
        /* {
             header: false,
             path: '/accounting/calendar',
             name: 'accounting.calendar',
             meta: {
                 siteTitle: ('generic.lang_calendar'),
                 menuTitle: ('generic.lang_calendar'),
                 layout: 'default',
                 icon: 'pe-7s-date',
                 permission: {
                     requiredModule: 3,
                     requiredPermission: "display_calender",
                     hybridDisplay: ""
                 }
             },
             component: () => import('../views/accounting/Calendar.vue'),
         },*/
        {
            header: false,
            path: '/accounting/SearchInvoices',
            name: 'accounting.SearchInvoices',
            meta: {
                siteTitle: ('accounting.lang_invoices'),
                menuTitle: ('accounting.lang_invoices'),
                layout: 'default',
                icon: 'pe-7s-search',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "accounting_searchbill",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/accounting/SearchInvoices')
        },
        {
            header: false,
            path: '/accounting/canteenbilling',
            name: 'accounting.canteenbilling',
            meta: {
                siteTitle: "accounting.lang_canteenBilling",
                menuTitle: "accounting.lang_canteenBilling",
                layout: 'default',
                icon: 'pe-7s-note2',
                permission: {
                    requiredModule: 41,
                    requiredPermission: "accounting_searchbill",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/accounting/canteenbilling/CanteenBilling')
        },
        {
            header: false,
            path: '/accounting/DisplayInvoice/:id',
            name: 'accounting.DisplayInvoice',
            props: true,
            meta: {
                hideInNavigation: true,
                siteTitle: ('accounting.lang_invoices'),
                menuTitle: ('accounting.lang_invoices'),
                layout: 'default',
                icon: 'pe-7s-help2',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "accounting_searchbill",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/accounting/DisplayInvoice')
        },
        {
            header: false,
            path: '/accounting/cashierOverview',
            name: 'accounting.cashierOverview',
            meta: {
                siteTitle: ('accounting.lang_accounting_cashierOverview'),
                menuTitle: ('accounting.lang_accounting_cashierOverview'),
                layout: 'default',
                icon: 'pe-7s-wallet',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "accounting_cashierOverview",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/accounting/CashierOverview.vue'),
        },//start of daily reports routes
        {
            header: false,
            path: '/accounting/dailyReport',
            name: 'accounting.dailyReport',
            meta: {
                siteTitle: ('accounting.lang_nav_accounting_dailyab'),
                menuTitle: ('accounting.lang_nav_accounting_dailyab'),
                layout: 'default',
                icon: 'pe-7s-moon',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "dailyReport",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/accounting/dailyreports/DailyReport')
        },
        {
            header: false,
            path: '/accounting/dailyReport/details/:id',
            name: 'accounting.dailyReport.details',
            meta: {
                hideInNavigation: true,
                layout: 'default',
                icon: '',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "dailyReport",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/accounting/dailyreports/DailyReportDetails')
        },
        //end of daily reports routes
        //start of monthly report
        {
            header: false,
            path: '/accounting/monthlyReport',
            name: 'accounting.monthlyReport',
            meta: {
                siteTitle: ('accounting.lang_nav_accounting_monthlyab'),
                menuTitle: ('accounting.lang_nav_accounting_monthlyab'),
                layout: 'default',
                icon: 'pe-7s-date',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "monthlyReport",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/accounting/monthlyreport/MonthlyReport')
        },
        {
            header: false,
            path: '/accounting/monthlyReport/details/:id',
            name: 'accounting.monthlyReport.details',
            meta: {
                hideInNavigation: true,
                layout: 'default',
                icon: 'pe-7s-date',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "monthlyReport",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/accounting/monthlyreport/MonthlyReportDetails')
        },

        //start of waiter sales
        {
            header: false,
            path: '/accounting/waiter-sales',
            name: 'accounting.waiter.sales',
            meta: {
                siteTitle: ('accounting.lang_clerkSales'),
                menuTitle: ('accounting.lang_clerkSales'),
                layout: 'default',
                icon: 'pe-7s-moon',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "clerkSales",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/accounting/user/WaiterSalesView')
        },
        //end of waiter sales

        //start of waiter tip
        {
            header: false,
            path: '/accounting/waiter-tips',
            name: 'accounting.waiter.tips',
            meta: {
                siteTitle: ('accounting.lang_tipOverview'),
                menuTitle: ('accounting.lang_tipOverview'),
                layout: 'default',
                icon: 'pe-7s-moon',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "tipOverview",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/accounting/user/WaiterTipsView')
        },

        //end of waiter tip
        //start of monthly report

        {
            header: false,
            path: '/accounting/DisplayUnpaidInvoice/:id',
            name: 'accounting.DisplayUnpaidInvoice',
            props: true,
            meta: {
                hideInNavigation: true,
                siteTitle: ('accounting.lang_nav_accounting_openreceipt'),
                menuTitle: ('accounting.lang_nav_accounting_openreceipt'),
                layout: 'default',
                icon: 'pe-7s-help2',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "unpaidInvoices",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/accounting/DisplayUnpaidInvoice')
        },
        {
            header: false,
            path: '/accounting/cashBook',
            name: 'accounting.cashBook',
            meta: {
                siteTitle: ('accounting.lang_nav_accounting_cashbook'),
                menuTitle: ('accounting.lang_nav_accounting_cashbook'),
                layout: 'default',
                icon: 'pe-7s-notebook',
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 18,
                    requiredPermission: "accounting_displayCashbook",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/accounting/Cashbook.vue')
        },
        {
            header: false,
            path: '/accounting/unpaidInvoices',
            name: 'accounting.unpaidInvoices',
            meta: {
                siteTitle: ('accounting.lang_nav_accounting_openreceipt'),
                menuTitle: ('accounting.lang_nav_accounting_openreceipt'),
                layout: 'default',
                icon: 'pe-7s-bookmarks',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "unpaidInvoices",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/accounting/UnpaidInvoices.vue')
        },
        {
            header: false,
            path: '/accounting/retourOverview',
            name: 'accounting.retourOverview',
            meta: {
                siteTitle: ('accounting.lang_nav_accounting_retouroverview'),
                menuTitle: ('accounting.lang_nav_accounting_retouroverview'),
                layout: 'default',
                icon: 'pe-7s-repeat',
                permission: {
                    requiredModule: 0,
                    requiredPermission: "accounting_searchbill",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/accounting/RetourOverview')
        },
    ],
    groups: [
        {
            meta: {
                menuTitle: ('accounting.lang_nav_collectivebill'),
                icon: 'pe-7s-plugin',
                permission: {}
            },
            childs: [
                {
                    header: false,
                    path: '/billing/dailycollectivebill',
                    name: 'billing.dailycollectivebill',
                    meta: {
                        siteTitle: ('accounting.lang_nav_daily_collectivebill'),
                        menuTitle: ('accounting.lang_nav_daily_collectivebill'),
                        layout: 'default',
                        icon: 'pe-7s-folder',
                        permission: {
                            requiredModule: 4,
                            requiredPermission: "",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/billing/DailyCollectiveBillView')
                },
                {
                    header: false,
                    path: '/billing/collectivebill',
                    name: 'billing.collectivebill',
                    meta: {
                        siteTitle: ('accounting.lang_nav_collectivebill'),
                        menuTitle: ('accounting.lang_nav_collectivebill'),
                        layout: 'default',
                        icon: 'pe-7s-folder',
                        permission: {
                            requiredModule: 4,
                            requiredPermission: "",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/billing/CollectiveBill')
                },
                {
                    header: false,
                    path: '/billing/collectivebill/:startDate/:endDate/:customer_id',
                    name: 'billing.collectivebill.day',
                    params: true,
                    meta: {
                        siteTitle: ('accounting.lang_nav_collectivebill'),
                        menuTitle: ('accounting.lang_nav_collectivebill'),
                        layout: 'default',
                        icon: 'pe-7s-folder',
                        hideInNavigation: true,
                        permission: {
                            requiredModule: 4,
                            requiredPermission: "",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/billing/CollectiveBillDay')
                },
                {
                    path: '/accounting/debitinvoice/unpaid',
                    name: 'accounting.debitinvoice.unpaid',
                    meta: {
                        siteTitle: ('accounting.lang_nav_accounting_openreceipt'),
                        menuTitle: ('accounting.lang_nav_accounting_openreceipt'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 4,
                            requiredPermission: "accounting_cashBookPostingRecords",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/accounting/debitinvoice/UnpaidDebitInvoices.vue')
                },
                {
                    path: '/accounting/debitinvoice/invoices',
                    name: 'accounting.debitinvoice/invoices',
                    meta: {
                        siteTitle: ('accounting.lang_invoices'),
                        menuTitle: ('accounting.lang_invoices'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 4,
                            requiredPermission: "accounting_cashBookPostingRecords",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/accounting/debitinvoice/DebitInvoices.vue')
                },
            ]
        },
        {
            meta: {
                menuTitle: ('settings.lang_settings'),
                icon: 'pe-7s-plugin',
                permission: {}
            },
            childs: [
                {
                    path: '/accounting/settings/cashBookPostingRecords',
                    name: 'accounting.settings.cashBookPostingRecords',
                    meta: {
                        siteTitle: ('accounting.lang_buchhungsaetze'),
                        menuTitle: ('accounting.lang_buchhungsaetze'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 18,
                            requiredPermission: "accounting_cashBookPostingRecords",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/accounting/CashBookPostingRecords.vue')
                },
                {
                    path: '/accounting/settings/tax',
                    name: 'accounting.settings.tax',
                    meta: {
                        siteTitle: ('erp.lang_nav_taxrates'),
                        menuTitle: ('erp.lang_nav_taxrates'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 0,
                            requiredPermission: "accounting_displayTaxRates",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/erp/settings/TaxSetup.vue')
                },
            ]
        },
        {
            meta: {
                menuTitle: ('accounting.lang_nav_accountingDatev'),
                icon: 'pe-7s-plugin',
                permission: {requiredModule: 25}
            },
            childs: [
                {
                    path: '/accounting/datev/interface',
                    name: 'accounting.datev.interface',
                    meta: {
                        siteTitle: ('accounting.lang_nav_accounting_datevinterface'),
                        menuTitle: ('accounting.lang_nav_accounting_datevinterface'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 25,
                            requiredPermission: "datevConfig",
                            hybridDisplay: "Server"
                        }
                    },
                    component: () => import('../views/accounting/datev/DatevConfig')
                },
                {
                    path: '/accounting/datev/export',
                    name: 'accounting.datev.export',
                    meta: {
                        siteTitle: ('accounting.lang_accounting_displayDatev'),
                        menuTitle: ('accounting.lang_accounting_displayDatev'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 25,
                            requiredPermission: "datevDownload",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/accounting/datev/DatevExport.vue')
                },
                {
                    path: '/accounting/datev/sapreport',
                    name: 'accounting.datev.sapreport',
                    meta: {
                        siteTitle: ('accounting.lang_accounting_sapSettings'),
                        menuTitle: ('accounting.lang_accounting_sapSettings'),
                        layout: 'default',
                        icon: '',
                        permission: {
                            requiredModule: 25,
                            requiredPermission: "sapFTPSettings",
                            hybridDisplay: ""
                        }
                    },
                    component: () => import('../views/accounting/sapreport/SAPReport')
                },
            ]
        }
    ]
};
