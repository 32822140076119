/**
 * @description this mixin allow to print interim bill
 * @see src/components/pos/buttons/ButtonsPrintInterimBill.vue
 * @author Omar IZEM
 */

import {mapGetters, mapState} from "vuex";
import {createInvoicePrintingData} from "@/plugins/printing/printInvoice";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {printDataFromPrinter} from "../../../plugins/printing/printerController";

export default {
    data() {
        return {
            targetService: null
        }
    },
    computed: {
        ...mapState([
            'pos',
            'api',
            'user',
            'settings',
            'posLayoutTemplates'
        ]),
        ...mapState('printer', {
            printers: state => state.printers
        }),
        ...mapGetters({
            activeUser: 'multiUser/activeUser',
        }),
        /**
         * find assigned printer to the current cashier
         * @return {*}
         */
        invoicePrinter() {
            return this.printers.find((printer) => {
                if (printer.cashierID.includes(this.api.auth.cashierID)) {
                    if (printer.type === 1) {
                        return true;
                    }
                }
            });
        },
        /**
         * check if multi user mode is enabled in the POS
         * @return {boolean|*}
         */
        multiUser() {
            if (this.settings.settings.hasOwnProperty("posGastroLayoutTemplate")) {
                //FIND TEMPLATE
                let templateID = 0;
                templateID = parseInt(this.settings.settings.posGastroLayoutTemplate);

                if (templateID > 0) {
                    return this.posLayoutTemplates.templates.some(template => {
                        if (templateID === template.id) {
                            return (template.multiUser === 1 || template.multiUser === true)
                        }
                    });
                }
            }

            return false;
        },
        /**
         * get activated user id in the multi user mode
         * @return {number|*}
         */
        activeUserID() {
            if (this.multiUser)
                if (this.activeUser.userID !== null)
                    return parseInt(this.activeUser.userID);
            return this.user.userID;
        },
        /**
         *
         * @return {{avatarURI: string, name: string, last_name: string, userID: null}|{mutations: {setFullscreen(*, *): void, login(*, *): void, clearData(*): void}, state: {avatarURI: string, fullscreen: boolean, loggedIn: boolean, name: string, last_name: string, userID: number}, getters: {getFullscreen(*): boolean, __loggedIn: function(*): boolean, user: function(*): {avatarURI: string, name: string, last_name: string, fullname: string, userID: number}}, actions: {logout({state: *, commit: *, rootState: *, rootGetters: *}, *): void, login({commit: *, dispatch: *, rootState: *}, *=): void, clearData({commit: *}): void}, namespaced: boolean}}
         */
        realUser() {
            if (this.multiUser)
                if (this.activeUser.userID !== null)
                    return this.activeUser;

            return this.user;
        },
        /**
         * get order items based on the service
         * @param order
         * @param service delivery, tablebee, eventbee ...
         * @return {*[]}
         */
        interimInvoiceItems() {
            return (order = null, service = null) => {
                let invoiceItems = [];

                //check if delivery
                if (this.isValidObject(order) && service === "delivery") {
                    if (order.hasOwnProperty("saleObj"))
                        return [...order.saleObj]
                    return [];
                }

                if (this.posType === "retail") {
                    invoiceItems = this.pos.retail.orderedItems;
                }
                
                if (this.posType === "wholesaleRetail") {
                    invoiceItems = this.pos.wholesaleRetail.orderedItems;
                }

                if (this.posType === "gastro") {
                    if (!this.pos.gastro.hasOwnProperty("table"))
                        return invoiceItems;

                    if (!this.pos.gastro.table.hasOwnProperty("name"))
                        return invoiceItems;

                    if (!this.pos.gastro.hasOwnProperty("party"))
                        return invoiceItems;

                    if (!this.pos.gastro.party)
                        return invoiceItems;

                    if (!this.pos.gastro.party.hasOwnProperty("name"))
                        return invoiceItems;

                    if (this.pos.gastro.table.name !== 0) {
                        if (this.pos.gastro.orderedItems.hasOwnProperty(this.pos.gastro.party.name)) {
                            if (this.pos.gastro.orderedItems[this.pos.gastro.party.name].length > 0) {
                                invoiceItems = this.pos.gastro.orderedItems[this.pos.gastro.party.name];
                            }
                        }
                    } else {
                        if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)) {
                            if (this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0) {
                                invoiceItems = this.pos.gastro.openItems[this.pos.gastro.party.name];
                            }
                        }
                    }
                }

                return invoiceItems.map((invoiceItem) => {
                    return invoiceItem;
                });
            }
        },
        /**
         * format list of items to print
         * @return {*}
         */
        invoiceItemsToPrint() {
            return (order = null, service = null) => {
                let items = this._.cloneDeep(this.interimInvoiceItems(order, service));

                items.forEach((invoiceItem) => {
                    //CHECK IF WE HAVE CHOOSEN TAKE AWAY
                    if (this.pos.gastro.takeAway > 0) {
                        if (invoiceItem.takeAwayTaxing > 0) {
                            invoiceItem.taxValue = this.offSiteTax;
                        }
                    }

                    //DISCOUNT
                    if (invoiceItem.discount > 0) {
                        invoiceItem.sellPrice = parseFloat((((100 - invoiceItem.discount) / 100) * invoiceItem.sellPrice).toFixed(2));
                    }
                });

                return items;
            }
        },
    },
    methods: {
        startPrintInterimBill(order = null, service = null, invoiceData = {}) {
            this.loading = true;

            //TRY TO CREATE PRINT DATA
            let printData;

            try {
                //GENERATE INVOICE DATA
                let data = {
                    cashierID: this.api.auth.cashierID,
                    userID: this.realUser.userID,
                };

                data = Object.assign(data, invoiceData);

                if (service === "delivery")
                    data["tableNo"] = this.pos.gastro.table.name;
                else if (this.posType === "gastro") {
                    data["tableNo"] = this.pos.gastro.table.name;
                } else if (this.posType === "retail" || this.posType === "wholesaleRetail") {
                    data["tableNo"] = "";
                }

                printData = createInvoicePrintingData(this.invoiceItemsToPrint(order, service), data, this.invoicePrinter.options, this.realUser, true);

                //CHECK IF LAST ELEMENT IS CUT
                let lastElememt = printData[(printData.length - 1)];

                if (!lastElememt.hasOwnProperty("cut"))
                    printData.push({cut: true});
            } catch (err) {
                this.loading = false;
            }

            printDataFromPrinter(this.invoicePrinter,printData).then(()=>{
                this.$emit('printed')
            }).catch((err)=>{
                Events.$emit("showSnackbar", {
                    message: this.$t('generic.lang_errorOccurred'),
                    color: "error"
                });
            }).finally(()=>{
                this.loading = false;
            })


        }
    }
}
