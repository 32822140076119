import {commit} from "lodash/seq";

export default {
    namespaced: true,

    state: {
        isOfflineForHybridServer: false,
        isOfflineForCloudServer: false,
        isHybridServerOfflineOrInStop: false,
        last_hybrid_dialog_appear: isNaN(parseInt(localStorage.getItem("last_hybrid_dialog_appear"))) ? 0 : parseInt(localStorage.getItem("last_hybrid_dialog_appear")),
        lastHybridContact:0,
    },
    getters: {
        IsOfflineForHybridServer: state => state.isOfflineForHybridServer,
        IsOfflineForCloudServer: state => state.isOfflineForCloudServer,
        lastHybridDialogAppear: state => isNaN(parseInt(localStorage.getItem("last_hybrid_dialog_appear"))) ? 0 : parseInt(localStorage.getItem("last_hybrid_dialog_appear")),
        IsHybridServerOfflineOrInStop: state => state.isHybridServerOfflineOrInStop,
        lastHybridContact: state => state.lastHybridContact,
    },
    mutations: {
        setLastHybridContact(state,payload) {
            state.lastHybridContact = Number(payload);
        },
        lastHybridDialogAppear(state) {
            state.last_hybrid_dialog_appear = new Date().getTime();
            localStorage.setItem("last_hybrid_dialog_appear", new Date().getTime() + "")
        },
        Is_OfflineForHybridServer(state, payload) {
            state.isOfflineForHybridServer = payload;
        },
        Is_OfflineForCloudServer(state, payload) {
            state.isOfflineForCloudServer = payload;
        },
        IsHybridServerOfflineOrInStop: (state) => {
            return state.isHybridServerOfflineOrInStop;
        },
        turnOfflineForCloudServer(state) {
            state.isOfflineForCloudServer = true;
        },
        turnOnlineForCloudServer(state) {
            state.isOfflineForCloudServer = false;
        },
        turnOfflineForHybridServer(state) {
            state.isOfflineForHybridServer = true;
        },
        turnOnlineForHybridServer(state) {
            state.isOfflineForHybridServer = false;
        },
        //set hybrid server status based on 'get/dbSync/' response
        checkStatus(state, payload) {
            /*state.isOfflineForHybridServer = false;
            state.isOfflineForCloudServer = false;
            state.isHybridServerOfflineOrInStop = false;*/


            /**
             * check for hybrid server
             */
            if (payload.hybridStatus && payload.hybridStatus.status && window.store.getters["permissions/checkHybridEnabled"]("Hybrid")) {
                //save the last hybrid contact in store
                this.commit('hybrid/setLastHybridContact',payload.hybridStatus.lastHybridContact)
                //calc current time and last hybrid sync diff
                let diff = (Math.round(new Date().getTime()) - (Number(payload.hybridStatus.lastHybridContact) * 1000)) / 60000;
                //check if the hybrid server is offline 10 minutes ago
                if (diff >= 10 && diff < (60 * 24 * 10)) {
                    if (state.last_hybrid_dialog_appear === 0 || (state.last_hybrid_dialog_appear > 0 && (new Date().getTime() - state.last_hybrid_dialog_appear) / 3600000 >= 1)) {
                        state.isOfflineForHybridServer = true;
                        window.eventBus.$emit("status", {
                            server: "hybrid",
                            status: true
                        })
                    } else {
                        state.isOfflineForHybridServer = false;
                        window.eventBus.$emit("status", {
                            server: "hybrid",
                            status: false
                        })
                    }
                    return;
                } else if (diff >= (60 * 24 * 10)) {
                    if (
                        state.last_hybrid_dialog_appear === 0
                        ||
                        (state.last_hybrid_dialog_appear > 0 && (new Date().getTime() - state.last_hybrid_dialog_appear) / 3600000 >= 1)
                    ) {
                        state.isHybridServerOfflineOrInStop = true;
                        state.isOfflineForHybridServer = false;
                        window.eventBus.$emit("status", {
                            server: "hybrid",
                            status: true,
                            type: "stop"
                        })
                    } else {
                        state.isHybridServerOfflineOrInStop = false;
                        window.eventBus.$emit("status", {
                            server: "hybrid",
                            status: false,
                            type: "stop"
                        })
                    }
                    return;
                }

                state.isOfflineForCloudServer = false;
            } else if (payload.hybridStatus && payload.hybridStatus.status && window.store.getters["permissions/checkHybridEnabled"]("Server")) {
                //save the last hybrid contact in store
                this.commit('hybrid/setLastHybridContact',payload.hybridStatus.lastHybridContact)
                //calc current time and last hybrid sync diff
                let diff = (Math.round(new Date().getTime()) - (Number(payload.hybridStatus.lastHybridContact) * 1000)) / 60000;
                //check if the hybrid server is offline 10 minutes ago
                if (diff >= 10) {
                    if (state.last_hybrid_dialog_appear === 0 || (state.last_hybrid_dialog_appear > 0 && ((new Date().getTime() - state.last_hybrid_dialog_appear) / 60000) >= 10)) {
                        state.isOfflineForCloudServer = true;
                        window.eventBus.$emit("status", {
                            server: "cloud",
                            status: true
                        })
                    }
                }else{
                    state.isOfflineForCloudServer = false;
                }
                state.isOfflineForHybridServer = false;
                state.isHybridServerOfflineOrInStop = false;
            } else {
                state.isOfflineForCloudServer = false;
                state.isOfflineForHybridServer = false;
                state.isHybridServerOfflineOrInStop = false;
                window.eventBus.$emit("status", {
                    server: "both",
                    status: false
                })
            }

        }
    }
}
