import axios from "axios";
import Vue from "vue";

export default {
    namespaced: true,

    state: {
        courses: [],
    },

    mutations: {
        init(state, data) {
            state.courses = data;
        },
        backgroundSync(state, payload) {
            const transaction = payload.transaction;
            const transactionType = payload.type;

            if (transactionType === "INSERT") {
                //INSERT COURSE
                Vue.set(state.courses, state.courses.length, {
                    id: transaction.id,
                    name: transaction.name,
                    position: transaction.position,
                    orderbonPrinterID: transaction.orderbonPrinterID,
                    courseIcon: transaction.courseIcon,
                });
            }

            if (transactionType === "UPDATE") {
                //FIND COURSE
                let course = state.courses.find((course) => {
                    if (course.id === transaction.id) {
                        return true;
                    }
                });

                //UPDATE COURSE
                if (course) {
                    course.name = transaction.name;
                    course.position = transaction.position;
                    course.orderbonPrinterID = transaction.orderbonPrinterID;
                    course.courseIcon = transaction.courseIcon;
                }
            }

            if (transactionType === "DELETE") {
                //FIND COURSE
                let course = state.courses.find((course) => {
                    if (course.id === transaction.id) {
                        return true;
                    }
                });

                if (course) {
                    let courseIndex = state.courses.indexOf(course);
                    //DELETE COURSE
                    Vue.delete(state.courses, courseIndex);
                }
            }
        }
    },

    actions: {
        init({commit}) {
            return axios.post('get/indexeddb/courses/').then((res) => {
                commit("init", res.data);
            });
        }
    }
};
