export let onlineShopRoutes = {
    meta: {
        menuTitle: ('Online Shop'),
        icon: 'pe-7s-shopbag',
        permission: {
            requiredModule: 63,
            requiredPermission: "",
            hybridDisplay: ""
        }
    },
    childs: [
        {
            path: '/onlineShop/dashboard',
            name: 'onlineShop.dashboard',
            meta: {
                siteTitle: ('Dashboard'),
                menuTitle: ('Dashboard'),
                layout: 'default',
                icon: 'pe-7s-graph1',
                permission: {
                    requiredModule: 63,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/onlineshop/OnlineShopDashboard')
        },
        {
            path: '/onlineShop/orders',
            name: 'onlineShop.orders',
            meta: {
                siteTitle: ('generic.lang_orders'),
                menuTitle: ('generic.lang_orders'),
                layout: 'default',
                icon: 'pe-7s-pen',
                permission: {
                    requiredModule: 63,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/onlineshop/OnlineShopOrders')
        },
        {
            path: '/onlineShop/customers',
            name: 'onlineShop.customers',
            meta: {
                siteTitle: ('customers.lang_customers'),
                menuTitle: ('customers.lang_customers'),
                layout: 'default',
                icon: 'pe-7s-users',
                permission: {
                    requiredModule: 63,
                    requiredPermission: "",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/onlineshop/OnlineShopCustomers')
        },
    ],
    groups: []
};
