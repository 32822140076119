<template>
<div></div>
</template>

<script>
export default {
  name: "SocketTableController",
  data(){
    return{

    }
  },
  sockets: {
    "tableplan.refreshTables": function () {
      this.$socket.emit("tableplan.getLockedTables");
    },
    "tableplan.getLockedTables": function (payload) {
      this.$store.commit("pos/gastro/setLockedTables",payload);
    },
  },
}
</script>

<style scoped>

</style>