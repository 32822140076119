<template>
  <v-snackbar
      :timeout="snackbar.timeout"
      :top="snackbar.position === 'top'"
      :color="snackbar.color"
      v-model="showSnackbar"
      :multi-line="multiLine"
  >
    {{ snackbar.message }}
    <v-btn v-if="snackbar.isClosable" text @click="showSnackbar = false">
      {{ $t('generic.lang_ok') }}
    </v-btn>
  </v-snackbar>
</template>

<script>
import {Events} from "../plugins/events";

export default {
  name: "Snackbar",

  data() {
    return {
      showSnackbar: false,
      snackbarDefaults: {
        message: '',
        timeout: 1500,
        color: 'success',
        isClosable: true
      },
      snackbar: {},
      multiLine: false
    }
  },

  mounted() {
    Events.$on("showSnackbar", data => {
      //TIMEOUT
      if (!data.hasOwnProperty("timeout")) {
        data.timeout = this.snackbarDefaults.timeout;
      }

      //CLOSABLE
      if (!data.hasOwnProperty("isClosable")) {
        data.isClosable = this.snackbarDefaults.isClosable;
      }

      this.showSnackbar = true;
      this.snackbar = data;
    });
  }
}
</script>
