import Vue from "vue";
import axios from "axios";

export default {
    namespaced: true,

    state: {
        templates: []
    },

    mutations: {
        init(state, data) {
            state.templates = data;
        },
        resetState(state,data){
            state.templates = [];
        },
        backgroundSync(state, payload) {
            const transaction = payload.transaction;
            const transactionType = payload.type;

            if(transactionType === "INSERT") {
                //INSERT TEMPLATE
                Vue.set(state.templates, state.templates.length, {
                    id: transaction.id,
                    multiUser:transaction.multiUser,
                    type: transaction.type,
                    name: transaction.name,
                    JSON: transaction.JSON,
                    gridWidth: transaction.gridWidth,
                    gridHeight: transaction.gridHeight,
                    fontSize: transaction.fontSize,
                    itemNameLength: transaction.itemNameLength
                });
            }

            if(transactionType === "UPDATE") {
                //FIND TEMPLATE
                let template = state.templates.find((template) => {
                    if(template.id === transaction.id) {
                        return true;
                    }
                });

                //UPDATE TEMPLATE
                if(template) {
                    template.name = transaction.name;
                    template.type = transaction.type;
                    template.JSON = transaction.JSON;
                    template.multiUser = transaction.multiUser;
                    template.gridWidth = transaction.gridWidth;
                    template.gridHeight = transaction.gridHeight;
                    template.itemNameLength = transaction.itemNameLength;
                    template.fontSize = transaction.fontSize;
                }
            }

            if(transactionType === "DELETE") {
                //FIND TEMPLATE
                let template = state.templates.find((template) => {
                    if(template.id === transaction.id) {
                        return true;
                    }
                });

                if(template) {
                    let templateIndex = state.templates.indexOf(template);

                    //DELETE TEMPLATE
                    Vue.delete(state.templates, templateIndex);
                }
            }
        }
    },

    actions: {
        async init({commit}) {
            commit('resetState', null);

            return axios.post('get/indexeddb/posLayoutTemplates/').then((res) => {
                commit("init", res.data);
            });
        },
        resetState({ commit }, data) {
            commit('resetState', data);
        },
    },

    getters: {

    }
};
