export let factureRoutes = {

    meta: {
        menuTitle: ('erp.lang_nav_faktura'),
        icon: 'pe-7s-note2',
        hideWhenHybridOffline: true,
        permission: {
            requiredModule: 4,
            requiredPermission: "",
            hybridDisplay: ""
        }
    },
    childs: [
        {
            header: false,
            path: '/billing/fakturaBilling',
            name: 'billing.fakturaBilling',
            meta: {
                siteTitle: ('accounting.lang_bill'),
                menuTitle: ('accounting.lang_bill'),
                layout: 'default',
                icon: 'pe-7s-pen',
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 4,
                    requiredPermission: "facture_invoices",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/billing/Invoices')
        },
        {
            header: false,
            path: '/billing/fakturaBilling/create',
            name: 'billing.fakturaBilling.create',
            meta: {
                siteTitle: 'Create Invoice',
                menuTitle: 'Create Invoice',
                layout: 'default',
                icon: 'pe-7s-pen',
                hideInNavigation: true,
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 4,
                    requiredPermission: "",
                    hybridDisplay: "",
                }
            },
            component: () => import('../views/billing/CreateInvoice')
        },
        {
            header: false,
            path: '/billing/drafts',
            name: 'billing.drafts',
            meta: {
                siteTitle: ('accounting.lang_nav_invoiceDraft'),
                menuTitle: ('accounting.lang_nav_invoiceDraft'),
                layout: 'default',
                icon: 'pe-7s-note2',
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 4,
                    requiredPermission: "facture_invoicesDrafts",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/billing/drafts/Drafts')
        },
        {
            header: false,
            path: '/billing/drafts/edit/:uuid',
            name: 'billing.drafts.edit',
            meta: {
                siteTitle: ('accounting.lang_nav_invoiceDraft'),
                menuTitle: ('accounting.lang_nav_invoiceDraft'),
                layout: 'default',
                icon: 'pe-7s-note2',
                hideInNavigation: true,
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 4,
                    requiredPermission: "facture_invoicesDrafts",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/billing/drafts/EditDraft')
        },
        {
            header: false,
            path: '/billing/quotations',
            name: 'billing.quotations',
            meta: {
                siteTitle: ('accounting.lang_nav_Quotations'),
                menuTitle: ('accounting.lang_nav_Quotations'),
                layout: 'default',
                icon: 'pe-7s-drawer',
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 4,
                    requiredPermission: "facture_qoutations",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/billing/quotations/Quotations')
        },
        {
            header: false,
            path: '/billing/quotations/create',
            name: 'billing.quotations.create',
            meta: {
                siteTitle: ('accounting.lang_nav_Quotations'),
                menuTitle: ('accounting.lang_nav_Quotations'),
                layout: 'default',
                icon: 'pe-7s-drawer',
                hideInNavigation: true,
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 4,
                    requiredPermission: "facture_qoutations",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/billing/quotations/CreateQuotation')
        },
        {
            header: false,
            path: '/billing/quotations/edit/:uuid',
            name: 'billing.quotations.edit',
            meta: {
                siteTitle: ('accounting.lang_nav_Quotations'),
                menuTitle: ('accounting.lang_nav_Quotations'),
                layout: 'default',
                icon: 'pe-7s-drawer',
                hideInNavigation: true,
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 4,
                    requiredPermission: "facture_qoutations",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/billing/quotations/EditQuotation')
        },
        {
            header: false,
            path: '/billing/deliverynote',
            name: 'billing.deliverynote',
            meta: {
                siteTitle: ('accounting.lang_nav_lieferschein'),
                menuTitle: ('accounting.lang_nav_lieferschein'),
                layout: 'default',
                icon: 'pe-7s-note2',
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 4,
                    requiredPermission: "facture_deliveryNotes",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/billing/deliverynotes/DeliveryNotes')
        },
        {
            header: false,
            path: '/billing/deliverynote/create',
            name: 'billing.deliverynote.create',
            meta: {
                siteTitle: ('accounting.lang_nav_lieferschein'),
                menuTitle: ('accounting.lang_nav_lieferschein'),
                layout: 'default',
                icon: 'pe-7s-note2',
                hideInNavigation: true,
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 4,
                    requiredPermission: "facture_deliveryNotes",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/billing/deliverynotes/CreateDeliveryNote')
        },
        {
            header: false,
            path: '/billing/deliverynote/edit/:uuid',
            name: 'billing.deliverynote.edit',
            meta: {
                siteTitle: ('accounting.lang_nav_lieferschein'),
                menuTitle: ('accounting.lang_nav_lieferschein'),
                layout: 'default',
                icon: 'pe-7s-note2',
                hideInNavigation: true,
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 4,
                    requiredPermission: "facture_deliveryNotes",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/billing/deliverynotes/EditDeliveryNote')
        },
        {
            header: false,
            path: '/billing/proformat',
            name: 'billing.proformat',
            meta: {
                siteTitle: ('accounting.lang_proFormaInvoice'),
                menuTitle: ('accounting.lang_proFormaInvoice'),
                layout: 'default',
                icon: 'pe-7s-note2',
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 4,
                    requiredPermission: "facture_proformaInvoices",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/billing/proformat/Proformats')
        },
        {
            header: false,
            path: '/billing/proformat/edit/:uuid',
            name: 'billing.proformat.edit',
            meta: {
                siteTitle: 'Pro Forma Invoice',
                menuTitle: 'Pro Forma Invoice',
                layout: 'default',
                icon: 'pe-7s-note2',
                hideInNavigation: true,
                hideWhenHybridOffline: true,
                permission: {
                    requiredModule: 4,
                    requiredPermission: "facture_proformaInvoices",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/billing/proformat/EditProformat')
        }
    ],
    groups: [],
};
