export let timeTrackRoutes = {
    meta: {
        menuTitle: ('generic.lang_nav_timesheet'),
        icon: 'pe-7s-stopwatch',
        permission: {
            requiredModule: 38,
            requiredPermission: "",
            hybridDisplay: ""
        }
    },
    childs: [
        {
            header: false,
            path: '/timetrack/timetrack',
            name: 'timetrack.timetrack',
            meta: {
                siteTitle: ('generic.lang_personalTimeTrack'),
                menuTitle: ('generic.lang_personalTimeTrack'),
                layout: 'default',
                icon: 'pe-7s-stopwatch',
                permission: {
                    requiredModule: 38,
                    requiredPermission: "timetrack_personal",
                    hybridDisplay: "Hybrid"
                }
            },
            component: () => import('../views/timesheet/timetrack/TimeTrack')
        },
        //start of timeCardDaily
        {
            header: false,
            path: '/timetrack/timeCardDaily',
            name: 'timetrack.timeCardDaily',
            meta: {
                siteTitle: ('generic.lang_timecardDaily'),
                menuTitle: ('generic.lang_timecardDaily'),
                layout: 'default',
                icon: 'lnr-calendar-full',
                permission: {
                    requiredModule: 38,
                    requiredPermission: "timetrack_daily",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/timesheet/dailytimecard/DailyTimeCard')
        },
        {
            header: false,
            path: '/timetrack/timeCardDaily/track/:id',
            name: 'timetrack.timeCardDaily.details',
            meta: {
                hideInNavigation: true,
                layout: 'default',
                permission: {
                    requiredModule: 38,
                    requiredPermission: "timetrack_daily",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/timesheet/dailytimecard/Track')
        }//end of timeCardDaily
        ,
        {//start of timeCardMonthly
            header: false,
            path: '/timetrack/timeCardMonthly',
            name: 'timetrack.timeCardMonthly',
            meta: {
                siteTitle: ('generic.lang_timecardMonthly'),
                menuTitle: ('generic.lang_timecardMonthly'),
                layout: 'default',
                icon: 'lnr-calendar-full',
                permission: {
                    requiredModule: 38,
                    requiredPermission: "timetrack_monthly",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/timesheet/monthlytimecard/MonthlyTimeCard')
        },
        {
            header: false,
            path: '/timetrack/timeCardMonthly/track/:id',
            name: 'timetrack.timeCardMonthly.track',
            meta: {
                siteTitle: ('generic.lang_timecardMonthly'),
                menuTitle: ('generic.lang_timecardMonthly'),
                layout: 'default',
                icon: 'lnr-calendar-full',
                hideInNavigation: true,
                permission: {
                    requiredModule: 38,
                    requiredPermission: "timetrack_monthly",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/timesheet/monthlytimecard/Track')
        },//end of timeCardMonthly
        {
            header: false,
            path: '/timetrack/timeCardMonthlyMass',
            name: 'timetrack.timeCardMonthlyMass',
            meta: {
                siteTitle: ('generic.lang_timeTrackingMass'),
                menuTitle: ('generic.lang_timeTrackingMass'),
                layout: 'default',
                icon: 'lnr-briefcase',
                permission: {
                    requiredModule: 38,
                    requiredPermission: "timetrack_monthly",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/common/404.vue')
        },
        {
            header: false,
            path: '/timetrack/analyses',
            name: 'timetrack.analyses',
            meta: {
                siteTitle: ('analytic.lang_auswertung'),
                menuTitle: ('analytic.lang_auswertung'),
                layout: 'default',
                icon: 'lnr-chart-bars',
                permission: {
                    requiredModule: 38,
                    requiredPermission: "timetrack_analysis",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/timesheet/analyses/TimeTrackAnalyse')
        }
    ],
    groups: []
};