<template>
  <div :class="[$vuetify.theme.dark? '' : 'app-container app-theme-white']">
    <transition v-if="/*$vuetify.breakpoint.mdAndUp*/true" appear mode="out-in" name="fade">
      <Sidebar :drawerSetter="($vuetify.breakpoint.mdAndDown ? false : null)" :sidebarbg="sidebarcolor"/>
    </transition>
    <div class="app-main__outer">
      <v-main>
        <slot></slot>
      </v-main>
    </div>
  </div>
</template>

<script>
import Sidebar from "../Components/Sidebar";

export default {
  name: 'app',

  components: {
    Sidebar
  },

  data() {
    return {
      headercolor: null,
      sidebarcolor: null,
    }
  },
}
</script>
