import randomString from "randomstring";
import Vue from "vue";
import axios from "axios";

import store from "@/store";

import gastro from "./gastro";
import retail from "./retail";
import wholesaleRetail from "./wholesaleRetail";
import { ENDPOINTS } from "../../../config";
import { getNetPrice, calcGrossPrice } from "../../../plugins/tax";

let cannotApplyPriceDiscountChanges = (item) => {
  return (
    item.isVoid ||
    item.ean.includes("V$") ||
    item.ean.includes("GF$") ||
    item.ean.includes("ZMYLE$") ||
    item.ean.includes("MYWORLD$") ||
    item.ean.includes("SIHOTVOUCHER$") ||
    item.randomPositionID?.includes("$deliveryCost_")
  );
};

// TODO: optimize the set customer workflow

export default {
  namespaced: true,

  modules: {
    gastro,
    retail,
    wholesaleRetail,
  },

  state: {
    activeShift: {
      uuid: "",
    },
    verifiedAge: 0,
    progressToken: null,
    customerDisplayTipPercent: null,
  },

  getters: {
    activeShiftUUID: (state) =>
      state.activeShift.uuid ? state.activeShift.uuid : null,
    customerDisplayTipPercent: (state) => state.customerDisplayTipPercent,
    bookedItemsCount: (state) => {
      return (posType) => {
        if (posType === "gastro") {
          let orderItemsCount = state.gastro.orderedItems[state.gastro.party.name]?.filter(
            (item) => !item.ean.includes("V$") && !item.ean.includes("GF$") || (item.id && (item.id.toString().includes('VOUCHER_') || item.id.toString().includes('GIFTCARD_')))
          ).length;

          let openItemsCount = state.gastro.openItems[state.gastro.party.name]?.filter(
            (item) => !item.ean.includes("V$") && !item.ean.includes("GF$") || (item.id && (item.id.toString().includes('VOUCHER_') || item.id.toString().includes('GIFTCARD_')))
          ).length;

          return openItemsCount > 0? openItemsCount: orderItemsCount;

        } else {
          return state[posType].orderedItems?.filter(
            (item) => !item.ean.includes("V$") && !item.ean.includes("GF$") || (item.id && (item.id.toString().includes('VOUCHER_') || item.id.toString().includes('GIFTCARD_')))
          ).length;
        }
      };
    },
    hasRedeemedAndNewVGF: (state) => {
      return (posType) => {
        if (posType === "gastro") {
          let hasNewVGF = [...(state.gastro.orderedItems[state.gastro.party.name] ?? []),
          ...(state.gastro.openItems[state.gastro.party.name] ?? [])].some(
            (item) => item.id && (item.id.toString().includes('VOUCHER_') || item.id.toString().includes('GIFTCARD_')) &&
            !item.isVoid
          );
          let hasRedeemedVGF = [...(state.gastro.orderedItems[state.gastro.party.name] ?? []),
          ...(state.gastro.openItems[state.gastro.party.name] ?? [])].some(
            (item) => (item.ean.includes("V$") || item.ean.includes("GF$")) &&
             !(item.id && (item.id.toString().includes('VOUCHER_') || item.id.toString().includes('GIFTCARD_'))
             && !item.isVoid
            )
          );
          return hasNewVGF && hasRedeemedVGF

        } else {
          let hasNewVGF = state[posType].orderedItems?.some(
            (item) => item.id && (item.id.toString().includes('VOUCHER_') || item.id.toString().includes('GIFTCARD_'))
            && !item.isVoid
          );
          let hasRedeemedVGF = state[posType].orderedItems?.some(
            (item) => (item.ean.includes("V$") || item.ean.includes("GF$")) &&
             !(item.id && (item.id.toString().includes('VOUCHER_') || item.id.toString().includes('GIFTCARD_')))
             && !item.isVoid
          );
          return hasNewVGF && hasRedeemedVGF
        }
      };
    },
    hasGiftCardAndSurpassMin: (state) => {
      return (posType) => {
        if (posType === "gastro") {
          const gfItem = state.gastro.openItems[state.gastro.party.name]?.find(
            (item) => item.ean.includes("GF$") && !item.isVoid
          );
          if (gfItem && gfItem.hasOwnProperty("min_redeem_cart_value")) {
            return (
              store.getters["pos/gastro/goodsValue"] >=
              gfItem.min_redeem_cart_value
            );
          }
          return true;
        } else {
          const gfItem = state[posType].orderedItems.find(
            (item) => item.ean.includes("GF$") && !item.isVoid
          );
          if (gfItem && gfItem.hasOwnProperty("min_redeem_cart_value")) {
            return (
              store.getters["pos/" + posType + "/goodsValue"] >=
              gfItem.min_redeem_cart_value
            );
          }
          return true;
        }
      };
    },
  },

  mutations: {
    updateActiveShift(state, data) {
      state.activeShift.uuid = data.uuid;
    },
    stornoItem(state, data) {
      let selectedItem;

      if (data.posType === "gastro") {
        if (state.gastro.selectedOrderedItem != null) {
          selectedItem = state.gastro.selectedOrderedItem;
        }
      }

      if (data.posType === "retail") {
        if (state.retail.selectedItem != null) {
          selectedItem = state.retail.selectedItem;
        }
      }

      if (data.posType === "wholesaleRetail") {
        if (state.wholesaleRetail.selectedItem != null) {
          selectedItem = state.wholesaleRetail.selectedItem;
        }
      }

      if (selectedItem) {
        if (data.amount !== selectedItem.amount) {
          //GENERATE NEW ITEM
          let newItem = Object.assign({}, selectedItem);
          newItem.randomPositionID = randomString.generate(15);
          newItem.originalItemRandomPositionID =
            (selectedItem && selectedItem.randomPositionID) ||
            randomString.generate(15);
          newItem.amount = data.amount;
          newItem.isVoid = true;
          newItem.voidReason = data.voidReason;
          newItem.needsVoidPrint = true;
          newItem.voidReasonID = data.id;

          //PUSH NEW ITEM
          if (data.posType === "gastro") {
            state.gastro.orderedItems[state.gastro.party.name].push(newItem);
          }

          if (data.posType === "retail") {
            state.retail.orderedItems.push(newItem);
          }

          if (data.posType === "wholesaleRetail") {
            state.wholesaleRetail.orderedItems.push(newItem);
          }

          //CHANGE AMOUNT OF OLD ITEM
          selectedItem.amount = selectedItem.amount - data.amount;
        } else {
          //FULL STACK OF ITEM IS TO VOID
          selectedItem.voidReasonID = data.id;
          selectedItem.isVoid = true;
          selectedItem.voidReason = data.voidReason;
          selectedItem.needsVoidPrint = true;
        }
      }
    },
    deleteItem(state, data) {
      if (data.posType === "gastro") {
        if (state.gastro.selectedOpenItem != null) {
          const item = state.gastro.selectedOpenItem;

          if (item) {
            let itemIndex = state.gastro.openItems[
              state.gastro.party.name
            ].indexOf(state.gastro.selectedOpenItem);

            Vue.delete(
              state.gastro.openItems[state.gastro.party.name],
              itemIndex
            );

            //SELECT NEXT ITEM
            this.commit(
              "pos/gastro/selectInvoiceOpenItem",
              state.gastro.openItems[state.gastro.party.name][itemIndex]
            );
          }
        } else if (state.gastro.selectedOpenItem === null) {
          //ALL ITEMS
          state.gastro.openItems[state.gastro.party.name] = [];
        }
      }
    },
    priceDiscount(state, data) {
      let item;

      if (data.posType === "gastro") {
        if (state.gastro.selectedOpenItem != null) {
          item = state.gastro.selectedOpenItem;
        }

        if (state.gastro.selectedOrderedItem != null) {
          item = state.gastro.selectedOrderedItem;
        }
      }

      if (data.posType === "retail") {
        if (state.retail.selectedItem != null) {
          item = state.retail.selectedItem;
        }
      }

      if (data.posType === "wholesaleRetail") {
        if (state.wholesaleRetail.selectedItem != null) {
          item = state.wholesaleRetail.selectedItem;
        }
      }

      if (item) {
        if (data.amount !== item.amount) {
          //GENERATE NEW ITEM
          let newItem = Object.assign({}, item);
          newItem.randomPositionID = randomString.generate(15);
          newItem.amount = data.amount;
          newItem.sellPrice = data.discountPrice;

          //PUSH NEW ITEM
          if (data.posType === "retail") {
            state.retail.orderedItems.push(newItem);
          }

          if (data.posType === "wholesaleRetail") {
            state.wholesaleRetail.orderedItems.push(newItem);
          }

          if (data.posType === "gastro") {
            if (state.gastro.selectedOpenItem != null) {
              state.gastro.openItems[state.gastro.party.name].push(newItem);
            }

            if (state.gastro.selectedOrderedItem != null) {
              state.gastro.orderedItems[state.gastro.party.name].push(newItem);
            }
          }

          //CHANGE AMOUNT OF OLD ITEM
          item.amount = item.amount - data.amount;
        } else {
          item.sellPrice = data.discountPrice;
        }
      }
    },
    discount(state, data) {
      let item;

      if (data.posType === "gastro") {
        if (state.gastro.selectedOpenItem != null) {
          item = state.gastro.selectedOpenItem;
        }

        if (state.gastro.selectedOrderedItem != null) {
          item = state.gastro.selectedOrderedItem;
        }
      }

      if (data.posType === "retail") {
        if (state.retail.selectedItem != null) {
          item = state.retail.selectedItem;
        }
      }

      if (data.posType === "wholesaleRetail") {
        if (state.wholesaleRetail.selectedItem != null) {
          item = state.wholesaleRetail.selectedItem;
        }
      }

      if (item) {
        if (data.amount !== item.amount) {
          //GENERATE NEW ITEM
          let newItem = Object.assign({}, item);
          newItem.randomPositionID = randomString.generate(15);
          newItem.amount = data.amount;
          newItem.discount = data.discount;

          //PUSH NEW ITEM
          if (data.posType === "retail") {
            state.retail.orderedItems.push(newItem);
          }

          if (data.posType === "wholesaleRetail") {
            state.wholesaleRetail.orderedItems.push(newItem);
          }

          if (data.posType === "gastro") {
            if (state.gastro.selectedOpenItem != null) {
              state.gastro.openItems[state.gastro.party.name].push(newItem);
            }

            if (state.gastro.selectedOrderedItem != null) {
              state.gastro.orderedItems[state.gastro.party.name].push(newItem);
            }
          }

          //CHANGE AMOUNT OF OLD ITEM
          item.amount = item.amount - data.amount;
        } else {
          item.discount = data.discount;
        }
      }
    },
    discountInvoice(state, data) {
      if (data.posType === "gastro") {
        //OPEN ITEMS
        if (state.gastro.openItems.hasOwnProperty(state.gastro.party.name)) {
          state.gastro.openItems[state.gastro.party.name].forEach((item) => {
            if (
              !item.itemNotDiscountable &&
              !item.ean.includes("V$") &&
              !item.ean.includes("GF$") &&
              !item.ean.includes("ZMYLE$") &&
              !item.ean.includes("MYWORLD$") &&
              !item.randomPositionID?.includes("$deliveryCost_")
            ) {
              item.discount = data.discount;
            }
          });
        }

        //BOOKED ITEMS
        if (state.gastro.orderedItems.hasOwnProperty(state.gastro.party.name)) {
          state.gastro.orderedItems[state.gastro.party.name].forEach((item) => {
            if (
              !item.isVoid &&
              !item.itemNotDiscountable &&
              !item.ean.includes("V$") &&
              !item.ean.includes("GF$") &&
              !item.ean.includes("ZMYLE$") &&
              !item.ean.includes("MYWORLD$") &&
              !item.randomPositionID?.includes("$deliveryCost_")
            ) {
              item.discount = data.discount;
            }
          });
        }
      }

      if (data.posType === "retail") {
        state.retail.orderedItems.forEach((item) => {
          if (
            !item.isVoid &&
            !item.itemNotDiscountable &&
            !item.ean.includes("V$") &&
            !item.ean.includes("GF$") &&
            !item.ean.includes("ZMYLE$") &&
            !item.ean.includes("MYWORLD$")
          ) {
            item.discount = data.discount;
          }
        });
      }
      if (data.posType === "wholesaleRetail") {
        state.wholesaleRetail.orderedItems.forEach((item) => {
          if (
            !item.isVoid &&
            !item.itemNotDiscountable &&
            !item.ean.includes("V$") &&
            !item.ean.includes("GF$") &&
            !item.ean.includes("ZMYLE$") &&
            !item.ean.includes("MYWORLD$")
          ) {
            item.discount = data.discount;
          }
        });
      }
    },
    async increaseInvoice(state, data) {
      if (data.posType === "gastro") {
        //OPEN ITEMS
        if (state.gastro.openItems.hasOwnProperty(state.gastro.party.name)) {
          for (let item in state.gastro.openItems[state.gastro.party.name]) {
            if (
              !state.gastro.openItems[state.gastro.party.name].hasOwnProperty(
                item
              )
            )
              continue;

            item = state.gastro.openItems[state.gastro.party.name][item];

            if (
              !item.priceCannotChange &&
              !item.ean.includes("V$") &&
              !item.ean.includes("GF$") &&
              !item.ean.includes("ZMYLE$") &&
              !item.ean.includes("MYWORLD$")
            ) {
              let fromPrice = item.originalSellPrice;

              // CHECK IF ITEM HAS MEALSIZE
              if (parseInt(item.mealSizeID) > 0) {
                // FIND ORG. ITEM
                let baseItem = await store.dispatch(
                  "items/getItemByID",
                  item.id
                );

                if (baseItem) {
                  // FIND MEALSIZE
                  let baseItemSize = baseItem.mealSizes.find(
                    (size) => size.id === item.mealSizeID
                  );

                  if (baseItemSize) {
                    fromPrice = baseItemSize.price;
                  }
                }
              }

              item.sellPrice = parseFloat(
                (((100 + data.value) / 100) * fromPrice).toFixed(2)
              );
              item.isPriceChanged = true;
            }
          }
        }

        //BOOKED ITEMS
        if (state.gastro.orderedItems.hasOwnProperty(state.gastro.party.name)) {
          for (let item in state.gastro.orderedItems[state.gastro.party.name]) {
            if (
              !state.gastro.orderedItems[
                state.gastro.party.name
              ].hasOwnProperty(item)
            )
              continue;

            item = state.gastro.orderedItems[state.gastro.party.name][item];

            if (
              !item.isVoid &&
              !item.priceCannotChange &&
              !item.ean.includes("V$") &&
              !item.ean.includes("GF$") &&
              !item.ean.includes("ZMYLE$") &&
              !item.ean.includes("MYWORLD$")
            ) {
              let fromPrice = item.originalSellPrice;

              // CHECK IF ITEM HAS MEALSIZE
              if (parseInt(item.mealSizeID) > 0) {
                // FIND ORG. ITEM
                let baseItem = await store.dispatch(
                  "items/getItemByID",
                  item.id
                );

                if (baseItem) {
                  // FIND MEALSIZE
                  let baseItemSize = baseItem.mealSizes.find(
                    (size) => size.id === item.mealSizeID
                  );

                  if (baseItemSize) {
                    fromPrice = baseItemSize.price;
                  }
                }
              }

              item.sellPrice = parseFloat(
                (((100 + data.value) / 100) * fromPrice).toFixed(2)
              );
              item.isPriceChanged = true;
            }
          }
        }
      }

      if (data.posType === "retail") {
        state.retail.orderedItems.forEach((item) => {
          if (
            !item.isVoid &&
            !item.priceCannotChange &&
            !item.ean.includes("V$") &&
            !item.ean.includes("GF$") &&
            !item.ean.includes("ZMYLE$") &&
            !item.ean.includes("MYWORLD$")
          ) {
            item.sellPrice = parseFloat(
              (((100 + data.value) / 100) * item.originalSellPrice).toFixed(2)
            );
            item.isPriceChanged = true;
          }
        });
      }

      if (data.posType === "wholesaleRetail") {
        state.wholesaleRetail.orderedItems.forEach((item) => {
          if (
            !item.isVoid &&
            !item.priceCannotChange &&
            !item.ean.includes("V$") &&
            !item.ean.includes("GF$") &&
            !item.ean.includes("ZMYLE$") &&
            !item.ean.includes("MYWORLD$")
          ) {
            item.sellPrice = parseFloat(
              (((100 + data.value) / 100) * item.originalSellPrice).toFixed(2)
            );
            item.netPrice = getNetPrice(item.sellPrice, item.taxValue);
            item.isPriceChanged = true;
          }
        });
      }
    },
    setCourse(state, data) {
      let item;
      if (data.posType === "gastro") {
        if (state.gastro.selectedOpenItem != null) {
          item = state.gastro.selectedOpenItem;
        }

        if (state.gastro.selectedOrderedItem != null) {
          item = state.gastro.selectedOrderedItem;
        }
      }

      if (data.posType === "retail") {
        if (state.retail.selectedItem != null) {
          item = state.retail.selectedItem;
        }
      }

      if (data.posType === "wholesaleRetail") {
        if (state.wholesaleRetail.selectedItem != null) {
          item = state.wholesaleRetail.selectedItem;
        }
      }

      if (item) {
        state.gastro.selectedCourse = data.course;
        item.course = data.course;
      } else {
        state.gastro.selectedCourse = data.course;
      }
    },
    setFreetext(state, data) {
      if (data.posType === "gastro") {
        if (data.selectedItem !== null) {
          if (state.gastro.selectedOpenItem != null) {
            const item = state.gastro.selectedOpenItem;

            if (item) {
              item.freeText = data.freeText;
            }
          }

          if (state.gastro.selectedOrderedItem != null) {
            const item = state.gastro.selectedOrderedItem;

            if (item) {
              item.freeText = data.freeText;
            }
          }
        } else {
          //SET GLOBAL FREETEXT
          store.commit("pos/gastro/setFreetext", data.freeText, {
            root: true,
          });
        }
      }

      if (data.posType === "retail") {
        if (data.selectedItem !== null) {
          const item = state.retail.selectedItem;

          if (item) {
            item.freeText = data.freeText;
          }
        } else {
          //SET GLOBAL FREETEXT
          store.commit("pos/retail/setFreetext", data.freeText, {
            root: true,
          });
        }
      }

      if (data.posType === "wholesaleRetail") {
        if (data.selectedItem !== null) {
          const item = state.wholesaleRetail.selectedItem;

          if (item) {
            item.freeText = data.freeText;
          }
        } else {
          //SET GLOBAL FREETEXT
          store.commit("pos/wholesaleRetail/setFreetext", data.freeText, {
            root: true,
          });
        }
      }
    },
    setCustomer(state, data) {
      /*
             ### customer cases ###
             0 => null to new customer,// the old discount should be considered
             1 => old customer to new customer,// very important to reset everything as it was when applying the new changes
             2 => old customer to null // we need to reset the price/discount to where it was before (for now it s not possible for discount )
             3 => null to null // no need to do anything
             can be used later
            */

      let customerCase = (() => {
        if (!state[data.posType].customer && data.customer) return 0;
        else if (state[data.posType].customer && data.customer) return 1;
        else if (state[data.posType].customer && !data.customer) return 2;
        else return 3;
      })();

      if (customerCase === 3) return;

      let baseItem = null;
      if (data.posType === "gastro") {
        // ADDING PARAM TO PREVENT THE PRICES FROM BEING CHANGED WHEN REACTIVATING A SALE OR SETTING THE CUSTOMER AFTER GETTING AN ORDER
        if (data.setCustomerOnly) {
          state.gastro.customer = data.customer;
          return;
        }

        let group = null;
        //CHECK DISCOUNT
        if (data.customer !== null) {
          //OPEN ITEMS
          for (let partyName in state.gastro.openItems) {
            if (!state.gastro.openItems.hasOwnProperty(partyName)) continue;

            state.gastro.openItems[partyName].forEach(async (item) => {
              if (cannotApplyPriceDiscountChanges(item)) return;

              let originalTax = 0;
              baseItem = await this.dispatch("items/getItemByID", item.id);
              if (baseItem) {
                originalTax = baseItem.taxValue;
              } else {
                originalTax = item.taxValue;
              }
              let itemNetPrice = getNetPrice(
                item.originalSellPrice,
                originalTax
              );

              //CALCULATING EXTRAS PRICE
              let extrasPrice = 0;
              if (
                Array.isArray(item.selectedExtras) &&
                item.selectedExtras.length > 0
              ) {
                extrasPrice = item.selectedExtras.reduce((sum, extra) => {
                  sum = sum + Number(extra.sellPrice) * extra.amount;
                  return sum;
                }, 0);
              }

              //check if we are in delivery service pos
              if (state.gastro.isDeliveryService) {
                group = await this.dispatch(
                  "itemgroups/getItemgroupByID",
                  item.itemgroupID
                );
                if (group) {
                  if (customerCase === 0) {
                    if (state.gastro.delivery) {
                      item.discount =
                        item.discount < Number(group.deliveryDiscount)
                          ? Number(group.deliveryDiscount)
                          : item.discount;
                    } else {
                      item.discount =
                        item.discount < Number(group.takeAwayDiscount)
                          ? Number(group.takeAwayDiscount)
                          : item.discount;
                    }
                  } else {
                    if (state.gastro.delivery) {
                      item.discount = Number(group.deliveryDiscount);
                    } else {
                      item.discount = Number(group.takeAwayDiscount);
                    }
                  }
                }
              }

              // WARE PRICE
              if (data.customer.group !== null) {
                if (data.customer.group.setTax >= 0) {
                  item.taxValue = data.customer.group.setTax;
                  item.sellPrice = calcGrossPrice(itemNetPrice, item.taxValue);
                } else {
                  item.taxValue = originalTax;
                }

                if (data.customer.group.type === 1) {
                  if (customerCase !== 0) item.discount = 0;

                  if (item.isPriceChanged || item.priceCannotChange) return;

                  await axios
                    .post(ENDPOINTS.POS.CUSTOMERGROUPITEMPRICE, {
                      customerGroup: data.customer.group.id,
                      itemId: item.id,
                    })
                    .then((res) => {
                      if (res.data.STATUS) {
                        item.sellPrice = Number(res.data.price) + extrasPrice;
                      } else {
                        item.sellPrice =
                          calcGrossPrice(itemNetPrice, item.taxValue) +
                          extrasPrice;
                      }
                    });
                } else {
                  //CHECK ITEM GROUP
                  if (
                    data.customer.hasOwnProperty("itemgroupDiscount") &&
                    data.customer.itemgroupDiscount !== null &&
                    Object.keys(data.customer.itemgroupDiscount).length > 0 &&
                    data.customer.itemgroupDiscount.hasOwnProperty(
                      item.itemgroupID
                    ) &&
                    !item.itemNotDiscountable
                  ) {
                    if (customerCase === 0) {
                      if (
                        item.discount <
                        data.customer.itemgroupDiscount[item.itemgroupID]
                      ) {
                        item.discount =
                          data.customer.itemgroupDiscount[item.itemgroupID];
                      }
                    } else {
                      item.discount =
                        data.customer.itemgroupDiscount[item.itemgroupID];
                    }
                  } else {
                    if (customerCase !== 0) item.discount = 0;
                  }
                  if (item.isPriceChanged) return;
                  item.sellPrice =
                    calcGrossPrice(itemNetPrice, item.taxValue) + extrasPrice;
                }
              } else {
                if (customerCase !== 0) item.discount = 0;

                if (baseItem) item.taxValue = baseItem.taxValue;

                if (item.isPriceChanged) return;

                item.sellPrice = item.originalSellPrice + extrasPrice;
              }
            });
          }

          //ORDERED ITEMS
          for (let partyName in state.gastro.orderedItems) {
            if (!state.gastro.orderedItems.hasOwnProperty(partyName)) continue;

            state.gastro.orderedItems[partyName].forEach(async (item) => {
              if (cannotApplyPriceDiscountChanges(item)) return;

              let originalTax = 0;
              baseItem = await this.dispatch("items/getItemByID", item.id);
              if (baseItem) {
                originalTax = baseItem.taxValue;
              } else {
                originalTax = item.taxValue;
              }
              let itemNetPrice = getNetPrice(
                item.originalSellPrice,
                originalTax
              );

              //CALCULATING EXTRAS PRICE
              let extrasPrice = 0;
              if (
                Array.isArray(item.selectedExtras) &&
                item.selectedExtras.length > 0
              ) {
                extrasPrice = item.selectedExtras.reduce((sum, extra) => {
                  sum = sum + Number(extra.sellPrice) * extra.amount;
                  return sum;
                }, 0);
              }

              //check if we are in delivery service pos
              if (state.gastro.isDeliveryService) {
                group = await this.dispatch(
                  "itemgroups/getItemgroupByID",
                  item.itemgroupID
                );
                if (group) {
                  if (customerCase === 0) {
                    if (state.gastro.delivery) {
                      item.discount =
                        item.discount < Number(group.deliveryDiscount)
                          ? Number(group.deliveryDiscount)
                          : item.discount;
                    } else {
                      item.discount =
                        item.discount < Number(group.takeAwayDiscount)
                          ? Number(group.takeAwayDiscount)
                          : item.discount;
                    }
                  } else {
                    if (state.gastro.delivery) {
                      item.discount = Number(group.deliveryDiscount);
                    } else {
                      item.discount = Number(group.takeAwayDiscount);
                    }
                  }
                }
              }

              // WARE PRICE
              if (data.customer.group !== null) {
                if (data.customer.group.setTax >= 0) {
                  item.taxValue = data.customer.group.setTax;
                  item.sellPrice = calcGrossPrice(itemNetPrice, item.taxValue);
                } else {
                  item.taxValue = originalTax;
                }

                if (data.customer.group.type === 1) {
                  if (customerCase !== 0) {
                    item.discount = 0;
                  }
                  if (item.isPriceChanged || item.priceCannotChange) return;

                  await axios
                    .post(ENDPOINTS.POS.CUSTOMERGROUPITEMPRICE, {
                      customerGroup: data.customer.group.id,
                      itemId: item.id,
                    })
                    .then((res) => {
                      if (res.data.STATUS) {
                        item.sellPrice = Number(res.data.price) + extrasPrice;
                      } else {
                        item.sellPrice =
                          calcGrossPrice(itemNetPrice, item.taxValue) +
                          extrasPrice;
                      }
                    });
                } else {
                  //CHECK ITEM GROUP
                  if (
                    data.customer.hasOwnProperty("itemgroupDiscount") &&
                    data.customer.itemgroupDiscount !== null &&
                    Object.keys(data.customer.itemgroupDiscount).length > 0 &&
                    data.customer.itemgroupDiscount.hasOwnProperty(
                      item.itemgroupID
                    ) &&
                    !item.itemNotDiscountable
                  ) {
                    if (customerCase === 0) {
                      if (
                        item.discount <
                        data.customer.itemgroupDiscount[item.itemgroupID]
                      ) {
                        item.discount =
                          data.customer.itemgroupDiscount[item.itemgroupID];
                      }
                    } else {
                      item.discount =
                        data.customer.itemgroupDiscount[item.itemgroupID];
                    }
                  } else {
                    if (customerCase !== 0) {
                      item.discount = 0;
                    }
                  }

                  if (item.isPriceChanged) return;
                  item.sellPrice =
                    calcGrossPrice(itemNetPrice, item.taxValue) + extrasPrice;
                }
              } else {
                if (customerCase !== 0) item.discount = 0;

                if (baseItem) item.taxValue = baseItem.taxValue;

                if (item.isPriceChanged) return;

                item.sellPrice = item.originalSellPrice + extrasPrice;
              }
            });
          }
        } else {
          //OPEN ITEMS
          for (let partyName in state.gastro.openItems) {
            if (!state.gastro.openItems.hasOwnProperty(partyName)) continue;

            state.gastro.openItems[partyName].forEach(async (item) => {
              if (cannotApplyPriceDiscountChanges(item)) return;

              baseItem = await this.dispatch("items/getItemByID", item.id);

              if (baseItem) {
                item.taxValue = baseItem.taxValue;
              }
              //check if we are in delivery service pos
              if (state.gastro.isDeliveryService) {
                group = await this.dispatch(
                  "itemgroups/getItemgroupByID",
                  item.itemgroupID
                );
                if (group) {
                  if (state.gastro.delivery) {
                    item.discount = Number(group.deliveryDiscount);
                  } else {
                    item.discount = Number(group.takeAwayDiscount);
                  }
                } else {
                  item.discount = 0;
                }
              } else {
                item.discount = 0;
              }
              if (item.isPriceChanged) return;

              //CALCULATING EXTRAS PRICE
              let extrasPrice = 0;
              if (
                Array.isArray(item.selectedExtras) &&
                item.selectedExtras.length > 0
              ) {
                extrasPrice = item.selectedExtras.reduce((sum, extra) => {
                  sum = sum + Number(extra.sellPrice) * extra.amount;
                  return sum;
                }, 0);
              }
              item.sellPrice = item.originalSellPrice + extrasPrice;
            });
          }

          //ORDERED ITEMS
          for (let partyName in state.gastro.orderedItems) {
            if (!state.gastro.orderedItems.hasOwnProperty(partyName)) continue;

            state.gastro.orderedItems[partyName].forEach(async (item) => {
              if (cannotApplyPriceDiscountChanges(item)) return;

              baseItem = await this.dispatch("items/getItemByID", item.id);

              if (baseItem) {
                item.taxValue = baseItem.taxValue;
              }
              //check if we are in delivery service pos
              if (state.gastro.isDeliveryService) {
                group = await this.dispatch(
                  "itemgroups/getItemgroupByID",
                  item.itemgroupID
                );
                if (group) {
                  if (state.gastro.delivery) {
                    item.discount = Number(group.deliveryDiscount);
                  } else {
                    item.discount = Number(group.takeAwayDiscount);
                  }
                } else {
                  item.discount = 0;
                }
              } else {
                item.discount = 0;
              }

              if (item.isPriceChanged) return;

              //CALCULATING EXTRAS PRICE
              let extrasPrice = 0;
              if (
                Array.isArray(item.selectedExtras) &&
                item.selectedExtras.length > 0
              ) {
                extrasPrice = item.selectedExtras.reduce((sum, extra) => {
                  sum = sum + Number(extra.sellPrice) * extra.amount;
                  return sum;
                }, 0);
              }

              item.sellPrice = item.originalSellPrice + extrasPrice;
            });
          }
        }
        state.gastro.customer = data.customer;
      }

      if (data.posType === "retail") {
        if (data.setCustomerOnly) {
          state.retail.customer = data.customer;
          return;
        }

        //CHECK DISCOUNT
        if (data.customer !== null) {
          //ORDERED ITEMS
          state.retail.orderedItems.forEach(async (item) => {
            if (cannotApplyPriceDiscountChanges(item)) return;
            let originalTax = 0;
            baseItem = await this.dispatch("items/getItemByID", item.id);
            if (baseItem) {
              originalTax = baseItem.taxValue;
            } else {
              originalTax = item.taxValue;
            }
            let itemNetPrice = getNetPrice(item.originalSellPrice, originalTax);
            // WARE PRICE
            if (data.customer.group !== null) {
              if (data.customer.group.setTax >= 0) {
                item.taxValue = data.customer.group.setTax;
                item.sellPrice = calcGrossPrice(itemNetPrice, item.taxValue);
              } else {
                item.taxValue = originalTax;
              }

              if (data.customer.group.type === 1) {
                if (customerCase !== 0) item.discount = 0;

                if (item.isPriceChanged || item.priceCannotChange) return;

                await axios
                  .post(ENDPOINTS.POS.CUSTOMERGROUPITEMPRICE, {
                    customerGroup: data.customer.group.id,
                    itemId: item.id,
                  })
                  .then((res) => {
                    if (res.data.STATUS) {
                      item.sellPrice = Number(res.data.price);
                    } else {
                      item.sellPrice = calcGrossPrice(
                        itemNetPrice,
                        item.taxValue
                      );
                    }
                  });
              } else {
                //CHECK ITEM GROUP
                if (
                  data.customer.hasOwnProperty("itemgroupDiscount") &&
                  data.customer.itemgroupDiscount !== null &&
                  Object.keys(data.customer.itemgroupDiscount).length > 0 &&
                  data.customer.itemgroupDiscount.hasOwnProperty(
                    item.itemgroupID
                  ) &&
                  !item.itemNotDiscountable
                ) {
                  if (customerCase === 0) {
                    if (
                      item.discount <
                      data.customer.itemgroupDiscount[item.itemgroupID]
                    ) {
                      item.discount =
                        data.customer.itemgroupDiscount[item.itemgroupID];
                    }
                  } else {
                    item.discount =
                      data.customer.itemgroupDiscount[item.itemgroupID];
                  }
                } else {
                  if (customerCase !== 0) item.discount = 0;
                }

                if (item.isPriceChanged) return;
                item.sellPrice = calcGrossPrice(itemNetPrice, item.taxValue);
              }
            } else {
              if (customerCase !== 0) item.discount = 0;

              if (baseItem) {
                item.taxValue = baseItem.taxValue;
              }

              if (item.isPriceChanged) return;

              item.sellPrice = item.originalSellPrice;
            }
          });
        } else {
          state.retail.orderedItems.forEach(async (item) => {
            if (cannotApplyPriceDiscountChanges(item)) return;

            baseItem = await this.dispatch("items/getItemByID", item.id);

            item.discount = 0;
            if (baseItem) {
              item.taxValue = baseItem.taxValue;
            }

            if (item.isPriceChanged) return;

            item.sellPrice = item.originalSellPrice;
          });
        }

        state.retail.customer = data.customer;
      }

      if (data.posType === "wholesaleRetail") {
        if (data.setCustomerOnly) {
          state.wholesaleRetail.customer = data.customer;
          return;
        }

        //CHECK DISCOUNT
        if (data.customer !== null) {
          //ORDERED ITEMS
          state.wholesaleRetail.orderedItems.forEach(async (item) => {
            if (cannotApplyPriceDiscountChanges(item)) return;

            let originalTax = 0;
            baseItem = await this.dispatch("items/getItemByID", item.id);
            if (baseItem) {
              originalTax = baseItem.taxValue;
            } else {
              originalTax = item.taxValue;
            }
            let itemNetPrice = getNetPrice(item.originalSellPrice, originalTax);

            // WARE PRICE
            if (data.customer.group !== null) {
              if (data.customer.group.setTax >= 0) {
                item.taxValue = data.customer.group.setTax;
                item.sellPrice = calcGrossPrice(itemNetPrice, item.taxValue);
                item.netPrice = getNetPrice(item.sellPrice, item.taxValue);
              } else {
                item.taxValue = originalTax;
              }

              if (data.customer.group.type === 1) {
                if (customerCase !== 0) item.discount = 0;

                if (item.isPriceChanged || item.priceCannotChange) return;

                await axios
                  .post(ENDPOINTS.POS.CUSTOMERGROUPITEMPRICE, {
                    customerGroup: data.customer.group.id,
                    itemId: item.id,
                  })
                  .then((res) => {
                    if (res.data.STATUS) {
                      item.sellPrice = Number(res.data.price);
                      item.netPrice = getNetPrice(
                        item.sellPrice,
                        item.taxValue
                      );
                    } else {
                      item.sellPrice = calcGrossPrice(
                        itemNetPrice,
                        item.taxValue
                      );
                      item.netPrice = getNetPrice(
                        item.sellPrice,
                        item.taxValue
                      );
                    }
                  });
              } else {
                //CHECK ITEM GROUP
                if (
                  data.customer.hasOwnProperty("itemgroupDiscount") &&
                  data.customer.itemgroupDiscount !== null &&
                  Object.keys(data.customer.itemgroupDiscount).length > 0 &&
                  data.customer.itemgroupDiscount.hasOwnProperty(
                    item.itemgroupID
                  ) &&
                  !item.itemNotDiscountable
                ) {
                  if (customerCase === 0) {
                    if (
                      item.discount <
                      data.customer.itemgroupDiscount[item.itemgroupID]
                    ) {
                      item.discount =
                        data.customer.itemgroupDiscount[item.itemgroupID];
                    }
                  } else {
                    item.discount =
                      data.customer.itemgroupDiscount[item.itemgroupID];
                  }
                } else {
                  if (customerCase !== 0) item.discount = 0;
                }

                if (item.isPriceChanged) return;
                item.sellPrice = calcGrossPrice(itemNetPrice, item.taxValue);
                item.netPrice = getNetPrice(item.sellPrice, item.taxValue);
              }
            } else {
              if (customerCase !== 0) item.discount = 0;

              if (baseItem) {
                item.taxValue = baseItem.taxValue;
              }

              if (item.isPriceChanged) return;

              item.sellPrice = item.originalSellPrice;
              item.netPrice = getNetPrice(item.sellPrice, item.taxValue);
            }
          });
        } else {
          state.wholesaleRetail.orderedItems.forEach(async (item) => {
            if (cannotApplyPriceDiscountChanges(item)) return;

            baseItem = await this.dispatch("items/getItemByID", item.id);

            item.discount = 0;
            if (baseItem) {
              item.taxValue = baseItem.taxValue;
            }

            if (item.isPriceChanged) return;

            item.sellPrice = item.originalSellPrice;
            item.netPrice = getNetPrice(item.sellPrice, item.taxValue);
          });
        }

        state.wholesaleRetail.customer = data.customer;
      }
    },
    setCustomerProject(state, data) {
      if (data.posType === "gastro") {
        state.gastro.customerProject = data.project;
      }

      if (data.posType === "retail") {
        state.retail.customerProject = data.project;
      }

      if (data.posType === "wholesaleRetail") {
        state.wholesaleRetail.customerProject = data.project;
      }
    },
    setVerifiedAge(state, data) {
      state.verifiedAge = data;
    },
    setProgressToken(state, token) {
      state.progressToken = token;
    },
    resetPos(state, data) {
      if (data.posType === "gastro") {
        //state.pos.gastro.openItems = {};
        //state.pos.gastro.bookedItems = {};
        state.gastro.selectedOrderedItem = null;
        state.gastro.selectedOpenItem = null;
        state.gastro.customer = null;
        state.gastro.setCustomerProject = null;
        state.gastro.party = null;
        state.customerDisplayTipPercent = null;
        state.gastro.table = {
          name: 0,
          parties: [],
        };
      }
    },
    setCustomerDisplayTipPercent(state, data) {
      state.customerDisplayTipPercent = data;
    },
  },

  actions: {
    updateActiveShift({ commit }, data) {
      commit("updateActiveShift", data);
    },
    stornoItem({ commit }, data) {
      commit("stornoItem", data);
    },
    deleteItem({ commit }, data) {
      commit("deleteItem", data);
    },
    priceDiscount({ commit }, data) {
      commit("priceDiscount", data);
    },
    discount({ commit }, data) {
      commit("discount", data);
    },
    discountInvoice({ commit }, data) {
      commit("discountInvoice", data);
    },
    increaseInvoice({ commit }, data) {
      commit("increaseInvoice", data);
    },
    setCourse({ commit }, data) {
      commit("setCourse", data);
    },
    setFreetext({ commit }, data) {
      commit("setFreetext", data);
    },
    setCustomer({ commit }, data) {
      commit("setCustomer", data);
    },
    setCustomerProject({ commit }, data) {
      commit("setCustomerProject", data);
    },
    setVerifiedAge({ commit }, data) {
      commit("setVerifiedAge", data);
    },
    resetPos({ commit }, data) {
      commit("resetPos", data);
    },
  },
};
