<template>
    <div class="header-dots" style="margin-right: 10px;">
        <span slot="button-content">
            <div @click="toggleKeyboard" class="icon-wrapper icon-wrapper-alt rounded-circle">
                <div class="icon-wrapper-bg bg-focus"></div>
                <v-icon :style="{'color': (touchkeyboard.settings.enabled ? '#81C784' : '#FF6D00')}" size="30">keyboard</v-icon>
            </div>
        </span>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        name: "HeaderTouchKeyboard",

        data() {
            return {
                enabled: false
            }
        },

        methods: {
            toggleKeyboard() {
                this.$store.dispatch("touchkeyboard/updateSettings", {
                    enabled: !this.touchkeyboard.settings.enabled
                });
            },
        },

        computed: {
            ...mapState([
                'touchkeyboard'
            ]),
        }
    }
</script>