import Vue from "vue";
import axios from "axios";

export default {
    namespaced: true,

    state: {
        templates: {}
    },

    mutations: {
        init(state, data) {
            state.templates = data;
        }
    },

    actions: {
        init({commit}) {
            return axios.post('get/indexeddb/posButtonsTemplates/').then((res) => {
                commit("init", res.data);
            });
        }
    },

    getters: {}
};
