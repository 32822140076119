export let tableBeeRoutes = {
    meta: {
        menuTitle: ('TableBee'),
        icon: 'pe-7s-study',
        permission: {
            requiredModule: 54,
            requiredPermission: "",
            hybridDisplay: ""
        }
    },
    childs: [
        //panic table
        {
            path: '/tablebee/overview',
            name: 'tablebee.overview',
            meta: {
                siteTitle: ('generic.lang_overview'),
                menuTitle: ('generic.lang_overview'),
                layout: 'default',
                icon: 'pe-7s-pen',
                permission: {
                    requiredModule: 54,
                    requiredPermission: "tablebee_overview",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/tableBee/TablebeeOverview')
        },
        //panic table
        {
            path: '/tablebee/panic-table',
            name: 'tablebee.panicTable',
            meta: {
                siteTitle: ('tablebee.lang_panic_table'),
                menuTitle: ('tablebee.lang_panic_table'),
                layout: 'default',
                icon: 'pe-7s-pen',
                permission: {
                    requiredModule: 54,
                    requiredPermission: "tablebee_openOrders",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/tableBee/panicTableView')
        },
        //end panic table
        {
            path: '/tablebee/design',
            name: 'tablebee.design',
            meta: {
                siteTitle: ('tablebee.lang_design_settings'),
                menuTitle: ('tablebee.lang_design_settings'),
                layout: 'default',
                icon: 'pe-7s-pen',
                permission: {
                    requiredModule: 54,
                    requiredPermission: "tablebee_settings",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/tableBee/tableBeeDesignView')
        },
        {
            path: '/tablebee/products-management',
            name: 'tablebee.products.management',
            meta: {
                siteTitle: ('erp.lang_products_management'),
                menuTitle: ('erp.lang_products_management'),
                layout: 'default',
                icon: 'pe-7s-box1',
                permission: {
                    requiredModule: 54,
                    requiredPermission: "tablebee_productSettings",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/tableBee/tableBeeProductsView')
        },
        {
            path: '/tablebee/table-manager',
            name: 'tablebee.table.manager',
            meta: {
                siteTitle: ('tablebee.lang_table_manager'),
                menuTitle: ('tablebee.lang_table_manager'),
                layout: 'default',
                icon: 'pe-7s-home',
                permission: {
                    requiredModule: 54,
                    requiredPermission: "tablebee_tableSettings",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/tableBee/TableManagerView')
        },
        {
            path: '/tablebee/global-settings',
            name: 'tablebee.global.settings',
            meta: {
                siteTitle: ('settings.lang_settings'),
                menuTitle: ('settings.lang_settings'),
                layout: 'default',
                icon: 'pe-7s-config',
                permission: {
                    requiredModule: 54,
                    requiredPermission: "tablebee_settings",
                    hybridDisplay: ""
                }
            },
            component: () => import('../views/tableBee/tableBeeGlobalSettingView')
        },
    ],
    groups: []
};
