<template>
  <div :class="[$vuetify.theme.dark? '' : 'app-container app-theme-white']" class="">
    <transition appear mode="out-in" name="fade">
      <Sidebar :sidebarbg="sidebarcolor"/>
    </transition>

    <div class="app-main__outer">
      <div class="app-main__inner p-0">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "../Components/Sidebar";

export default {
  name: 'app',

  components: {
    Sidebar
  },

  data() {
    return {
      headercolor: null,
      sidebarcolor: null
    }
  },
}
</script>

