import axios from "axios";
import Vue from "vue";

export default {
    namespaced: true,

    state: {
        printers: {},
        templates: [],
        stornoTemplates: []
    },

    mutations: {
        initPrinters(state, data) {
            state.printers = data;
        },
        backgroundSync(state, data) {
            const transaction = data.transaction;
            const transactionType = data.type;

            if (transactionType === "INSERT") {
                //INSERT PRINTER
                Vue.set(state.printers, state.printers.length, {
                    id: transaction.id,
                    name: transaction.printerName,
                    type: transaction.type,
                    options: transaction.options,
                    virtualOrderbonPrinterAssignment: transaction.virtualOrderbonPrinterAssignment,
                    api: transaction.api,
                    externalESCType: transaction.externalESCType,
                    usbId1: transaction.usbId1,
                    usbId2: transaction.usbId2,
                    paperType: transaction.paperType,
                    address: transaction.address,
                    serverAddress: transaction.serverAddress,
                    cashierID: transaction.cashierID
                });
            }

            if (transactionType === "UPDATE") {
                //FIND PRINTER
                let printer = state.printers.find((printer) => {
                    if (printer.id === transaction.id) {
                        return true;
                    }
                });

                //UPDATE ITEM
                if (printer) {
                    printer.name = transaction.printerName;
                    printer.type = transaction.type;
                    printer.options = transaction.options;
                    printer.virtualOrderbonPrinterAssignment = transaction.virtualOrderbonPrinterAssignment;
                    printer.api = transaction.api;
                    printer.externalESCType = transaction.externalESCType;
                    printer.usbId1 = transaction.usbId1;
                    printer.usbId2 = transaction.usbId2;
                    printer.paperType = transaction.paperType;
                    printer.address = transaction.address;
                    printer.serverAddress = transaction.serverAddress;
                    printer.cashierID = transaction.cashierID;
                }
            }

            if (transactionType === "DELETE") {
                //FIND PRINTER
                let printer = state.printers.find((printer) => {
                    if (printer.id === transaction.id) {
                        return true;
                    }
                });

                if (printer) {
                    let printerIndex = state.printers.indexOf(printer);

                    //DELETE PRINTER
                    Vue.delete(state.printers, printerIndex);
                }
            }
        },
        updatePrinters(state, data) {
            state.printers = data;
        },
        updateTemplates(state, data) {
            state.templates = data;
        },
        updateStornoTemplates(state, data) {
            state.stornoTemplates = data;
        },
        updateTSEPrinterStatus(state, data) {
            const device = state.printers.TSE.find((printer) => {
                return printer.id === data.id;
            });

            if (device) {
                device.TSEStatus = data.status;
            }
        },
    },

    actions: {
        initPrinters({commit}) {
            return axios.post('get/indexeddb/printers/',).then((res) => {
                commit("initPrinters", res.data);
            }).catch(() => {

            });
        },
        updatePrinters({commit}, data) {
            commit("updatePrinters", data);
        },
        updateTemplates({commit}, data) {
            commit('updateTemplates', data);
        },
        updateStornoTemplates({commit}, data) {
            commit('updateStornoTemplates', data);
        },
        updateTSEPrinterStatus({commit}, data) {
            commit("updateTSEPrinterStatus", data);
        },
    },

    getters: {
        printers: state => state.printers,
        invoicePrinter: (state, getters, rootState) => {
            if(state.printers) {
                return state.printers.find((printer) => {
                    if (printer.cashierID.includes(rootState.api.auth.cashierID)) {
                      if (printer.type === 1)
                        return true;
                    }
                  });
            }

            return null;
        },
        templates: (state) => state.templates,
        templatesRetail: (state) => state.templates.retail,
        templatesGastro: (state) => state.templates.gastro,
        stornoTemplates: (state) => state.stornoTemplates,
        templatesGastroSetting: (state) => state.templates.settingGastro,
        templatesRetailSetting: (state) => state.templates.settingRetail
    }
};
