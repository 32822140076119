import axios from "axios";
import {handleCurrencyFormatting} from "../../plugins/currencyFormater";

export default {
    namespaced: true,

    state: {
        settings: [],
        currencyData: {
            currency: 'EUR',
            symbol: '€',
            thousandsSeparator: '.',
            fractionSeparator: ',',
            fractionCount: 2,
        }
    },

    mutations: {
        initSettings(state, data) {
            state.settings = data;
            handleCurrencyFormatting(data['geoloc_systemcountry']);
        },
        setCurrencyData(state, data) {
            state.currencyData.currency = data.currency || 'EUR';
            state.currencyData.symbol = data.symbol || '€';
            state.currencyData.thousandsSeparator = data.thousandsSeparator || '.';
            state.currencyData.fractionSeparator = data.fractionSeparator || ',';
            state.currencyData.fractionCount = data.fractionCount || 2;
        }
    },

    actions: {
        initSettings({commit}) {
            return axios.post('get/indexeddb/settings/').then((res) => {
                commit("initSettings", res.data);
            });
        }
    },

    getters: {
        settings: (state) => state.settings,
        getSettingValue: (state) => (settingName) => {
            if (!state.settings.hasOwnProperty(settingName))
                return null;

            return state.settings[settingName];
        },
        currencyData: (state) => (key) => {
            return state.currencyData[key];
        }
    }
};
