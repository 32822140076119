<template>
    <div class="app-container app-theme-white">
        <transition name="fade" mode="out-in" appear>
            <Sidebar :drawerSetter=false :sidebarbg="sidebarcolor"/>
        </transition>

        <v-content>
            <slot></slot>
        </v-content>
    </div>
</template>

<script>
    import Sidebar from "../Components/Sidebar";

    export default {
        name: 'app',

        components: {
            Sidebar
        },

        data() {
            return {
                headercolor: null,
                sidebarcolor: null
            }
        }
    }
</script>