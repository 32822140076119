import * as mathjs from 'mathjs';

//ROUND DECIMAL
function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}
//RAPPENRUNDUNG
function RappenRundungFunc(num) {
    return (Math.round(num * 20)) / 20;
}
//3pos round
function posRound(zahl, n) {
    var faktor;
    faktor = Math.pow(10, n);
    return(Math.round(zahl * faktor) / faktor);
}

export function roundNumber(num, scale) {
    num = parseFloat(num);
    if(!("" + num).includes("e")) {
        return +(mathjs.round(num + "e+" + scale)  + "e-" + scale);
    } else {
        var arr = ("" + num).split("e");
        var sig = ""
        if(+arr[1] + scale > 0) {
            sig = "+";
        }
        return +(mathjs.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
    }
}
