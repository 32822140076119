import axios from "axios";
import Vue from "vue";

export default {
    namespaced: true,

    state: {
        payments: [],
        ecPaymentType: 0,
    },

    mutations: {
        init(state, data) {
            state.payments = data;
        },
        updateEcPaymentType(state, data) {
            state.ecPaymentType = data;
        },
        backgroundSync(state, data) {
            const transaction = data.transaction;
            const transactionType = data.type;

            if(transactionType === "INSERT") {
                //INSERT
                Vue.set(state.payments, state.payments.length, {
                    paymentID: transaction.id,
                    paymentName: transaction.name,
                    paymentNameTranslate: transaction.translate,
                    paymentText: transaction.value,
                    isTipEnabled: transaction.isTipEnabled,
                });
            }

            if(transactionType === "UPDATE") {
                //FIND
                let payment = state.payments.find((payment) => {
                    if(payment.paymentID === transaction.id) {
                        return true;
                    }
                });

                //UPDATE ITEM
                if(payment) {
                    payment.paymentName = transaction.name;
                    payment.paymentText = transaction.value;
                    payment.paymentNameTranslate = transaction.translate;
                    payment.isTipEnabled = transaction.isTipEnabled;
                }
            }

            if(transactionType === "DELETE") {
                //FIND
                let payment = state.payments.find((payment) => {
                    if(payment.paymentID === transaction.id) {
                        return true;
                    }
                });

                if(payment) {
                    let paymentIndex = state.payments.indexOf(payment);

                    //DELETE PAYMENT
                    Vue.delete(state.payments, paymentIndex);
                }
            }
        },
    },

    actions: {
        init({commit}) {
            return axios.post('get/indexeddb/payments/').then((res) => {
                commit("init", res.data.tags);
            });
        },
        updateEcPaymentType({commit}, data) {
            commit('updateEcPaymentType', data);
        },
    },
    getters: {
        ecPaymentType: (state) => state.ecPaymentType,
    }
};
